import { useCallback } from 'react';
import { Box3, Mesh, Vector3 } from 'three';
import {
  GroupNode,
  SceneNodeType,
  SimpleNode,
  SlotNode
} from '../../types/scene.types';

import { sceneStore } from '../../store/sceneStore';

import { GroupModel } from '../../models/GroupModel';
import { Model } from '../../models/Model';

export function Preview() {
  const preview = sceneStore.use.techScope().preview;
  const handlePreviewInit = useCallback((node: Mesh) => {
    if (!node) return;

    const box = new Box3();
    box.setFromObject(node);
    const preview = sceneStore.get.techScope().preview;
    const groupDiff =
      preview?.type === SceneNodeType.GROUP
        ? preview.position.clone().sub(box.getCenter(new Vector3()))
        : new Vector3();
    preview &&
      sceneStore.set.updatePreview({
        box: box,
        groupDif: groupDiff
      });
  }, []);

  if (!preview) return null;

  return preview.type === SceneNodeType.GROUP ? (
    <GroupModel node={preview as GroupNode} ref={handlePreviewInit} />
  ) : (
    <Model node={preview as SimpleNode | SlotNode} ref={handlePreviewInit} />
  );
}
