import { Clone, useGLTF } from '@react-three/drei';

import { sceneStore } from '../../store/sceneStore';

export function TestObject() {
  const testObject = sceneStore.use.testObject();

  if (!testObject) return null;

  const { scene } = useGLTF(testObject);

  return <Clone object={scene} castShadow receiveShadow />;
}
