import { useEffect, useRef } from 'react';
import { Euler, Vector3 } from 'three';
import { useThree } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import find from 'lodash/find';
import keys from 'lodash/keys';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import {
  SceneNode,
  SceneNodeAttach,
  SceneNodeStick,
  SceneNodeType,
  SlotNode
} from '../../types/scene.types';
import { SettingsAssetModelItem } from '../../types/settings.types';

import { DefaultScopes, sceneStore } from '../../store/sceneStore';
import { dndStore } from '../../store/dndStore';
import { globalStore } from '../../store/globalStore';

import { parseGLTFNodes } from '../../utils/parseGLTFNodes';
import { prepareSlots } from '../../utils/prepareSlots';

export function DNDPointerControl() {
  const settings = globalStore.use.settings();
  const orbitControls = useThree((state) => state.controls) as OrbitControls;

  if (!settings) return null;

  const plane = globalStore.use.plane();

  const { raycaster, gl } = useThree(({ raycaster, gl }) => ({
    raycaster,
    gl
  }));

  const scope = DefaultScopes.FLOOR;

  const slots = useRef<SlotNode['slots']>({});

  const modelItem = useRef<SettingsAssetModelItem | undefined>();

  useEffect(() => {
    const handlePointerEnter = () => {
      const { active, id } = dndStore.store.getState();

      if (!active) return;

      modelItem.current = find(
        settings.models.assets,
        (item) => item.id === id
      );

      if (modelItem.current) {
        if (modelItem.current.type !== SceneNodeType.SLOT) {
          sceneStore.set.configureNode(null);
        }

        const { scene } = useGLTF(modelItem.current.fileUrl);

        const { slots: preparedSlots, childs: prepareChilds } = parseGLTFNodes(
          settings,
          scene
        );

        slots.current = {};

        if (preparedSlots) {
          slots.current = prepareSlots(settings, preparedSlots, false);
        }

        if (prepareChilds) {
          mergeWith(
            slots.current,
            prepareSlots(settings, prepareChilds, true),
            (obj, src) => (isArray(obj) ? obj.concat(src) : undefined)
          );
        }

        sceneStore.set.updatePreview({
          position: new Vector3(),
          sku: modelItem.current.id,
          model: modelItem.current.fileUrl,
          slots: slots.current,
          rotation: new Euler()
        });
      }

      dndStore.set.visibility(false);
    };

    gl.domElement.addEventListener('pointerenter', handlePointerEnter);

    return () => {
      gl.domElement.removeEventListener('pointerenter', handlePointerEnter);
    };
  }, []);

  useEffect(() => {
    const handlePointerLeave = () => {
      const active = dndStore.get.active();

      if (!active) return;

      dndStore.set.visibility(true);
      sceneStore.set.updatePreview(null);
    };

    gl.domElement.addEventListener('pointerleave', handlePointerLeave);

    return () => {
      gl.domElement.removeEventListener('pointerleave', handlePointerLeave);
    };
  }, []);

  useEffect(() => {
    const handlePointerMove = () => {
      const { active, id, visibility } = dndStore.store.getState();
      const draggedNode = sceneStore.get.draggedNode();
      const previewInSlot = sceneStore.get.previewInSlot();

      if (previewInSlot) return;

      if ((!active || !id || visibility) && !draggedNode) return;

      const position = new Vector3();
      active && plane.set(new Vector3(0, 1, 0), 0);

      raycaster.ray.intersectPlane(plane, position);

      !active && position.add(dndStore.get.modelShift()).setY(0);

      if (draggedNode) {
        const sectioningMode = sceneStore.get.sectioningMode();
        const sceneMode = sceneStore.get.sceneMode();
        if (
          sceneMode &&
          sectioningMode &&
          draggedNode.type !== SceneNodeType.CAMERA &&
          draggedNode.parent
        ) {
          position.add(draggedNode.parent.position);
        }
        sceneStore.set.draggedToPosition(position);

        return;
      }

      if (modelItem.current) {
        sceneStore.set.draggedToPosition(position);

        sceneStore.set.updatePreview({
          sku: modelItem.current.id,
          rotation: new Euler(),
          model: modelItem.current.fileUrl,
          slots: slots.current,
          attach: modelItem.current.attach,
          type: modelItem.current.type,
          stick: modelItem.current.stick
        } as Partial<SceneNode>);

        return;
      }
    };

    gl.domElement.addEventListener('pointermove', handlePointerMove);

    return () => {
      gl.domElement.removeEventListener('pointermove', handlePointerMove);
    };
  }, []);

  useEffect(() => {
    const handlePointerUp = () => {
      const { active, id, visibility } = dndStore.store.getState();
      const draggedNode = sceneStore.get.draggedNode();
      const previewInSlot = sceneStore.get.previewInSlot();

      if (orbitControls) orbitControls.enableRotate = true;

      if (previewInSlot) return;

      if ((!active || !id || visibility) && !draggedNode) return;

      const position = new Vector3();
      active && plane.set(new Vector3(0, 1, 0), 0);

      raycaster.ray.intersectPlane(plane, position);

      !active && position.add(dndStore.get.modelShift()).setY(0);

      if (draggedNode) {
        const sectioningMode = sceneStore.get.sectioningMode();
        const sceneMode = sceneStore.get.sceneMode();
        if (
          sceneMode &&
          sectioningMode &&
          draggedNode.type !== SceneNodeType.CAMERA &&
          draggedNode.parent
        ) {
          draggedNode.position.sub(draggedNode.parent.position);
        }

        if (draggedNode.scope) {
          if (draggedNode.type != SceneNodeType.CAMERA)
            sceneStore.set.addSceneNode(draggedNode.scope, draggedNode);
        } else {
          const configureNode = sceneStore.get.configureNode();
          if (
            draggedNode.type === SceneNodeType.SLOT &&
            !keys(configureNode?.model).length
          ) {
            sceneStore.set.addToConfigureNode(draggedNode);
          }
        }

        sceneStore.set.selectedNode(draggedNode);
        sceneStore.set.draggedNode(null);

        sceneStore.set.updatePreview(null);
        modelItem.current = undefined;
        dndStore.get.modelShift().set(0, 0, 0);
        return;
      }

      if (modelItem.current) {
        if (modelItem.current.type === SceneNodeType.SIMPLE) {
          const newItem = sceneStore.set.addSceneNode(scope, {
            sku: modelItem.current.id,
            rotation: new Euler(),
            position,
            model: modelItem.current.fileUrl,
            attach: modelItem.current.attach,
            type: modelItem.current.type,
            stick: modelItem.current.stick
          });

          sceneStore.set.selectedNode(newItem);

          sceneStore.set.updatePreview(null);
          modelItem.current = undefined;
          dndStore.get.modelShift().set(0, 0, 0);
          return;
        }

        let configureNode = sceneStore.get.configureNode();

        if (!configureNode) {
          const groupNode = sceneStore.set.addSceneNode(scope, {
            sku: modelItem.current.parentId || '',
            position: new Vector3(),
            rotation: new Euler(),
            model: {},
            attach: SceneNodeAttach.DEFAULT,
            type: SceneNodeType.GROUP,
            stick: SceneNodeStick.DEFAULT
          });

          sceneStore.set.configureNode(groupNode);

          configureNode = sceneStore.get.configureNode();
        }

        if (
          modelItem.current.type === SceneNodeType.SLOT &&
          !keys(configureNode?.model).length
        ) {
          const newItem = sceneStore.set.addToConfigureNode({
            sku: modelItem.current.id,
            position,
            rotation:
              sceneStore.get.techScope().preview?.rotation || new Euler(),
            model: modelItem.current.fileUrl,
            slots: slots.current,
            attach: modelItem.current.attach,
            type: modelItem.current.type,
            stick: modelItem.current.stick
          });
          const sectioningMode = sceneStore.get.sectioningMode();
          sceneStore.set.selectedNode(sectioningMode ? newItem : configureNode);
        }
      }

      sceneStore.set.updatePreview(null);
      modelItem.current = undefined;
      dndStore.get.modelShift().set(0, 0, 0);
    };

    gl.domElement.addEventListener('pointerup', handlePointerUp);

    return () => {
      gl.domElement.removeEventListener('pointerup', handlePointerUp);
    };
  }, [orbitControls]);

  return null;
}
