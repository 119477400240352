import { useCallback } from 'react';
import { IconsEnum } from '../assets/icons/types';

import { sceneStore } from '../store/sceneStore';

import { PureIconButtonHelper } from '../helpers/buttons/PureIconButtonHelper';
import { SceneNodeType } from '../types/scene.types';

export function UILayout() {
  const selectedNode = sceneStore.use.selectedNode();

  const handleDelete = useCallback(() => {
    if (selectedNode && selectedNode.type !== SceneNodeType.CAMERA)
      selectedNode && sceneStore.set.deleteSceneNode(selectedNode);
    if (selectedNode && selectedNode.type === SceneNodeType.CAMERA)
      selectedNode && sceneStore.set.deleteCameraNode(selectedNode);
  }, [selectedNode]);

  return (
    <>
      {selectedNode && (
        <PureIconButtonHelper
          className="absolute right-10 bottom-10 bg-black text-white hover:bg-gray-700 hover:shadow inline-flex items-center px-4 py-2 rounded-md sm:px-2 z-40"
          icon={IconsEnum.TRASH_OUTLINE}
          iconClassName="h-7 w-7 stroke-2"
          onClick={handleDelete}
        />
      )}
    </>
  );
}
