import { useCallback } from 'react';
import { Euler } from 'three';
import isString from 'lodash/isString';

import {
  SceneNodeType,
  CameraNode,
  CameraOrientation
} from '../../../../../../types/scene.types';

import {
  ConfiguratorEvents,
  configuratorEvents
} from '../../../../../../events/configuratorEvents';

import { sceneStore } from '../../../../../../store/sceneStore';
import {
  globalStore,
  MeasurementsSystem
} from '../../../../../../store/globalStore';

import { exportConfiguratorFile } from '../../../../../../store/utils/exportConfiguratorFile';
import { importConfiguratorFile } from '../../../../../../store/utils/importConfiguratorFile';
import { createAndDownloadFile } from '../../../../../../utils/createAndDownloadFile';
import { loadFile, loadFilePath } from '../../../../../../utils/loadFile';

function useRoomDesignerPageToolbar() {
  const enableDoor = globalStore.use.enableDoor();
  const enableWindow = globalStore.use.enableWindow();
  const enableMeasurements = globalStore.use.enableMeasurements();
  const enableRoomMeasurements = globalStore.use.enableRoomMeasurements();
  const measurementsSystem = globalStore.use.measurementsSystem();

  const handleToggleCollision = useCallback<() => void>(() => {
    globalStore.set.collisionEnabled(!globalStore.get.collisionEnabled());
  }, []);

  const handleLoadGLB = useCallback<() => void>(async () => {
    sceneStore.set.testObject(null);
    const file = await loadFilePath('.glb');
    sceneStore.set.testObject(file);
  }, []);

  const handleRotate = useCallback<() => void>(() => {
    sceneStore.set.rotateBy(new Euler(0, 45 * (Math.PI / 180), 0));
  }, []);

  const handleCameraModeChange = useCallback<() => void>(() => {
    sceneStore.set.cameraTransformMode(
      sceneStore.get.cameraTransformMode() === 'translate'
        ? 'rotate'
        : 'translate'
    );
  }, []);

  const handleSave = useCallback<() => void>(() => {
    const sceneFile = exportConfiguratorFile();
    createAndDownloadFile(JSON.stringify(sceneFile), 'scene.json');
  }, []);

  const handleLoad = useCallback<() => void>(async () => {
    const file = await loadFile();

    if (!isString(file)) return;
    const sceneFile = JSON.parse(file);

    importConfiguratorFile(sceneFile);
  }, []);

  const handleToggleDoor = useCallback<() => void>(() => {
    globalStore.set.enableDoor(!enableDoor);
  }, [enableDoor]);

  const handleToggleWindow = useCallback<() => void>(() => {
    globalStore.set.enableWindow(!enableWindow);
  }, [enableWindow]);

  const selectCamera = useCallback<(camera: CameraNode) => void>(
    (camera: CameraNode) => {
      sceneStore.set.activeCamera({ ...camera });
      sceneStore.set.selectedNode(camera);
    },
    []
  );

  const selectCameraOrientation = useCallback<
    (cameraOrientation: CameraOrientation) => void
  >((cameraOrientation: CameraOrientation) => {
    sceneStore.set.cameraOrientation({ orient: cameraOrientation });
  }, []);

  const handleToggleMeasurements = useCallback<() => void>(() => {
    globalStore.set.enableMeasurements(!enableMeasurements);
  }, [enableMeasurements]);

  const handleToggleRoomMeasurements = useCallback<() => void>(() => {
    globalStore.set.enableRoomMeasurements(!enableRoomMeasurements);
  }, [enableRoomMeasurements]);

  const handleVideoCameraCreate = useCallback<() => void>(() => {
    const node = sceneStore.set.addCameraNode('Cameras', {
      type: SceneNodeType.CAMERA,
      model: '/assets/models/common/videoCamera.glb'
    });
    sceneStore.set.selectedNode(node);
  }, []);

  const toggleMeasurementsSystem = useCallback<() => void>(() => {
    if (measurementsSystem === MeasurementsSystem.METRICAL) {
      globalStore.set.measurementsSystem(MeasurementsSystem.AMERICAN);
    } else if (measurementsSystem === MeasurementsSystem.AMERICAN) {
      globalStore.set.measurementsSystem(MeasurementsSystem.METRICAL);
    }
  }, [measurementsSystem]);

  const handleZoomIn = useCallback<() => void>(() => {
    configuratorEvents.emit(ConfiguratorEvents.ZOOM_IN);
  }, []);

  const handleZoomOut = useCallback<() => void>(() => {
    configuratorEvents.emit(ConfiguratorEvents.ZOOM_OUT);
  }, []);

  return {
    handleToggleCollision,
    handleLoadGLB,
    handleRotate,
    handleCameraModeChange,
    handleSave,
    handleLoad,
    handleToggleDoor,
    handleToggleWindow,
    handleToggleMeasurements,
    handleToggleRoomMeasurements,
    toggleMeasurementsSystem,
    handleVideoCameraCreate,
    handleZoomIn,
    handleZoomOut,
    selectCameraOrientation,
    selectCamera
  };
}

export default useRoomDesignerPageToolbar;
