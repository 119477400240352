import cl from 'classnames';
import { ReactNode, useCallback, useState } from 'react';

import { IconsEnum } from '../../../assets/icons/types';

import { PureIconButtonHelper } from '../../../helpers/buttons/PureIconButtonHelper';

const editorStyle = { minWidth: '866px', minHeight: '658px' };

interface EditorLayoutProps {
  children: ReactNode;
  contentBrowser?: ReactNode;
  header?: ReactNode;
  tabs?: ReactNode;
  toolbar?: ReactNode;
  sidebar?: ReactNode;
}

function EditorLayout({
  children,
  contentBrowser,
  header,
  tabs,
  toolbar,
  sidebar
}: EditorLayoutProps) {
  const [isBottomPanelOpened, setIsBottomPanelOpened] = useState(true);

  const handleToggleBottomPanel = useCallback(
    () => setIsBottomPanelOpened((val) => !val),
    [setIsBottomPanelOpened]
  );

  return (
    <div className="absolute inset-0 flex flex-col text-xs" style={editorStyle}>
      {/*Header*/}
      <div className="flex justify-between items-center border-b border-gray-300 dark:border-gray-700 bg-sky-100 dark:bg-gray-900">
        <div className="px-2">
          {/*Bread crumbs*/}
          <div className="text-sm flex gap-0.5 items-center py-1.5 rounded-t-3xl dark:text-gray-400">
            Unsaved project
          </div>
          {/*Tabs*/}
          {tabs}
        </div>
        <button
          data-modal-open="fine-tune"
          className="flex items-center text-left gap-2 py-2 px-4 bg-sky-800 hover:bg-sky-900 text-white h-full w-60 xl:w-72"
        >
          <div>
            {/*Icon: outline/sparkles*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              className="h-8 w-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
              />
            </svg>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-base leading-none">Fine-tune</div>
            <div className="text-2xs leading-none text-sky-200">
              Click to know how enhance design with professional
            </div>
          </div>
        </button>
      </div>
      {/*Main windows*/}
      <div className="flex-1 bg-white dark:bg-gray-950 flex">
        <div className="flex flex-col w-full">
          <div className="flex flex-1">
            {/*Main editor window*/}
            <div className="flex-1 relative">
              <div className="absolute inset-0">{children}</div>
              {/*Toolbar buttons*/}
              <div className="w-full top-0 absolute">{toolbar}</div>
              {/*Footer buttons*/}
              <div className="absolute bottom-0 w-full flex justify-center pb-8">
                <button
                  type="button"
                  data-configurator-tab-trigger="planner"
                  className="py-2 pl-4 pr-4 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                >
                  <span className="">View in room plan</span>
                </button>
              </div>
            </div>
            {/*Right Sidebar*/}
            {sidebar}
            {/* <div className="w-60 xl:w-72 flex flex-col border-l dark:border-gray-700 divide-y dark:divide-gray-700 pb-4">
              <!--- Description -->
              <div className="flex-1 flex flex-col">
                <div className="flex-1 relative">
                  <div className="absolute inset-0 overflow-y-auto">
                    <div className="">
                      <div className="grid grid-cols-2 gap-1 p-2">
                        <div className="col-span-full text-sm font-thin text-gray-500">
                          Description
                        </div>
                        <div className="">Product name</div>
                        <div className="">Quadra</div>
                        <div className="">Brand name</div>
                        <div className="">Article</div>
                        <div className="">Article number</div>
                        <div className="">386539</div>
                        <div className="">Size</div>
                        <div className="">390 x 242 cm</div>
                        <div className="">Price</div>
                        <div className="">$1,260.00</div>
                      </div>
                      <div className="grid grid-cols-2 gap-1 p-2">
                        <div className="col-span-full text-sm font-thin text-gray-500">
                          Materials
                        </div>
                        <div className="">Cloth</div>
                        <div className="">Twiling blue</div>
                        <div className="">Legs</div>
                        <div className="">Aluminium</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--- Outliner -->
              <div className="flex-1 flex flex-col">
                <div className="px-2 py-1 items-center flex justify-between gap-2">
                  <span className="text-sm font-thin text-gray-500">
                    Outliner
                  </span>
                  <div>
                    <div className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 sm:max-w-lg relative rounded text-gray-400 border border-transparent w-full">
                      <div className="absolute flex inset-y-0 items-center left-2 pointer-events-none">
                        <!--- Icon: solid/search -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="h-3 w-3"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <label
                        htmlFor="single_project_search_field"
                        className="sr-only"
                      >
                        Search items
                      </label>
                      <input
                        id="single_project_search_field"
                        type="search"
                        className="text-xs bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded h-full pl-6 placeholder-gray-400 pr-2 py-0.5 text-gray-900 w-full"
                        placeholder="Search items"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1 relative">
                  <div className="absolute inset-0 overflow-y-auto">
                    <div className="py-2">
                      <div className="">
                        <button
                          className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none text-blue-600 bg-blue-100 dark:text-blue-300 dark:bg-blue-700/20 font-normal cursor-default"
                          aria-controls="sub-menu-quadra-sectional-sofa"
                          aria-expanded="true"
                        >
                          <!-- Icon: solid/chevron-right -->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-4 w-4 absolute left-0 rotate-90"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <!-- Icon: solid/home -->
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-3.5 w-3.5 mr-1"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span
                            className="truncate"
                            title="Quadra sectional sofa"
                          >
                            Quadra sectional sofa
                          </span>
                        </button>
                        <div className="pl-4">
                          <div
                            className="border-l border-gray-700"
                            data-id="sub-menu-quadra-sectional-sofa"
                          >
                            <div className="">
                              <button className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none hover:bg-gray-200 dark:hover:bg-gray-800">
                                <!-- Icon: solid/cube -->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-3.5 w-3.5 mr-1"
                                >
                                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                                </svg>
                                <span
                                  className="truncate"
                                  title="Right arm long"
                                >
                                  Right arm long
                                </span>
                              </button>
                            </div>
                            <div className="">
                              <button className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none hover:bg-gray-200 dark:hover:bg-gray-800">
                                <!-- Icon: solid/cube -->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-3.5 w-3.5 mr-1"
                                >
                                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                                </svg>
                                <span className="truncate" title="Right arm">
                                  Right arm
                                </span>
                              </button>
                            </div>
                            <div className="">
                              <button className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none hover:bg-gray-200 dark:hover:bg-gray-800">
                                <!-- Icon: solid/cube -->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-3.5 w-3.5 mr-1"
                                >
                                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                                </svg>
                                <span className="truncate" title="Angular">
                                  Angular
                                </span>
                              </button>
                            </div>
                            <div className="">
                              <button className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none hover:bg-gray-200 dark:hover:bg-gray-800">
                                <!-- Icon: solid/cube -->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-3.5 w-3.5 mr-1"
                                >
                                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                                </svg>
                                <span className="truncate" title="Middle">
                                  Middle
                                </span>
                              </button>
                            </div>
                            <div className="">
                              <button className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none hover:bg-gray-200 dark:hover:bg-gray-800">
                                <!-- Icon: solid/cube -->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-3.5 w-3.5 mr-1"
                                >
                                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                                </svg>
                                <span
                                  className="truncate"
                                  title="Left arm long"
                                >
                                  Left arm long
                                </span>
                              </button>
                            </div>
                            <div className="">
                              <button className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none hover:bg-gray-200 dark:hover:bg-gray-800">
                                <!-- Icon: solid/cube -->
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-3.5 w-3.5 mr-1"
                                >
                                  <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                                </svg>
                                <span className="truncate" title="Left arm">
                                  Left arm
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* Content browser */}
          <div
            className={cl(
              '-mt-4 px-2 dark:bg-gray-950 bg-white',
              isBottomPanelOpened && 'h-1/3'
            )}
          >
            <div className="flex flex-col h-full relative rounded-t-3xl bg-white dark:bg-gray-900 shadow-2xl dark:shadow-none border border-b-0 border-gray-300 dark:border-gray-700">
              <div className="flex items-center px-2 gap-3 rounded-t-3xl shadow-t-lg dark:shadow-none">
                <PureIconButtonHelper
                  className="py-1 pl-1 pr-1 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 transform rotate-180"
                  onClick={handleToggleBottomPanel}
                  icon={IconsEnum.CHEVRON_UP}
                  iconClassName={cl(
                    'h-5 w-5',
                    !isBottomPanelOpened && 'rotate-180'
                  )}
                />
                <div className="text-sm flex gap-0.5 items-center py-3 rounded-t-3xl text-gray-600 dark:text-gray-400">
                  <span className="font-thin">Product configurator</span>
                  {/*Icon: solid/chevron-right*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-4 w-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="dark:text-white text-black">
                    Sofa sections
                  </span>
                </div>
              </div>

              <div
                className={cl(
                  'flex-1 relative',
                  !isBottomPanelOpened && 'hidden'
                )}
              >
                <div className="absolute inset-0 flex divide-x divide-gray-300 dark:divide-gray-700 border-t border-gray-300 dark:border-gray-700">
                  {contentBrowser}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditorLayout;
