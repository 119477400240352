import filter from 'lodash/filter';
import { useParams } from 'react-router-dom';

import { ProductsListItem } from '../ProductsListItem';

import { products } from '../../productsConstants';

function ProductsList() {
  const { brand } = useParams();

  return (
    <div className="px-4 grid grid-cols-auto-fill grid-cell-min-48 xl:grid-cell-min-52 2xl:grid-cell-min-64 3xl:grid-cell-min-72 gap-4 xl:gap-8 2xl:gap-12">
      {filter(products, ['brandKey', brand]).map((product) => (
        <ProductsListItem product={product} key={product.id} />
      ))}
    </div>
  );
}

export default ProductsList;
