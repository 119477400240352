import { useControls } from 'leva';
import { useEffect, useMemo } from 'react';
import { Box3, Matrix4, Vector3 } from 'three';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';

import { sceneHelpersStore } from '../store/sceneHelpersStore';
import { sceneStore } from '../store/sceneStore';
import { globalStore } from '../store/globalStore';

import { Grid } from './components/Grid';

import { profiledContourUV } from '../utils/threejs/profiledContourUV';
import { applyBoxUV } from '../utils/threejs/applyBoxUV';

const gridPadding = 2;
const gridStep = 0.25;

export function Floor() {
  // DEV ONLY
  const [{ meshNormalMaterial }] = useControls('floor', () => ({
    meshNormalMaterial: false
  }));

  const interactiveItemGroup = sceneHelpersStore.use.interactiveItemGroup();
  const sceneMode = sceneStore.use.sceneMode();

  const boundingBox = useMemo<Box3>(() => {
    const box = new Box3();

    box.setFromObject(interactiveItemGroup);

    return box;
  }, [interactiveItemGroup]);

  const boxSize = useMemo<Vector3>(
    () => boundingBox.getSize(new Vector3()),
    [boundingBox]
  );

  const gridPosition = useMemo<Vector3>(() => {
    const center = boundingBox.getCenter(new Vector3()).setY(0);
    center.sub(interactiveItemGroup.position);
    return center;
  }, [boundingBox]);

  const gridWidth = Math.ceil(boxSize.x) + gridPadding;
  const gridHeight = Math.ceil(boxSize.z) + gridPadding;

  const { plan } = sceneStore.use.roomSettings();

  const profileShape = useMemo(() => {
    const shape: number[] = [];

    each(plan, (_, i) => {
      shape.push(plan[i].x, plan[i].y);
    });

    return shape;
  }, [plan]);

  const floorGeometry = useMemo(() => {
    const geometry = profiledContourUV(
      profileShape,
      [0.1, 0, 0, 0],
      true,
      false,
      false
    );

    applyBoxUV(geometry, new Matrix4(), globalStore.get.realWorldUVScale());

    geometry.attributes.uv.needsUpdate = true;

    return geometry;
  }, [profileShape]);

  useEffect(() => {
    floorGeometry.computeBoundingBox();

    const box = floorGeometry.boundingBox || new Box3();

    const matrix = new Matrix4().makeRotationZ(-Math.PI / 2);
    box.applyMatrix4(matrix);

    sceneStore.set.roomSettings({
      ...sceneStore.get.roomSettings(),
      floor: box
    });
  }, [floorGeometry]);

  const materialsLib = sceneStore.use.materialsLib();
  const { materials } = sceneStore.use.roomSettings();

  return (
    <>
      {!isEmpty(interactiveItemGroup.children) && !sceneMode && (
        <Grid
          position={gridPosition}
          width={gridWidth}
          height={gridHeight}
          step={gridStep}
        />
      )}

      <mesh receiveShadow rotation-x={-Math.PI / 2} position-y={-0.001}>
        <planeGeometry args={[20, 20]} />
        <shadowMaterial transparent opacity={0.5} />
        {/* DEVONLY */}
        {meshNormalMaterial && <meshNormalMaterial />}
      </mesh>

      <mesh visible={sceneMode} rotation-z={-Math.PI / 2} position-y={-0.002}>
        <primitive object={floorGeometry} attach="geometry" />
        {materialsLib[materials.floor.name] && (
          <primitive
            object={materialsLib[materials.floor.name]}
            attach="material"
          />
        )}
      </mesh>
    </>
  );
}
