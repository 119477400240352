import { Object3D } from 'three';
import map from 'lodash/map';
import each from 'lodash/each';

import { SlotItemPointAvailable, Slots } from '../types/scene.types';
import { Settings } from '../types/settings.types';

import { preparePoint } from './preparedPoint';
import { parseClearName } from './parseClearName';

export function prepareSlots(
  settings: Settings,
  slotsObj: Object3D,
  isChild: boolean
) {
  const { separator } = settings.naming;

  const slots: Slots = {};

  each(slotsObj.children, (slot) => {
    const clearName = parseClearName(settings, slot.name);

    const points = clearName.split(separator.points);

    if (!points.length)
      throw new Error('Not valid slot name. Points not found in slot.');

    const point = preparePoint(settings, points.shift() || '');

    if (!slots[point.sideName]) {
      slots[point.sideName] = {
        name: point.sideName,
        points: {}
      };
    }

    const available: SlotItemPointAvailable[] = map(points, (point) =>
      preparePoint(settings, point)
    );

    slots[point.sideName].points[point.pointName] = {
      name: point.pointName,
      position: slot.position,
      rotation: slot.rotation,
      available,
      isChild
    };
  });

  return slots;
}
