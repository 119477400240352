import { useGLTF } from '@react-three/drei';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import reduce from 'lodash/reduce';
import { Box3, Euler, Vector2, Vector3 } from 'three';

import {
  ConfiguratorFile,
  ConfiguratorFileScopeSceneBackup
} from '../../types/configuratorFile.types';
import {
  GroupNode,
  RoomSettings,
  Scene,
  SceneNodeItems,
  SceneNodeType,
  SlotNode,
  Slots
} from '../../types/scene.types';

import { globalStore } from '../globalStore';
import { sceneStore } from '../sceneStore';

import { parseGLTFNodes } from '../../utils/parseGLTFNodes';
import { prepareSlots } from '../../utils/prepareSlots';

export function importConfiguratorFile(configuratorFile: ConfiguratorFile) {
  sceneStore.set.clear();
  globalStore.set.clear();
  globalStore.set.mergeState(configuratorFile.globalStore);
  sceneStore.set.mergeState(configuratorFile.sceneStore);

  const settings = globalStore.get.settings();

  if (!settings) return;

  const scene = reduce(
    configuratorFile.sceneStoreBackup.scene,
    (acc, scopeItem, scopeKey) => {
      const scopeItems = reduce(
        scopeItem,
        (acc, rootItem, key) => {
          let model: string | SceneNodeItems = '';

          const prepareLinkItem = {} as GroupNode;

          if (rootItem.type === SceneNodeType.GROUP) {
            model = reduce(
              rootItem.model as ConfiguratorFileScopeSceneBackup,
              (acc, item, key) => {
                let slots: Slots = {};

                const { scene } = useGLTF(item.model as string);

                const { slots: preparedSlots, childs: prepareChilds } =
                  parseGLTFNodes(settings, scene);

                if (preparedSlots) {
                  slots = prepareSlots(settings, preparedSlots, false);
                }

                if (prepareChilds) {
                  mergeWith(
                    slots,
                    prepareSlots(settings, prepareChilds, true),
                    (obj, src) => (isArray(obj) ? obj.concat(src) : undefined)
                  );
                }

                acc[key] = {
                  id: key,
                  model: item.model as string,
                  sku: item.sku,
                  position: new Vector3(
                    item.position.x,
                    item.position.y,
                    item.position.z
                  ),
                  rotation: new Euler(
                    item.rotation.x,
                    item.rotation.y,
                    item.rotation.z,
                    item.rotation.order
                  ),
                  type: item.type,
                  attach: item.attach,
                  stick: item.stick,
                  slots,
                  parent: prepareLinkItem
                } as SlotNode;

                return acc;
              },
              {} as SceneNodeItems
            );
          }

          if (rootItem.type !== SceneNodeType.GROUP) {
            model = rootItem.model as string;
          }

          Object.assign(prepareLinkItem, {
            id: key,
            model,
            sku: rootItem.sku,
            position: new Vector3(
              rootItem.position.x,
              rootItem.position.y,
              rootItem.position.z
            ),
            rotation: new Euler(
              rootItem.rotation.x,
              rootItem.rotation.y,
              rootItem.rotation.z,
              rootItem.rotation.order
            ),
            type: rootItem.type,
            attach: rootItem.attach,
            stick: rootItem.stick,
            parent: null,
            scope: scopeKey
          });

          acc[key] = prepareLinkItem;

          return acc;
        },
        {} as SceneNodeItems
      );

      acc[scopeKey] = scopeItems;

      return acc;
    },
    {} as Scene
  );

  each(scene, (scopeItem, scopeKey) => {
    each(scopeItem, (item, itemKey) => {
      if (item.id === configuratorFile.sceneStoreBackup.configureId) {
        sceneStore.set.configureNode(item);
      }

      if (item.type === SceneNodeType.GROUP) {
        each(item.model as SceneNodeItems, (node, key) => {
          const configureSceneBackup = configuratorFile.sceneStoreBackup.scene[
            scopeKey
          ][itemKey].model as ConfiguratorFileScopeSceneBackup;
          each(configureSceneBackup[key].slotsMap, (slot, slotName) => {
            const slotNode = node as SlotNode;

            slotNode.slots[slotName].points[slot.pointName].node = (
              item.model as SceneNodeItems
            )[slot.pointNodeId] as SlotNode;
            slotNode.slots[slotName].point =
              slotNode.slots[slotName].points[slot.pointName];
          });
        });
      }
    });
  });
  const floor = configuratorFile.sceneStoreBackup.roomSettings.floor;
  const importedRoomSettings = {
    boxes: [],
    plan: [],
    floor: new Box3(
      new Vector3(floor.min.x, floor.min.y, floor.min.z),
      new Vector3(floor.max.x, floor.max.y, floor.max.z)
    ),
    materials: configuratorFile.sceneStoreBackup.roomSettings.materials,
    thickness: configuratorFile.sceneStoreBackup.roomSettings.thickness,
    wallHeight: configuratorFile.sceneStoreBackup.roomSettings.wallHeight
  } as RoomSettings;
  importedRoomSettings.boxes = [];
  for (const key in configuratorFile.sceneStoreBackup.roomSettings.boxes) {
    const box = configuratorFile.sceneStoreBackup.roomSettings.boxes[key];
    importedRoomSettings.boxes.push(
      new Box3(
        new Vector3(box.min.x, box.min.y, box.min.z),
        new Vector3(box.max.x, box.max.y, box.max.z)
      )
    );
  }
  for (const key in configuratorFile.sceneStoreBackup.roomSettings.plan) {
    const plan = configuratorFile.sceneStoreBackup.roomSettings.plan[key];
    importedRoomSettings.plan.push(new Vector2(plan.x, plan.y));
  }

  sceneStore.set.scene(scene);
  sceneStore.set.roomSettings(importedRoomSettings);
}
