import { MaterialType } from '../../types/scene.types';
import { SettingsMaterialAssetItems } from '../../types/settings.types';

export const mockHarmonyTextures: SettingsMaterialAssetItems = {
  'Chenill-Tweeted-Frost-Gray': {
    id: 'Chenill-Tweeted-Frost-Gray',
    previewImage: '/assets/materials/preview/Chenill_Tweeted_Frost_Gray.jpg',
    name: 'Chenill Tweeted Frost Gray',
    filesUrl: {
      [MaterialType.REAL_WORLD]:
        '/assets/materials/Chenill_Tweeted_Frost_Gray.glb',
      [MaterialType.ATLAS]: {
        'Harmony-element-base-1':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Frost-Gray_Harmony-element-base-1.glb',
        'Harmony-element-base-2':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Frost-Gray_Harmony-element-base-2.glb',
        'Harmony-element-base-3':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Frost-Gray_Harmony-element-base-3.glb',
        'Harmony-element-base-4':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Frost-Gray_Harmony-element-base-4.glb',
        'Harmony-element-base-5':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Frost-Gray_Harmony-element-base-5.glb',
        'Harmony-element-base-6':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Frost-Gray_Harmony-element-base-6.glb',
        'Harmony-element-base-7':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Frost-Gray_Harmony-element-base-7.glb'
      }
    }
  },
  'Chenill-Tweeted-Pewter': {
    id: 'Chenill-Tweeted-Pewter',
    previewImage: '/assets/materials/preview/Chenill_Tweeted_Pewter.jpg',
    name: 'Chenill Tweeted Pewter',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/Chenill_Tweeted_Pewter.glb',
      [MaterialType.ATLAS]: {
        'Harmony-element-base-1':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Pewter_Harmony-element-base-1.glb',
        'Harmony-element-base-2':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Pewter_Harmony-element-base-2.glb',
        'Harmony-element-base-3':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Pewter_Harmony-element-base-3.glb',
        'Harmony-element-base-4':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Pewter_Harmony-element-base-4.glb',
        'Harmony-element-base-5':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Pewter_Harmony-element-base-5.glb',
        'Harmony-element-base-6':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Pewter_Harmony-element-base-6.glb',
        'Harmony-element-base-7':
          '/assets/materials/atlas/harmohy/Chenill-Tweeted-Pewter_Harmony-element-base-7.glb'
      }
    }
  },
  'Performance-Basketweawe-Camel': {
    id: 'Performance-Basketweawe-Camel',
    previewImage: '/assets/materials/preview/Performance_Basketweawe_Camel.jpg',
    name: 'Performance Basketweawe Camel',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'Harmony-element-base-1':
          '/assets/materials/atlas/harmohy/Performance-Basketweawe-Camel_Harmony-element-base-1.glb',
        'Harmony-element-base-2':
          '/assets/materials/atlas/harmohy/Performance-Basketweawe-Camel_Harmony-element-base-2.glb',
        'Harmony-element-base-3':
          '/assets/materials/atlas/harmohy/Performance-Basketweawe-Camel_Harmony-element-base-3.glb',
        'Harmony-element-base-4':
          '/assets/materials/atlas/harmohy/Performance-Basketweawe-Camel_Harmony-element-base-4.glb',
        'Harmony-element-base-5':
          '/assets/materials/atlas/harmohy/Performance-Basketweawe-Camel_Harmony-element-base-5.glb',
        'Harmony-element-base-6':
          '/assets/materials/atlas/harmohy/Performance-Basketweawe-Camel_Harmony-element-base-6.glb',
        'Harmony-element-base-7':
          '/assets/materials/atlas/harmohy/Performance-Basketweawe-Camel_Harmony-element-base-7.glb'
      }
    }
  },
  'Blue-fabric': {
    id: 'Blue-fabric',
    previewImage: '/assets/materials/preview/Blue_fabric.jpg',
    name: 'Blue fabric',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/Blue_fabric.glb'
    }
  },
  'dark-metal': {
    id: 'dark-metal',
    previewImage: '/assets/materials/preview/dark_metal.jpg',
    name: 'Dark metal',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/dark_metal.glb',
      [MaterialType.ATLAS]: {
        'Harmony-element-legs-1':
          '/assets/materials/atlas/harmohy/dark-metal_Harmony-element-legs-1.glb',
        'Harmony-element-legs-2':
          '/assets/materials/atlas/harmohy/dark-metal_Harmony-element-legs-2.glb',
        'Harmony-element-legs-3':
          '/assets/materials/atlas/harmohy/dark-metal_Harmony-element-legs-3.glb',
        'Harmony-element-legs-4':
          '/assets/materials/atlas/harmohy/dark-metal_Harmony-element-legs-4.glb',
        'Harmony-element-legs-5':
          '/assets/materials/atlas/harmohy/dark-metal_Harmony-element-legs-5.glb',
        'Harmony-element-legs-6':
          '/assets/materials/atlas/harmohy/dark-metal_Harmony-element-legs-6.glb',
        'Harmony-element-legs-7':
          '/assets/materials/atlas/harmohy/dark-metal_Harmony-element-legs-7.glb'
      }
    }
  },
  'light-metal': {
    id: 'light-metal',
    previewImage: '/assets/materials/preview/light_metal.jpg',
    name: 'Light metal',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/light_metal.glb',
      [MaterialType.ATLAS]: {
        'Harmony-element-legs-1':
          '/assets/materials/atlas/harmohy/light-metal_Harmony-element-legs-1.glb',
        'Harmony-element-legs-2':
          '/assets/materials/atlas/harmohy/light-metal_Harmony-element-legs-2.glb',
        'Harmony-element-legs-3':
          '/assets/materials/atlas/harmohy/light-metal_Harmony-element-legs-3.glb',
        'Harmony-element-legs-4':
          '/assets/materials/atlas/harmohy/light-metal_Harmony-element-legs-4.glb',
        'Harmony-element-legs-5':
          '/assets/materials/atlas/harmohy/light-metal_Harmony-element-legs-5.glb',
        'Harmony-element-legs-6':
          '/assets/materials/atlas/harmohy/light-metal_Harmony-element-legs-6.glb',
        'Harmony-element-legs-7':
          '/assets/materials/atlas/harmohy/light-metal_Harmony-element-legs-7.glb'
      }
    }
  },
  wood: {
    id: 'wood',
    previewImage: '/assets/materials/preview/wood.jpg',
    name: 'Wood',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/wood.glb',
      [MaterialType.ATLAS]: {
        'Harmony-element-legs-1':
          '/assets/materials/atlas/harmohy/wood_Harmony-element-legs-1.glb',
        'Harmony-element-legs-2':
          '/assets/materials/atlas/harmohy/wood_Harmony-element-legs-2.glb',
        'Harmony-element-legs-3':
          '/assets/materials/atlas/harmohy/wood_Harmony-element-legs-3.glb',
        'Harmony-element-legs-4':
          '/assets/materials/atlas/harmohy/wood_Harmony-element-legs-4.glb',
        'Harmony-element-legs-5':
          '/assets/materials/atlas/harmohy/wood_Harmony-element-legs-5.glb',
        'Harmony-element-legs-6':
          '/assets/materials/atlas/harmohy/wood_Harmony-element-legs-6.glb',
        'Harmony-element-legs-7':
          '/assets/materials/atlas/harmohy/wood_Harmony-element-legs-7.glb'
      }
    }
  }
};
