import each from 'lodash/each';

import { SceneNode, SlotNode } from '../../types/scene.types';

export function detachSlotsForSlotNode(
  node: SlotNode,
  onDeleteSceneItem: (node: SceneNode) => void
) {
  each(node.slots, (slot) => {
    if (!slot.point) return;

    if (slot.point.node) {
      each(slot.point.node.slots, (refSlot) => {
        if (refSlot.point?.node === node) {
          refSlot.point.node = undefined;
          refSlot.point = undefined;
          return false;
        }
      });

      if (slot.point.isChild) {
        onDeleteSceneItem(slot.point.node);
      }

      slot.point.node = undefined;
    }

    slot.point = undefined;
  });
}
