import React, { memo } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import { useMenu } from '../../../hooks/useMenu';
import { usePrimaryMenuHover } from './hooks/usePrimaryMenuHover';

import { PrimaryMenuMobilesToggleSecondaryMenu } from './components/PrimaryMenuMobilesToggleSecondaryMenu';
import { PrimaryNav } from '../PrimaryNav';
import { PrimaryMenuKeepSecondaryButton } from './components/PrimaryMenuKeepSecondaryButton';

import { Icon } from '../../../../helpers/Icon';

function PrimaryMenu() {
  const {
    isSecondaryMenuKeepOpen,
    openSecondaryMenu,
    toggleKeepSecondaryMenu
  } = useMenu();

  const { handleMouseEnter, handleMouseLeave } = usePrimaryMenuHover({
    openSecondaryMenu
  });

  return (
    <div
      data-id="nav-primary-wrap"
      className="z-20 flex sm:flex-col h-10 sm:h-full w-full sm:w-16 border-t sm:border-t-0 sm:border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-900"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="hidden sm:inline-flex h-16 w-16 items-center justify-center text-white bg-gradient-to-tr from-blue-600 to-blue-400">
        <Icon icon={IconsEnum.AV} />
      </div>

      <nav
        data-id="nav-primary"
        className="flex-grow w-full md:flex-shrink-0 relative"
        aria-label="Sidebar"
      >
        <div className="absolute inset-0 flex flex-col sm:overflow-y-auto sm:overflow-x-hidden justify-between">
          <div className="flex sm:flex-col sm:py-3 sm:px-1 sm:space-y-1 h-full w-full sm:h-auto">
            <PrimaryMenuMobilesToggleSecondaryMenu />
            <PrimaryNav />
            <div className="p-1 sm:hidden" />
          </div>
          <div
            data-id="nav-primary-middle"
            className="flex-shrink-0 p-3 hidden sm:block"
          >
            <PrimaryMenuKeepSecondaryButton
              isSecondaryMenuKeepOpen={isSecondaryMenuKeepOpen}
              toggleKeepSecondaryMenu={toggleKeepSecondaryMenu}
            />
          </div>
          <div
            data-id="nav-primary-bottom"
            className="flex-shrink-0 py-3 hidden sm:block space-y-3"
          />
        </div>
      </nav>
    </div>
  );
}

export default memo(PrimaryMenu);
