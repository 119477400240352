import { IconProps } from '../types';

function RulerSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-6 w-6'}
      fill="currenColor"
      viewBox="0 0 20 20"
    >
      <path d="m19.7 5.9-5.6-5.6c-.4-.4-1-.4-1.4 0l-12.4 12.4c-.4.4-.4 1 0 1.4l5.6 5.6c.4.4 1 .4 1.4 0 2.3-2.4 9.8-9.9 12.5-12.5.3-.3.3-1-.1-1.3zm-13.1 11.7-4.1-4.1 11-11.2 4.1 4.1-.7.7-1.4-1.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.4 1.4-1.4 1.5-.7-.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.7.7-1.4 1.4-1.3-1.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.4 1.4-1.4 1.4-.7-.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.7.7z" />
    </svg>
  );
}

export default RulerSolidIcon;
