import { useMemo, useState } from 'react';

import {
  preventDefaultDragStart,
  useDragHtml
} from '../../../../hooks/useDragHtml';

export interface ContentBrowserModelProps {
  id: string;
  img: string;
  name: string;
  category?: string;
}

function ContentBrowserModel({
  id,
  img,
  name,
  category
}: ContentBrowserModelProps) {
  const { dndId, shift, active, visibility, onPointerDown } = useDragHtml(id);

  const [wrapperEl, setWrapperEl] = useState<HTMLDivElement | null>(null);

  const offsetWtapperParent = useMemo(() => {
    if (!wrapperEl) return { left: 0, top: 0 };

    let left = 0;
    let top = 0;
    let parent: HTMLElement | null = wrapperEl;

    while (parent) {
      left += parent.offsetLeft;
      top += parent.offsetTop;
      parent = parent.offsetParent as HTMLElement | null;
    }

    return { left, top };
  }, [wrapperEl]);

  const cloneStyles = {
    transform: `translate(${offsetWtapperParent.left + shift.x}px, ${
      offsetWtapperParent.top + shift.y
    }px)`,
    width: `${wrapperEl?.offsetWidth}px`,
    height: `${wrapperEl?.offsetHeight}px`
  };

  return (
    <div>
      <div
        className="aspect-1 rounded dark:bg-gray-850 relative"
        onPointerDown={onPointerDown}
        style={{ pointerEvents: active ? 'none' : 'auto' }}
        ref={setWrapperEl}
      >
        <img
          src={img}
          alt={name}
          className="object-cover h-full w-full rounded"
          onDragStart={preventDefaultDragStart}
        />
      </div>
      <div className="flex flex-col gap-0.5 py-1">
        <div className="text-2xs line-clamp-1 text-gray-500">{category}</div>
        <div className="text-2xs line-clamp-3">{name}</div>
      </div>

      {dndId === id && active && visibility && (
        <div
          className="fixed top-0 left-0 pointer-events-none outline z-50 aspect-1 rounded dark:bg-gray-850"
          style={cloneStyles}
        >
          <img
            src={img}
            alt={name}
            className="object-cover h-full w-full rounded"
            onDragStart={preventDefaultDragStart}
          />
        </div>
      )}
    </div>
  );
}

export default ContentBrowserModel;
