import {
  SceneNodeAttach,
  SceneNodeStick,
  SceneNodeType
} from '../../types/scene.types';
import { SettingsModelAssetItems } from '../../types/settings.types';

export const mockSku446: SettingsModelAssetItems = {
  sku446_angular: {
    id: 'sku446_angular',
    parentId: 'sku446',
    previewImage: '/assets/models/sku446/preview/angular.jpg',
    name: 'Angular',
    fileUrl: '/assets/models/sku446/angular.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  sku446_middle: {
    id: 'sku446_middle',
    parentId: 'sku446',
    previewImage: '/assets/models/sku446/preview/middle.jpg',
    name: 'Middle',
    fileUrl: '/assets/models/sku446/middle.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  sku446_left_arm: {
    id: 'sku446_left_arm',
    parentId: 'sku446',
    previewImage: '/assets/models/sku446/preview/left_arm.jpg',
    name: 'Left Arm',
    fileUrl: '/assets/models/sku446/left_arm.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  sku446_right_arm: {
    id: 'sku446_right_arm',
    parentId: 'sku446',
    previewImage: '/assets/models/sku446/preview/right_arm.jpg',
    name: 'Right Arm',
    fileUrl: '/assets/models/sku446/right_arm.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  sku446_left_arm_long: {
    id: 'sku446_left_arm_long',
    parentId: 'sku446',
    previewImage: '/assets/models/sku446/preview/left_arm_long.jpg',
    name: 'Left Arm Long',
    fileUrl: '/assets/models/sku446/left_arm_long.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  sku446_right_arm_long: {
    id: 'sku446_right_arm_long',
    parentId: 'sku446',
    previewImage: '/assets/models/sku446/preview/right_arm_long.jpg',
    name: 'Right Arm Long',
    fileUrl: '/assets/models/sku446/right_arm_long.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  }
};
