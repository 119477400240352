import mitt from 'mitt';

export const enum ConfiguratorEvents {
  DELETE = 'delete',
  ZOOM_IN = 'zoom_in',
  ZOOM_OUT = 'zoom_out'
}

type ConfiguratorEventsTypes = {
  [ConfiguratorEvents.DELETE]: string;
  [ConfiguratorEvents.ZOOM_IN]: unknown;
  [ConfiguratorEvents.ZOOM_OUT]: unknown;
};

export const configuratorEvents = mitt<ConfiguratorEventsTypes>();
