import React from 'react';
import { Link } from 'react-router-dom';

import { IconsEnum } from '../../../../assets/icons/types';
import { ProductsListItemProduct } from './ProductsListItem.types';

import { ProductsListItemNda } from './components/ProductsListItemNda';

import { Icon } from '../../../../helpers/Icon';
import { TextWithTooltip } from '../../../../helpers/TextWithTooltip';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';
import { Translate } from '../../../../helpers/Translate';

import { words } from '../../../../locales/keys';

interface ProductsListItemProps {
  product: ProductsListItemProduct;
}

function ProductsListItem({ product }: ProductsListItemProps) {
  return (
    <div className="w-80 max-w-full mx-auto relative rounded-md group">
      <div className="relative rounded-md overflow-hidden aspect-w-1 aspect-h-1">
        <img
          className="object-cover w-full h-full"
          src={product.preview}
          alt="Tara Ultra Nickel Matte by Dornbracht"
        />
      </div>
      <div className="text-sm p-3">
        <div className="font-medium truncate">
          <TextWithTooltip
            text={product.name}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="text-gray-600 dark:text-gray-500 truncate">
          <ProductsListItemNda product={product} />
          <TextWithTooltip
            text={product.brand}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="mt-3 flex justify-between items-center relative z-5">
          <Link
            to={`/${product.brandKey}/products/${product.id}`}
            className="border-gray-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 bg-yellow-50 dark:bg-yellow-900 dark:bg-opacity-50 border font-medium hover:shadow inline-flex gap-1 items-center px-2 py-1 relative rounded-md shadow-sm text-sm"
          >
            <Icon
              icon={IconsEnum.CUBE_TRANSPARENT_OUTLINE}
              className="h-4 w-4"
            />
            <Translate id={words.configure} />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProductsListItem;
