export function createAndDownloadFile(
  content: string,
  filename: string,
  mimeType = 'application/json'
) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:${mimeType};charset=utf-8,` + encodeURIComponent(content)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
