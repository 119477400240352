import { IconProps } from '../types';

function ArrowLeftOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-6 w-6'}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 19l-7-7m0 0l7-7m-7 7h18"
      />
    </svg>
  );
}

export default ArrowLeftOutlineIcon;
