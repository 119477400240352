import React from 'react';

import { IconsEnum } from '../../../../../../assets/icons/types';
import { SettingsInfo } from '../../../../../../types/settings.types';

import { ProductPageMenuHeader } from '../ProductPageMenuHeader';

import { PureIconButtonHelper } from '../../../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../../../helpers/Translate';

import { words } from '../../../../../../locales/keys';

interface PageMenuHomeContentProps {
  info: SettingsInfo;
  onAddDecorationButtonClick: () => void;
  onAddSectionButtonClick: () => void;
  onChangeMaterialsButtonClick: () => void;
  onWallsButtonClick: () => void;
}

function PageMenuHomeContent({
  info,
  onAddDecorationButtonClick,
  onAddSectionButtonClick,
  onChangeMaterialsButtonClick,
  onWallsButtonClick
}: PageMenuHomeContentProps) {
  return (
    <>
      <div className="w-120 max-w-full mx-auto">
        <div className="flex flex-col px-4">
          <div className="w-full mb-8">
            <div className="aspect-w-1 aspect-h-1 rounded-xl overflow-hidden relative">
              <img
                className="object-cover w-full h-full"
                src={info.preview}
                alt="Product preview"
              />
            </div>
          </div>
          <div className="w-full flex-1">
            <p className="text-lg text-gray-500">{info.brand}</p>
            <ProductPageMenuHeader info={info} />
            {/*<ProductPageMenuDetails info={info} />*/}
          </div>
        </div>
      </div>

      <div className="p-4 mt-8">
        <div className="uppercase text-gray-500">
          <Translate id={words.editProduct} />
        </div>

        <div className="flex flex-col -mx-2 mt-4 gap-1">
          <PureIconButtonHelper
            className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full"
            i18nText={words.addSection}
            icon={IconsEnum.VIEW_GRID_ADD}
            iconClassName="h-6 w-6 text-gray-500 mr-2"
            onClick={onAddSectionButtonClick}
          />

          <PureIconButtonHelper
            className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full"
            i18nText={words.addDecoration}
            icon={IconsEnum.VIEW_GRID_ADD}
            iconClassName="h-6 w-6 text-gray-500 mr-2"
            onClick={onAddDecorationButtonClick}
          />

          <PureIconButtonHelper
            className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full"
            i18nText={words.changeMaterials}
            icon={IconsEnum.COLOR_SWATCH_OUTLINE}
            iconClassName="h-6 w-6 text-gray-500 mr-2"
            onClick={onChangeMaterialsButtonClick}
          />

          <PureIconButtonHelper
            className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full"
            i18nText={words.walls}
            icon={IconsEnum.CUBE_TRANSPARENT_OUTLINE}
            iconClassName="h-6 w-6 text-gray-500 mr-2"
            onClick={onWallsButtonClick}
          />
        </div>
      </div>
    </>
  );
}

export default PageMenuHomeContent;
