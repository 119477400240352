import { useCallback } from 'react';
import { ThreeEvent } from '@react-three/fiber';

import { SceneNode } from '../types/scene.types';

import { sceneStore } from '../store/sceneStore';

export function useHoverable(node: SceneNode) {
  const handlePointerEnter = useCallback((event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation();
    sceneStore.set.hoveredNode(node);
  }, []);

  const handlePointerLeave = useCallback(() => {
    sceneStore.set.hoveredNode(null);
  }, []);

  return {
    hoveredNode: sceneStore.use.hoveredNode(),
    handlePointerEnter,
    handlePointerLeave
  };
}
