import { IconProps } from '../types';

function DoorOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 552 552"
      fill="currentColor"
    >
      <g transform="translate(-100, -100)">
        <path d="m481.51 589.02h-206.23c-11.926 0-21.617-9.7148-21.617-21.645l-0.003907-382.93c0-11.934 9.6992-21.648 21.617-21.648h206.23c11.934 0 21.629 9.7148 21.629 21.648v382.93c0.007812 11.93-9.6914 21.641-21.625 21.641zm-206.23-404.57-0.027344 382.93 205.39 0.066407c0.50391 0 0.90625-0.41797 0.90625-0.91797l-0.007813-382.08z" />
        <path d="m448.57 398.92h-20.516c-5.7148 0-10.363-4.6367-10.363-10.363 0-5.7266 4.6445-10.367 10.363-10.367h20.516c5.7227 0 10.363 4.6406 10.363 10.367 0 5.7266-4.6406 10.363-10.363 10.363z" />
      </g>
    </svg>
  );
}

export default DoorOutlineIcon;
