export const enum IconsEnum {
  ARROW_LEFT = 'arrow_left',
  ARROW_LEFT_OUTLINE = 'arrow_left_outline',
  ARROW_PATH = 'arrow_path',
  ARROW_RIGHT_SOLID = 'arrow_right_solid',
  ARROW_UP_ON_SQUARE = 'arrow_up_on_square',
  AV = 'av',
  CALCULATOR = 'calculator',
  CHECK = 'check',
  CHEVRON_UP = 'chevron_up',
  CHEVRON_RIGHT = 'chevron_right',
  COLOR_SWATCH_OUTLINE = 'color_swatch_outline',
  CPU_CHIP = 'cpu_chip',
  CROSS = 'cross',
  CUBE_OUTLINE = 'cube_outline',
  CUBE_SOLID = 'cube_solid',
  CUBE_TRANSPARENT_OUTLINE = 'cube_transparent_outline',
  DISTANCE_SOLID = 'distance_solid',
  DOCUMENT_ARROW_DOWN_OUTLINE = 'document_arrow_down_outline',
  DOCUMENT_ARROW_UP_OUTLINE = 'document_arrow_up_outline',
  DOOR_OUTLINE = 'door_outline',
  EXCLAMATION_CIRCLE = 'exclamation_circle',
  MENU_OUTLINE = 'menu_outline',
  MINUS_OUTLINE = 'minus_outline',
  PLUS_OUTLINE = 'plus_outline',
  REFRESH_OUTLINE = 'refresh_outline',
  RULER_OUTLINE = 'ruler_outline',
  RULER_SOLID = 'ruler_solid',
  TRASH_OUTLINE = 'trash_outline',
  VIDEO_CAMERA_SOLID = 'video_camera_solid',
  VIEW_GRID_ADD = 'view_grid_add',
  X = 'x',
  ZOOM_IN_OUTLINE = 'zoom_in_outline',
  ZOOM_OUT_OUTLINE = 'zoom_out_outline',
  HOME = 'home',
  PENCIL_SQUARE = 'pencil_square',
  WINDOW_OUTLINE = 'window_outline'
}

export interface IconProps {
  className?: string;
  id?: string;
  strokeWidth?: string;
}
