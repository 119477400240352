import map from 'lodash/map';

import { SettingsModelAssetItems } from '../../../../../../types/settings.types';

import { ProductSidebarSection } from '../../../ProductSidebarSection';

import { Translate } from '../../../../../../helpers/Translate';

import { words } from '../../../../../../locales/keys';

interface PageMenuAddSectionContentProps {
  assets: SettingsModelAssetItems;
}

function PageMenuAddSectionContent({ assets }: PageMenuAddSectionContentProps) {
  return (
    <div className="p-4 space-y-4" data-toggle-item="add-section">
      <div className="space-y-4">
        <div className="uppercase text-gray-500">
          <Translate id={words.components} />
        </div>

        <div className="grid grid-cols-2 gap-2 justify-center">
          {map(assets, (item) => (
            <ProductSidebarSection
              key={item.id}
              id={item.id}
              name={item.name}
              img={item.previewImage}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PageMenuAddSectionContent;
