import { useMemo } from 'react';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';

import { IconsEnum } from '../../../../assets/icons/types';
import {
  ModelBind,
  SettingsModelAssetItems
} from '../../../../types/settings.types';

import { globalStore } from '../../../../store/globalStore';
import { sceneStore } from '../../../../store/sceneStore';
import {
  ConfigureProductPageMenuModes,
  useConfigureProductPageMenu
} from './hooks/useConfigureProductPageMenu';

import { PageMenuAddSectionContent } from './components/PageMenuAddSectionContent';
import { PageMenuChangeMaterialsContent } from './components/PageMenuChangeMaterialsContent';
import { PageMenuHomeContent } from './components/PageMenuHomeContent';
import { PageMenuWallsContent } from './components/PageMenuWallsContent';
import { SecondaryMenuWrapper } from '../../../../app/components/menus/SecondaryMenuWrapper';

import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../helpers/Translate';

import { words } from '../../../../locales/keys';

function ConfigureProductPageMenu() {
  const settings = globalStore.useTracked.settings();

  const materials = sceneStore.useTracked.materials();

  const {
    menuMode,
    menuI18nTitle,
    handleGoBack,
    handleChangeMaterials,
    handleAddDecoration,
    handleAddSection,
    handleSetWalls
  } = useConfigureProductPageMenu();

  const modelAssets = useMemo(
    () =>
      reduce(
        settings?.models.assets,
        (acc, asset, assetName) =>
          (includes(settings?.models.bind[ModelBind.MODEL], assetName)
            ? (acc[assetName] = asset)
            : true) && acc,
        {} as SettingsModelAssetItems
      ),
    [settings?.models.assets, settings?.models.bind]
  );

  const decorationAssets = useMemo(
    () =>
      reduce(
        settings?.models.assets,
        (acc, asset, assetName) =>
          (includes(settings?.models.bind[ModelBind.DECORATIONS], assetName)
            ? (acc[assetName] = asset)
            : true) && acc,
        {} as SettingsModelAssetItems
      ),
    [settings?.models.assets, settings?.models.bind]
  );

  if (!settings) return null;

  return (
    <SecondaryMenuWrapper i18nTitle={words.interiorConfigurator}>
      <div className="p-4">
        <div className="-mx-4 mb-1">
          <div className="flex items-center group relative px-4 py-2">
            <PureIconButtonHelper
              className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 -mx-1 -my-2"
              icon={IconsEnum.ARROW_LEFT_OUTLINE}
              onClick={handleGoBack}
            />
            <h6 className="flex-1 font-semibold uppercase px-2">
              <Translate id={menuI18nTitle} />
            </h6>
          </div>
        </div>
      </div>

      {menuMode === ConfigureProductPageMenuModes.HOME && (
        <PageMenuHomeContent
          info={settings.info}
          onAddDecorationButtonClick={handleAddDecoration}
          onAddSectionButtonClick={handleAddSection}
          onChangeMaterialsButtonClick={handleChangeMaterials}
          onWallsButtonClick={handleSetWalls}
        />
      )}

      {menuMode === ConfigureProductPageMenuModes.ADD_SECTION && (
        <PageMenuAddSectionContent assets={modelAssets} />
      )}

      {menuMode === ConfigureProductPageMenuModes.ADD_DECORATION && (
        <PageMenuAddSectionContent assets={decorationAssets} />
      )}

      {menuMode === ConfigureProductPageMenuModes.CHANGE_MATERIALS && (
        <PageMenuChangeMaterialsContent
          materials={materials}
          settingsMaterials={settings.materials}
        />
      )}

      {menuMode == ConfigureProductPageMenuModes.WALLS && (
        <PageMenuWallsContent />
      )}
    </SecondaryMenuWrapper>
  );
}

export default ConfigureProductPageMenu;
