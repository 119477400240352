import { useGLTF } from '@react-three/drei';
import { createStore } from '@udecode/zustood';
import { Group, Object3D } from 'three';
import each from 'lodash/each';

import { SceneNodeType } from '../types/scene.types';

import { globalStore } from './globalStore';
import { sceneStore } from './sceneStore';

import { parseGLTFNodes } from '../utils/parseGLTFNodes';

export const sceneHelpersStore = createStore('sceneHelpersStore')({
  interactiveItemGroup: new Group()
});

function updateSceneGroup() {
  const configureNode = sceneStore.get.configureNode();
  const sectioningMode = sceneStore.get.sectioningMode();
  const selectedNode = sceneStore.get.selectedNode();
  const draggedNode = sceneStore.get.draggedNode();
  const settings = globalStore.get.settings();

  if (!settings) return;

  const node =
    (sectioningMode && draggedNode ? selectedNode : null) ||
    configureNode ||
    draggedNode ||
    selectedNode;
  const group = new Group();

  if (!node) {
    sceneHelpersStore.set.interactiveItemGroup(group);
    return;
  }

  if (node?.type === SceneNodeType.GROUP) {
    group.position.copy(node.position);
    group.rotation.copy(node.rotation);

    // TODO: add while loop for recursive hierarchy clone

    each(node?.model, (node) => {
      if (node.type === SceneNodeType.GROUP) return;

      const { scene: nodeScene } = useGLTF(node.model);

      const { model } = parseGLTFNodes(settings, nodeScene);

      const object = model?.clone() || new Object3D();

      object.position.copy(node.position);
      object.rotation.copy(node.rotation);

      group.add(object);
    });
  } else {
    if (!node.position) return;
    const { scene: nodeScene } = useGLTF(node.model);

    const { model } = parseGLTFNodes(settings, nodeScene);

    const object = model?.clone() || new Object3D();
    if (node.type !== SceneNodeType.CAMERA) {
      object.position.copy(node.position);
      object.rotation.copy(node.rotation);
    }

    group.add(object);
  }

  sceneHelpersStore.set.interactiveItemGroup(group);
}

sceneStore.store.subscribe(
  (
    {
      scene,
      sectioningMode,
      configureNode,
      selectedNode,
      draggedNode,
      techScope,
      draggedToPosition
    },
    {
      scene: prevScene,
      sectioningMode: prevSectioningMode,
      configureNode: prevConfigureNode,
      selectedNode: prevSelectedNode,
      draggedNode: prevPreview,
      techScope: prevTechScope,
      draggedToPosition: prevDraggedToPosition
    }
  ) => {
    if (
      scene === prevScene &&
      sectioningMode === prevSectioningMode &&
      configureNode === prevConfigureNode &&
      selectedNode === prevSelectedNode &&
      draggedNode === prevPreview &&
      techScope.preview === prevTechScope.preview &&
      draggedToPosition === prevDraggedToPosition
    )
      return;

    updateSceneGroup();
  }
);
