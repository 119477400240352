import { useCallback, useEffect, useRef } from 'react';
import { Group } from 'three';
import { GroupNode } from '../types/scene.types';

import { sceneStore } from '../store/sceneStore';
import { dndStore } from '../store/dndStore';

import { HandleDraggableEvent } from '../hooks/useDraggable';
import { useSelectable } from '../hooks/useSelectable';

import { GroupModel } from './GroupModel';

import { Draggable } from './helpers/Draggable';
import { Hoverable } from './helpers/Hoverable';

type DraggableGroupModelProps = {
  node: GroupNode;
};

export function DraggableGroupModel({ node }: DraggableGroupModelProps) {
  const selectedNode = sceneStore.use.selectedNode();

  const { handleClick } = useSelectable(node);

  const group = useRef<Group>();

  const handlePointerDown = useCallback<HandleDraggableEvent>(
    ({ planeIntersectPoint }) => {
      dndStore.get
        .modelShift()
        .set(
          node.position.x - planeIntersectPoint.x,
          0,
          node.position.z - planeIntersectPoint.z
        );
    },
    [node.position.x, node.position.y]
  );

  const handleDragStart = useCallback<HandleDraggableEvent>(() => {
    sceneStore.set.updatePreview(node);

    sceneStore.set.deleteSceneNode(node);
    sceneStore.set.draggedNode(node);
    sceneStore.set.selectedNode(node);
  }, [node]);

  useEffect(() => {
    group.current &&
      selectedNode === node &&
      sceneStore.set.draggedObject(group.current);
  }, [selectedNode]);

  return (
    <Hoverable node={node}>
      <Draggable
        onPointerDown={handlePointerDown}
        onDragStart={handleDragStart}
        onClick={handleClick}
      >
        <GroupModel ref={group} node={node} />
      </Draggable>
    </Hoverable>
  );
}
