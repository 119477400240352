import cl from 'classnames';
import map from 'lodash/map';
import { useState } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import { Icon } from '../../../Icon';

export interface TreeViewerNodeProps {
  icon: IconsEnum;
  label: string;
  childrenNodes?: TreeViewerNodeProps[];
  selected?: boolean;
  onClick?: () => void;
}

function TreeViewerNode({
  icon,
  label,
  childrenNodes,
  selected,
  onClick
}: TreeViewerNodeProps) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <button
        className={cl(
          'relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none  font-normal cursor-default',
          selected
            ? 'text-blue-600 bg-blue-100 dark:text-blue-300 dark:bg-blue-700/20'
            : 'hover:bg-gray-200 dark:hover:bg-gray-800'
        )}
        onClick={onClick}
      >
        {childrenNodes && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
          >
            <Icon
              icon={IconsEnum.CHEVRON_RIGHT}
              className={cl(
                'h-4 w-4 absolute left-0',
                isOpen ? 'rotate-90' : ''
              )}
            />
          </div>
        )}

        <Icon icon={icon} className="h-3.5 w-3.5 mr-1" />
        <span className="truncate">{label}</span>
      </button>
      {childrenNodes && isOpen && (
        <div className="pl-4">
          <div className="border-l border-gray-700">
            {map(childrenNodes, (item, i) => (
              <TreeViewerNode
                key={i}
                icon={item.icon}
                label={item.label}
                childrenNodes={item.childrenNodes}
                selected={item.selected}
                onClick={item.onClick}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default TreeViewerNode;
