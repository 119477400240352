import { useCallback } from 'react';
import cl from 'classnames';
import isString from 'lodash/isString';
import each from 'lodash/each';
import { Euler } from 'three';
import { IconsEnum } from '../../../../assets/icons/types';
import { SceneNodeType } from '../../../../types/scene.types';

import {
  ConfiguratorEvents,
  configuratorEvents
} from '../../../../events/configuratorEvents';

import { globalStore, MeasurementsSystem } from '../../../../store/globalStore';
import { sceneStore } from '../../../../store/sceneStore';

import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';
import { PureButtonHelper } from '../../../../helpers/buttons/PureButtonHelper';
import { InputNumber } from '../../../../helpers/inputs/InputNumber';

import { exportConfiguratorFile } from '../../../../store/utils/exportConfiguratorFile';
import { importConfiguratorFile } from '../../../../store/utils/importConfiguratorFile';
import { createAndDownloadFile } from '../../../../utils/createAndDownloadFile';
import { loadFile, loadFilePath } from '../../../../utils/loadFile';

function ConfigureProductPageHeader() {
  const handleZoomIn = useCallback(() => {
    configuratorEvents.emit(ConfiguratorEvents.ZOOM_IN);
  }, []);

  const handleZoomOut = useCallback(() => {
    configuratorEvents.emit(ConfiguratorEvents.ZOOM_OUT);
  }, []);

  const enableMeasurements = globalStore.use.enableMeasurements();
  const enableRoomMeasurements = globalStore.use.enableRoomMeasurements();
  const collisionEnabled = globalStore.use.collisionEnabled();
  const enableDoor = globalStore.use.enableDoor();
  const enableWindow = globalStore.use.enableWindow();
  const measurementsSystem = globalStore.use.measurementsSystem();

  const sceneMode = sceneStore.use.sceneMode();
  const sectioningMode = sceneStore.use.sectioningMode();
  const selectedNode = sceneStore.use.selectedNode();
  const configureNode = sceneStore.use.configureNode();

  const handleToggleMeasurements = useCallback(() => {
    globalStore.set.enableMeasurements(!enableMeasurements);
  }, [enableMeasurements]);

  const handleToggleRoomMeasurements = useCallback(() => {
    globalStore.set.enableRoomMeasurements(!enableRoomMeasurements);
  }, [enableRoomMeasurements]);

  const handleSetMetricalMeasurementsSystem = useCallback(() => {
    globalStore.set.measurementsSystem(MeasurementsSystem.METRICAL);
  }, []);

  const handleSetAmericanMeasurementsSystem = useCallback(() => {
    globalStore.set.measurementsSystem(MeasurementsSystem.AMERICAN);
  }, []);

  const handleToggleCollision = useCallback(() => {
    globalStore.set.collisionEnabled(!globalStore.get.collisionEnabled());
  }, []);

  const handleToggleDoor = useCallback(() => {
    globalStore.set.enableDoor(!enableDoor);
  }, [enableDoor]);

  const handleToggleWindow = useCallback(() => {
    globalStore.set.enableWindow(!enableWindow);
  }, [enableWindow]);

  const handleRotate = useCallback(() => {
    sceneStore.set.rotateBy(new Euler(0, 45 * (Math.PI / 180), 0));
  }, [enableWindow]);

  const handleSave = useCallback(() => {
    const sceneFile = exportConfiguratorFile();

    createAndDownloadFile(JSON.stringify(sceneFile), 'scene.json');
  }, []);

  const handleLoad = useCallback(async () => {
    const file = await loadFile();

    if (!isString(file)) return;
    const sceneFile = JSON.parse(file);

    importConfiguratorFile(sceneFile);
  }, []);

  const handleLoadGLB = useCallback(async () => {
    sceneStore.set.testObject(null);
    const file = await loadFilePath('.glb');
    sceneStore.set.testObject(file);
  }, []);

  const handleSceneModeToggle = useCallback(async () => {
    if (
      sceneMode &&
      !sectioningMode &&
      selectedNode?.type === SceneNodeType.GROUP
    ) {
      sceneStore.set.configureNode(selectedNode);
    }

    if (!sceneMode) {
      sceneStore.set.configureNode(null);
    }

    if (sceneMode) {
      sceneStore.set.roomSettings({
        ...sceneStore.get.roomSettings(),
        boxes: []
      });
    }

    sceneStore.set.selectedNode(null);

    sceneStore.set.sectioningMode(sceneMode);
    sceneStore.set.sceneMode(!sceneMode);
  }, [sceneMode, sectioningMode, selectedNode]);

  const handleSectioningToggle = useCallback(() => {
    sceneStore.set.configureNode(
      !sectioningMode && selectedNode?.type === SceneNodeType.GROUP
        ? selectedNode
        : null
    );
    sceneStore.set.selectedNode(null);

    sceneStore.set.sectioningMode(!sectioningMode);
  }, [selectedNode, sectioningMode]);

  const handleWallsWidthChange = useCallback<(value: number | null) => void>(
    (value) => {
      const { plan } = sceneStore.get.roomSettings();

      each(plan, (point) => {
        point.setX(((point.x / Math.abs(point.x)) * (value || 1)) / 2);
      });

      sceneStore.set.roomSettings({
        ...sceneStore.get.roomSettings(),
        plan: [...plan]
      });
    },
    []
  );

  const handleWallsDepthChange = useCallback<(value: number | null) => void>(
    (value) => {
      const { plan } = sceneStore.get.roomSettings();

      each(plan, (point) => {
        point.setY(((point.y / Math.abs(point.y)) * (value || 1)) / 2);
      });

      sceneStore.set.roomSettings({
        ...sceneStore.get.roomSettings(),
        plan: [...plan]
      });
    },
    []
  );

  const roomSettings = sceneStore.use.roomSettings();

  return (
    <div className="flex flex-1 justify-center space-x-2">
      <div className="flex -space-x-px rounded-md shadow-sm">
        <PureIconButtonHelper
          className="border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:bg-gray-50 hover:shadow inline-flex items-center px-4 py-2 relative rounded-l-md sm:px-2 text-gray-500"
          icon={IconsEnum.ZOOM_IN_OUTLINE}
          iconClassName="h-6 w-6 stroke-2"
          onClick={handleZoomIn}
        />
        <PureIconButtonHelper
          className="border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:bg-gray-50 hover:shadow inline-flex items-center px-4 py-2 relative rounded-r-md sm:px-2 text-gray-500"
          icon={IconsEnum.ZOOM_OUT_OUTLINE}
          iconClassName="h-6 w-6 stroke-2"
          onClick={handleZoomOut}
        />
      </div>

      <PureIconButtonHelper
        className={cl(
          'border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm',
          {
            'hover:bg-gray-50': !enableMeasurements,
            'bg-gray-200 hover:bg-gray-300': enableMeasurements
          }
        )}
        icon={IconsEnum.RULER_OUTLINE}
        iconClassName="h-6 w-6"
        onClick={handleToggleMeasurements}
      />

      <PureIconButtonHelper
        className={cl(
          'border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm',
          {
            'hover:bg-gray-50': !enableRoomMeasurements,
            'bg-gray-200 hover:bg-gray-300': enableRoomMeasurements
          }
        )}
        icon={IconsEnum.CALCULATOR}
        iconClassName="h-6 w-6"
        onClick={handleToggleRoomMeasurements}
      />

      <div className="flex -space-x-px rounded-md shadow-sm">
        <PureButtonHelper
          className={cl(
            'border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:bg-gray-50 hover:shadow inline-flex items-center px-4 py-2 relative rounded-l-md sm:px-2 text-gray-500',
            {
              'bg-gray-200 hover:bg-gray-300':
                measurementsSystem === MeasurementsSystem.METRICAL
            }
          )}
          onClick={handleSetMetricalMeasurementsSystem}
          text="cm"
        />
        <PureButtonHelper
          className={cl(
            'border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:bg-gray-50 hover:shadow inline-flex items-center px-4 py-2 relative rounded-r-md sm:px-2 text-gray-500',
            {
              'bg-gray-200 hover:bg-gray-300':
                measurementsSystem === MeasurementsSystem.AMERICAN
            }
          )}
          onClick={handleSetAmericanMeasurementsSystem}
          text="inch"
        />
      </div>

      <PureIconButtonHelper
        className="border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow hover:bg-gray-50 inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm"
        icon={IconsEnum.DOCUMENT_ARROW_DOWN_OUTLINE}
        iconClassName="h-6 w-6 stroke-2"
        onClick={handleSave}
      />

      <PureIconButtonHelper
        className="border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow hover:bg-gray-50 inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm"
        icon={IconsEnum.DOCUMENT_ARROW_UP_OUTLINE}
        iconClassName="h-6 w-6 stroke-2"
        onClick={handleLoad}
      />
      <PureIconButtonHelper
        className="border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow hover:bg-gray-50 inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm"
        icon={IconsEnum.ARROW_UP_ON_SQUARE}
        iconClassName="h-6 w-6 stroke-2"
        onClick={handleLoadGLB}
      />

      {sceneMode && (
        <PureIconButtonHelper
          className={cl(
            'border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm',
            {
              'hover:bg-gray-50': !collisionEnabled,
              'bg-gray-200 hover:bg-gray-300': collisionEnabled
            }
          )}
          icon={IconsEnum.CPU_CHIP}
          iconClassName="h-6 w-6"
          onClick={handleToggleCollision}
        />
      )}

      {sceneMode && (
        <PureIconButtonHelper
          className={cl(
            'border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm',
            {
              'hover:bg-gray-50': !enableDoor,
              'bg-gray-200 hover:bg-gray-300': enableDoor
            }
          )}
          icon={IconsEnum.DOOR_OUTLINE}
          iconClassName="h-6 w-6"
          onClick={handleToggleDoor}
        />
      )}

      {sceneMode && (
        <PureIconButtonHelper
          className={cl(
            'border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm',
            {
              'hover:bg-gray-50': !enableWindow,
              'bg-gray-200 hover:bg-gray-300': enableWindow
            }
          )}
          icon={IconsEnum.WINDOW_OUTLINE}
          iconClassName="h-6 w-6"
          onClick={handleToggleWindow}
        />
      )}

      {((sceneMode &&
        (selectedNode?.type === SceneNodeType.GROUP || configureNode)) ||
        !sceneMode) &&
        selectedNode?.type != SceneNodeType.SIMPLE && (
          <PureIconButtonHelper
            className={cl(
              'border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow hover:bg-gray-50 inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm',
              {
                'bg-gray-200 hover:bg-gray-300': sceneMode
              }
            )}
            icon={IconsEnum.HOME}
            iconClassName="h-6 w-6 stroke-2"
            onClick={handleSceneModeToggle}
          />
        )}

      {sceneMode &&
        (selectedNode?.type === SceneNodeType.GROUP || configureNode) &&
        selectedNode?.type != SceneNodeType.SIMPLE && (
          <PureIconButtonHelper
            className={cl(
              'border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow hover:bg-gray-50 inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm',
              {
                'hover:bg-gray-50': !sectioningMode,
                'bg-gray-200 hover:bg-gray-300': sectioningMode
              }
            )}
            icon={IconsEnum.PENCIL_SQUARE}
            iconClassName="h-6 w-6 stroke-2"
            onClick={handleSectioningToggle}
          />
        )}

      {selectedNode && (
        <PureIconButtonHelper
          className="border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:shadow hover:bg-gray-50 inline-flex items-center px-4 py-2 relative rounded-md sm:px-2 text-gray-500 shadow-sm"
          icon={IconsEnum.ARROW_PATH}
          iconClassName="h-6 w-6 stroke-2"
          onClick={handleRotate}
        />
      )}

      {sceneMode && (
        <InputNumber
          inputClassName="basic-input px-2 w-16"
          min={0.5}
          step={0.5}
          value={Math.abs(roomSettings.plan[0].x * 2)}
          name="roomWidth"
          onChange={handleWallsWidthChange}
        />
      )}

      {sceneMode && (
        <InputNumber
          inputClassName="basic-input px-2 w-16"
          min={0.5}
          step={0.5}
          value={Math.abs(roomSettings.plan[0].y * 2)}
          name="roomDepth"
          onChange={handleWallsDepthChange}
        />
      )}
    </div>
  );
}

export default ConfigureProductPageHeader;
