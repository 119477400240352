import { Suspense, useEffect, useMemo } from 'react';
import each from 'lodash/each';

import { MaterialType } from '../../../types/scene.types';

import { sceneStore } from '../../../store/sceneStore';
import { globalStore } from '../../../store/globalStore';

import { RealWorldMaterials } from './components/RealWorldMaterials';
import { AtalsMaterials } from './components/AtalsMaterials';

export function Materials() {
  const settings = globalStore.use.settings();
  const materials = sceneStore.use.materials();
  const roomSettings = sceneStore.use.roomSettings();

  if (!settings) return null;

  const { realWorldMats, atlasMats } = useMemo(() => {
    const realWorldMats = {} as Record<string, string>;
    const atlasMats = {} as Record<string, Record<string, string>>;

    const mergedMaterials = {
      ...roomSettings.materials,
      ...materials
    };

    each(mergedMaterials, (materialItem, group) => {
      const materialAsset = settings.materials.assets[materialItem.name];

      if (!materialAsset) return;

      const atlasMaterials = materialAsset.filesUrl[MaterialType.ATLAS];
      const realWorldMaterial = materialAsset.filesUrl[MaterialType.REAL_WORLD];

      realWorldMaterial && (realWorldMats[group] = realWorldMaterial);
      atlasMaterials && (atlasMats[group] = atlasMaterials);
    });

    return {
      realWorldMats,
      atlasMats
    };
  }, [materials]);

  useEffect(() => {
    each(settings.materials.bind, (availables, group) => {
      const materials = sceneStore.get.materials();
      !materials[group] &&
        availables[0] &&
        sceneStore.set.setMaterial(group, availables[0]);
    });
  }, [settings.materials.bind]);

  return (
    <>
      <Suspense>
        <RealWorldMaterials mats={realWorldMats} />
      </Suspense>
      <Suspense>
        <AtalsMaterials mats={atlasMats} />
      </Suspense>
    </>
  );
}
