import {
  SceneNodeAttach,
  SceneNodeStick,
  SceneNodeType
} from '../../types/scene.types';

export const mockRoomLibrary = {
  door: {
    id: 'door',
    previewImage: '/assets/models/room/preview/door.jpg',
    name: 'door',
    fileUrl: '/assets/models/room/door.glb',
    attach: SceneNodeAttach.WALL,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  window: {
    id: 'window',
    previewImage: '/assets/models/room/preview/window.jpg',
    name: 'window',
    fileUrl: '/assets/models/room/window.glb',
    attach: SceneNodeAttach.WALL,
    stick: SceneNodeStick.DEFAULT,
    type: SceneNodeType.SIMPLE
  }
};
