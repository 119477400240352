import { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { globalStore } from './store/globalStore';
import { sceneStore } from './store/sceneStore';

import { AppLayout } from './app/AppLayouts/AppLayout';
import { ConfiguratorPage } from './main/configurator/pages/ConfiguratorPage';
import { ConfiguratorTabs } from './main/configurator/roomsConstants';
import { ConfigureProductPage } from './main/products/pages/ConfigureProductPage';
import { ProductsIndexPage } from './main/products/pages/ProductsIndexPage';

import { i18n } from './utils/i18n';

import { sku446Settings } from './mocks/mockSettingSku446';

const settings = sku446Settings;

export function App() {
  console.log(i18n.language);

  useMemo(() => {
    if (!settings) return;

    sceneStore.set.clear();
    globalStore.set.clear();
    globalStore.set.settings(settings);
  }, [settings]);

  return (
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route
            path="/product-editor"
            element={
              <ConfiguratorPage activeTab={ConfiguratorTabs.PRODUCT_EDITOR} />
            }
          />
          <Route
            path="/room-planner"
            element={
              <ConfiguratorPage activeTab={ConfiguratorTabs.ROOM_PLANNER} />
            }
          />
          <Route
            path="/room-designer"
            element={
              <ConfiguratorPage activeTab={ConfiguratorTabs.ROOM_DESIGNER} />
            }
          />
          <Route
            path="/shopping-list"
            element={
              <ConfiguratorPage activeTab={ConfiguratorTabs.SHOPPING_LIST} />
            }
          />

          <Route path="/:brand" element={<ProductsIndexPage />} />
          <Route path="/:brand/products" element={<ProductsIndexPage />} />
          <Route
            path="/:brand/products/:productId"
            element={<ConfigureProductPage />}
          />
        </Routes>
      </AppLayout>
    </BrowserRouter>
  );
}
