import { useMemo } from 'react';
import { useGLTF } from '@react-three/drei';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import each from 'lodash/each';
import keys from 'lodash/keys';
import values from 'lodash/values';

import {
  ModelBind,
  SettingsAssetModelItem,
  SettingsAssetMaterialItem
} from '../../../../types/settings.types';
import { MaterialItem, SceneNodeType } from '../../../../types/scene.types';

import { globalStore } from '../../../../store/globalStore';
import { sceneStore } from '../../../../store/sceneStore';

import { ContentBrowserModel } from '../ContentBrowserModel';
import { ContentBrowserMaterial } from '../ContentBrowserMaterial';

import { parseGLTFNodes } from '../../../../utils/parseGLTFNodes';
import { parseMeshName } from '../../../../utils/parseMeshName';

import { ConfiguratorTabs } from '../../roomsConstants';

enum ConfiguratorPageContentBrowserItemType {
  MODEL,
  MATERIAL
}

type ModelAssetItem = SettingsAssetModelItem & {
  assetType: ConfiguratorPageContentBrowserItemType.MODEL;
};

type MaterialAssetItem = SettingsAssetMaterialItem & {
  assetType: ConfiguratorPageContentBrowserItemType.MATERIAL;
  group: string;
  materialItem: MaterialItem;
};

type AssetItem = ModelAssetItem | MaterialAssetItem;

type AssetItems = Record<string, AssetItem>;

interface ConfiguratorPageContentBrowserProps {
  activeTab: ConfiguratorTabs;
}

function ConfiguratorPageContentBrowser({
  activeTab
}: ConfiguratorPageContentBrowserProps) {
  const settings = globalStore.use.settings();

  const materials = sceneStore.useTracked.materials();

  const configureNode = sceneStore.use.configureNode();

  const modelAssets = useMemo(
    () =>
      reduce(
        settings?.models.assets,
        (acc, asset, assetName) =>
          (configureNode?.type !== SceneNodeType.SIMPLE &&
          includes(settings?.models.bind[ModelBind.MODEL], assetName)
            ? (acc[assetName] = {
                ...asset,
                assetType: ConfiguratorPageContentBrowserItemType.MODEL
              } as ModelAssetItem)
            : true) && acc,
        {} as AssetItems
      ),
    [settings?.models.assets, settings?.models.bind, configureNode]
  );

  const decorationAssets = useMemo(
    () =>
      reduce(
        settings?.models.assets,
        (acc, asset, assetName) =>
          (includes(settings?.models.bind[ModelBind.DECORATIONS], assetName)
            ? (acc[assetName] = {
                ...asset,
                assetType: ConfiguratorPageContentBrowserItemType.MODEL
              } as ModelAssetItem)
            : true) && acc,
        {} as AssetItems
      ),
    [settings?.models.assets, settings?.models.bind]
  );

  const configureNodeMaterialGroupd = useMemo(() => {
    if (!settings || !configureNode) return [];

    const groups: Record<string, boolean> = {};

    const memory =
      configureNode.type === SceneNodeType.GROUP
        ? values(configureNode.model)
        : [configureNode];

    let node: typeof memory[0] | undefined;

    while ((node = memory.pop())) {
      if (node.type === SceneNodeType.GROUP) {
        memory.push(...values(node.model));
        continue;
      }

      if (
        node.type === SceneNodeType.SIMPLE ||
        node.type === SceneNodeType.SLOT
      ) {
        const { scene } = useGLTF(node.model);

        const { model: gltfModel } = parseGLTFNodes(settings, scene);

        gltfModel?.traverse((object) => {
          const { materialGroup } = parseMeshName(settings, object.name);

          groups[materialGroup] = true;
        });
      }
    }

    return keys(groups);
  }, [settings, configureNode]);

  const materialAssets = useMemo(
    () =>
      reduce(
        materials,
        (acc, materialItem, group) => {
          each(settings?.materials.bind[group], (item) => {
            includes(configureNodeMaterialGroupd, group) &&
              (acc[`${group}_${item.name}`] = {
                ...settings?.materials.assets[item.name],
                group,
                materialItem: item,
                assetType: ConfiguratorPageContentBrowserItemType.MATERIAL
              } as MaterialAssetItem);
          });

          return acc;
        },
        {} as AssetItems
      ),
    [materials, settings, configureNodeMaterialGroupd]
  );

  const assets = useMemo<AssetItems>(() => {
    switch (activeTab) {
      case ConfiguratorTabs.PRODUCT_EDITOR:
        return { ...modelAssets, ...materialAssets };
      case ConfiguratorTabs.ROOM_DESIGNER:
        return decorationAssets;
    }

    return {};
  }, [activeTab, modelAssets, decorationAssets, materialAssets]);

  if (!settings) return null;

  return (
    <>
      <div className="w-60 relative">
        <div className="absolute inset-0 overflow-y-auto">
          <div className="flex flex-col py-3">
            <div className="px-2 mb-3">
              <div className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 sm:max-w-lg relative rounded text-gray-400 border border-transparent w-full">
                <div className="absolute flex inset-y-0 items-center left-2 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-3 w-3"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <label
                  htmlFor="single_project_search_field"
                  className="sr-only"
                >
                  Search sections
                </label>
                <input
                  id="single_project_search_field"
                  type="search"
                  className="text-xs bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded h-full pl-6 placeholder-gray-400 pr-2 py-0.5 text-gray-900 w-full"
                  placeholder="Search sections"
                  autoComplete="off"
                />
              </div>
            </div>
            <div>
              <div>
                <button className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none text-blue-600 bg-blue-100 dark:text-blue-300 dark:bg-blue-700/20 font-normal cursor-default">
                  <span className="truncate" title="Sofa sections">
                    Sofa sections
                  </span>
                </button>
              </div>
              <div>
                <button
                  className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none hover:bg-gray-200 dark:hover:bg-gray-800"
                  aria-controls="sub-menu-materials"
                  aria-expanded="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-4 w-4 absolute left-0 rotate-90"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="truncate" title="Materials">
                    Materials
                  </span>
                </button>
                <div className="pl-4">
                  <div
                    className="border-l border-gray-700"
                    data-id="sub-menu-materials"
                  >
                    <div className="">
                      <button className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none hover:bg-gray-200 dark:hover:bg-gray-800">
                        <span className="truncate" title="Cloth">
                          Cloth
                        </span>
                      </button>
                    </div>
                    <div className="">
                      <button className="relative font-light flex py-0.5 pl-4 pr-2 w-full text-xs focus:ring-none hover:bg-gray-200 dark:hover:bg-gray-800">
                        <span className="truncate" title="Legs">
                          Legs
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 relative">
        <div className="absolute inset-0 overflow-y-auto">
          <div className="grid grid-cols-auto-fill grid-cell-min-28 2xl:grid-cell-min-32 gap-2 p-3">
            {map(assets, (item: AssetItem) =>
              item.assetType ===
              ConfiguratorPageContentBrowserItemType.MODEL ? (
                <ContentBrowserModel
                  key={item.id}
                  id={item.id}
                  img={item.previewImage}
                  name={item.name}
                  // category={model.type}
                />
              ) : (
                <ContentBrowserMaterial
                  key={item.group + item.id}
                  materialItem={item.materialItem}
                  group={item.group}
                  materialName={settings.materials.assets[item.id]?.name}
                  currentMaterialItem={materials[item.group]}
                  materialImage={item.previewImage}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfiguratorPageContentBrowser;
