import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConfigureProductPageMenuModes } from './useConfigureProductPageMenu.types';
import { I18nText } from '../../../../../../types/general.types';

import { words } from '../../../../../../locales/keys';

function useConfigureProductPageMenu() {
  const navigate = useNavigate();

  const [menuMode, setMenuMode] = useState<ConfigureProductPageMenuModes>(
    ConfigureProductPageMenuModes.HOME
  );

  const [menuI18nTitle, setMenuI18nTitle] = useState<I18nText>(
    words.productDetails
  );

  return {
    menuMode,
    menuI18nTitle,
    setMenuMode,
    handleGoBack: () => {
      if (menuMode === ConfigureProductPageMenuModes.HOME) {
        navigate(-1);
      } else {
        setMenuMode(ConfigureProductPageMenuModes.HOME);
        setMenuI18nTitle(words.productDetails);
      }
    },
    handleChangeMaterials: () => {
      setMenuMode(ConfigureProductPageMenuModes.CHANGE_MATERIALS);
      setMenuI18nTitle(words.changeMaterials);
    },
    handleAddDecoration: () => {
      setMenuMode(ConfigureProductPageMenuModes.ADD_DECORATION);
      setMenuI18nTitle(words.addDecoration);
    },
    handleAddSection: () => {
      setMenuMode(ConfigureProductPageMenuModes.ADD_SECTION);
      setMenuI18nTitle(words.addSection);
    },
    handleSetWalls: () => {
      setMenuMode(ConfigureProductPageMenuModes.WALLS);
      setMenuI18nTitle(words.walls);
    }
  };
}

export default useConfigureProductPageMenu;
