import React, { Fragment, memo } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import { TooltipSingletonSourceWrapper } from '../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';

import { Icon } from '../../../../helpers/Icon';

function PrimaryNav() {
  return (
    <Fragment>
      <TooltipSingletonSourceWrapper
        placement={TooltipPlacement.RIGHT}
        withArrow
      >
        <a className="inline-flex flex-shrink-0 items-center justify-center h-full sm:h-14 flex-grow sm:w-14 sm:rounded-lg sm:flex-grow-0 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 stroke-1.75 text-blue-500 bg-blue-500 bg-opacity-20 dark:text-blue-400">
          <Icon
            icon={IconsEnum.CUBE_OUTLINE}
            className="h-6 w-6 sm:h-7 sm:w-7"
          />
        </a>
      </TooltipSingletonSourceWrapper>
    </Fragment>
  );
}

export default memo(PrimaryNav);
