import {
  SceneNodeAttach,
  SceneNodeStick,
  SceneNodeType
} from '../../types/scene.types';
import { SettingsModelAssetItems } from '../../types/settings.types';

export const mockDecorations: SettingsModelAssetItems = {
  button_tufted_chair: {
    id: 'button_tufted_chair',
    previewImage: '/assets/models/lib/preview/button_tufted_chair.jpg',
    name: 'Button Tufted Chair',
    fileUrl: '/assets/models/lib/button_tufted_chair.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  chair: {
    id: 'chair',
    previewImage: '/assets/models/lib/preview/chair.jpg',
    name: 'Model 849',
    fileUrl: '/assets/models/lib/chair.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  cocktail_table: {
    id: 'cocktail_table',
    previewImage: '/assets/models/lib/preview/cocktail_table.jpg',
    name: 'Nolan Bunching Cocktail',
    fileUrl: '/assets/models/lib/cocktail_table.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  coffee_table: {
    id: 'coffee_table',
    previewImage: '/assets/models/lib/preview/coffee_table.jpg',
    name: 'YYY Coffee 421',
    fileUrl: '/assets/models/lib/coffee_table.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  floor_lamp: {
    id: 'floor_lamp',
    previewImage: '/assets/models/lib/preview/floor_lamp.jpg',
    name: 'Roomor 1',
    fileUrl: '/assets/models/lib/floor_lamp.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  grande_long: {
    id: 'grande_long',
    previewImage: '/assets/models/lib/preview/grande_long.jpg',
    name: 'Grande Long',
    fileUrl: '/assets/models/lib/grande_long.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  grande_short: {
    id: 'grande_short',
    previewImage: '/assets/models/lib/preview/grande_short.jpg',
    name: 'Grande Short',
    fileUrl: '/assets/models/lib/grande_short.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  coffee_table_2: {
    id: 'coffee_table_2',
    previewImage: '/assets/models/lib/preview/coffee_table_2.jpg',
    name: 'Wisconsin',
    fileUrl: '/assets/models/lib/coffee_table_2.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  divan: {
    id: 'divan',
    previewImage: '/assets/models/lib/preview/divan.jpg',
    name: 'Mr. Floyd 3 Seater',
    fileUrl: '/assets/models/lib/divan.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  kamin: {
    id: 'kamin',
    previewImage: '/assets/models/lib/preview/kamin.jpg',
    name: 'Fireplace 129',
    fileUrl: '/assets/models/lib/kamin.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  kover: {
    id: 'kover',
    previewImage: '/assets/models/lib/preview/kover.jpg',
    name: 'Rolf Off White',
    fileUrl: '/assets/models/lib/kover.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  kreslo: {
    id: 'kreslo',
    previewImage: '/assets/models/lib/preview/kreslo.jpg',
    name: 'Mr. Floyd Chair',
    fileUrl: '/assets/models/lib/kreslo.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  stol: {
    id: 'stol',
    previewImage: '/assets/models/lib/preview/stol.jpg',
    name: 'Skogsta',
    fileUrl: '/assets/models/lib/stol.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  stol_stylya: {
    id: 'stol_stylya',
    previewImage: '/assets/models/lib/preview/stol_stylya.jpg',
    name: 'Skogsta, Bergmund Set',
    fileUrl: '/assets/models/lib/stol_stylya.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  stul: {
    id: 'stul',
    previewImage: '/assets/models/lib/preview/stul.jpg',
    name: 'Bergmund',
    fileUrl: '/assets/models/lib/stul.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  tumba_decor_2: {
    id: 'tumba_decor_2',
    previewImage: '/assets/models/lib/preview/tumba_decor_2.jpg',
    name: 'Hadley Herringbone',
    fileUrl: '/assets/models/lib/tumba_decor_2.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  stol_2: {
    id: 'stol_2',
    previewImage: '/assets/models/lib/preview/stol_2.jpg',
    name: 'Particulier',
    fileUrl: '/assets/models/lib/stol_2.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  plant: {
    id: 'plant',
    previewImage: '/assets/models/lib/preview/plant.jpg',
    name: 'Monsterra Plant 2325',
    fileUrl: '/assets/models/lib/plant.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  plant_2: {
    id: 'plant_2',
    previewImage: '/assets/models/lib/preview/plant_2.jpg',
    name: 'Queen Anthurium Plant',
    fileUrl: '/assets/models/lib/plant_2.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  lamp_2: {
    id: 'lamp_2',
    previewImage: '/assets/models/lib/preview/lamp_2.jpg',
    name: 'No. 47',
    fileUrl: '/assets/models/lib/lamp_2.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  'aria-ultra-ww-armchair': {
    id: 'aria-ultra-ww-armchair',
    previewImage: '/assets/models/lib/preview/aria-ultra-ww-armchair.jpg',
    name: 'Aria ultra ww armchair',
    fileUrl: '/assets/models/lib/aria-ultra-ww-armchair.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  },
  payton_sofa: {
    id: 'payton_sofa',
    previewImage: '/assets/models/lib/preview/python_sofa.jpg',
    name: 'Payton Sofa',
    fileUrl: '/assets/models/lib/python_sofa.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SIMPLE
  }
};
