import React, { Fragment, useCallback, ChangeEventHandler } from 'react';
import isFinite from 'lodash/isFinite';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';

import { InputNumberProps } from './InputNumber.types';
import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../Icon';
import { Translate } from '../../Translate';

function InputNumber({
  disabled,
  error,
  inputClassName,
  leftIcon,
  max,
  min,
  name,
  placeholder,
  step,
  value,
  onChange
}: InputNumberProps) {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const parsedValue = toNumber(event.target.value);
      onChange(isFinite(parsedValue) ? parsedValue : null);
    },
    [onChange]
  );

  return (
    <Fragment>
      <div className="relative">
        {leftIcon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{leftIcon}</span>
          </div>
        )}
        <input
          className={inputClassName || 'basic-input pl-7 pr-4'}
          disabled={disabled}
          max={max}
          min={min}
          step={step}
          placeholder={placeholder}
          name={name}
          type="number"
          value={isNumber(value) ? value : ''}
          onChange={handleChange}
        />
      </div>
      {error ? (
        <p className="mt-1 text-red-500 text-2xs align-center">
          <Icon
            className="h-5 w-5 text-red-500"
            icon={IconsEnum.EXCLAMATION_CIRCLE}
          />
          <span>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </span>
        </p>
      ) : null}
    </Fragment>
  );
}

export default InputNumber;
