import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import en from '../../locales/en';
import uk from '../../locales/uk';

const EN_LANG = 'en';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UK_LANG = 'uk';

const DEFAULT_LANG = EN_LANG;

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      uk: {
        translation: uk
      }
    },
    fallbackLng: DEFAULT_LANG,
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['htmlTag'],
      caches: []
    }
  });

export default i18n;
