const basePath = '';

export class ConfiguratorPath {
  static productEditor() {
    return `${basePath}/product-editor`;
  }

  static roomPlanner() {
    return `${basePath}/room-planner`;
  }

  static roomDesigner() {
    return `${basePath}/room-designer`;
  }

  static shoppingList() {
    return `${basePath}/shopping-list`;
  }
}
