import { forwardRef, useEffect, useMemo } from 'react';
import { Group, Mesh } from 'three';
import { useGLTF } from '@react-three/drei';

import { SimpleNode, SlotNode } from '../types/scene.types';

import { SceneStore, sceneStore } from '../store/sceneStore';
import { globalStore } from '../store/globalStore';

import { Selection } from './helpers/Selection';

import { parseMeshName } from '../utils/parseMeshName';
import { parseGLTFNodes } from '../utils/parseGLTFNodes';
import { parseModel } from '../utils/parseModel';

export interface ModelProps {
  node: SimpleNode | SlotNode;
}

const sceneStoreSelector = ({ materials, materialsLib }: SceneStore) => ({
  materials,
  materialsLib
});

export const Model = forwardRef(({ node }: ModelProps, ref) => {
  if (!node || !node.model) return null;

  const settings = globalStore.useTracked.settings();

  if (!settings) return null;

  const { scene } = useGLTF(node.model);

  const { materials, materialsLib } = sceneStore.useStore(sceneStoreSelector);

  const { model: gltfModel } = parseGLTFNodes(settings, scene);

  const gltfModelClone = useMemo(() => gltfModel?.clone(), [gltfModel]);

  useEffect(() => {
    gltfModelClone?.traverse((object) => {
      object.castShadow = true;
      object.receiveShadow = true;

      const { materialGroup, meshName } = parseMeshName(settings, object.name);

      const materialItem = materials[materialGroup];

      if (!materialItem) return null;

      const atlasMaterialName = `${materialItem.name}${settings.naming.separator.group}${meshName}`;
      const realWorldMaterialName = materialItem.name;

      const { ATLAS, REAL_WORLD } = parseModel(settings, object);

      if (
        !ATLAS &&
        !REAL_WORLD &&
        object instanceof Mesh &&
        materialsLib[realWorldMaterialName]
      ) {
        object.material = materialsLib[realWorldMaterialName];
      }

      if (REAL_WORLD && materialsLib[realWorldMaterialName]) {
        REAL_WORLD.material = materialsLib[realWorldMaterialName];

        ATLAS && (ATLAS.visible = false);
        REAL_WORLD.visible = true;
      }

      if (ATLAS && materialsLib[atlasMaterialName]) {
        ATLAS.material = materialsLib[atlasMaterialName];

        REAL_WORLD && (REAL_WORLD.visible = false);
        ATLAS.visible = true;
      }

      return null;
    });
  }, [gltfModelClone, materials, materialsLib, settings]);

  if (!gltfModel) return null;

  return (
    <Selection node={node}>
      <primitive
        ref={ref as React.RefObject<Group>}
        object={gltfModelClone}
        position={node.position}
        rotation={node.rotation}
        castShadow
        receiveShadow
      />
    </Selection>
  );
});
