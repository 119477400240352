import isEmpty from 'lodash/isEmpty';

import { globalStore } from '../../store/globalStore';
import { sceneStore } from '../../store/sceneStore';
import { sceneHelpersStore } from '../../store/sceneHelpersStore';

import { BoxMeasurements } from './BoxMeasurements';
import { RoomMeasurements } from './RoomMeasurements';

export function SceneMeasurements() {
  const interactiveItemGroup = sceneHelpersStore.use.interactiveItemGroup();
  const sceneMode = sceneStore.use.sceneMode();
  const configureNode = sceneStore.use.configureNode();
  const enableRoomMeasurements = globalStore.use.enableRoomMeasurements();

  if (
    isEmpty(interactiveItemGroup.children) ||
    (!sceneMode && configureNode === null)
  )
    return null;

  return (
    <>
      <BoxMeasurements group={interactiveItemGroup} />
      {enableRoomMeasurements && (
        <RoomMeasurements group={interactiveItemGroup} />
      )}
    </>
  );
}
