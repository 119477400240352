import React, { ReactNode } from 'react';
import cl from 'classnames';

import { ClassName } from '../../../types/general.types';

import { MainLayout } from '../MainLayout';

interface IndexLayoutProps {
  children: ReactNode;
  customSecondaryMenu?: ReactNode;
  header?: ReactNode;
  mainAddClassName?: ClassName;
  name?: string;
  sidebar?: ReactNode;
  subHeader?: ReactNode;
}

function IndexLayout({
  children,
  customSecondaryMenu,
  header,
  mainAddClassName,
  sidebar,
  subHeader
}: IndexLayoutProps) {
  return (
    <MainLayout>
      <div className="flex flex-1">
        {customSecondaryMenu}
        <div className="flex flex-col w-full z-0">
          <div
            id="page-header-wrap"
            className="relative z-5 shrink-0 flex gap-4 lg:gap-6 px-4 items-center h-16 border-b border-gray-200 dark:border-gray-700"
          >
            {header}
          </div>

          <div className="relative flex-1 xl:z-0">
            <div className="absolute inset-0 flex overflow-hidden">
              <main
                className={cl(
                  'flex-1 relative overflow-y-auto focus:outline-none z-0',
                  mainAddClassName
                )}
                tabIndex={-1}
              >
                <div className="pt-6 pb-8">
                  <div className="flex flex-col space-y-8">
                    {subHeader}
                    {children}
                  </div>
                </div>
              </main>
              {sidebar}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default IndexLayout;
