import { useCallback, useState } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';
import { MaterialItem } from '../../../../types/scene.types';

import { sceneStore } from '../../../../store/sceneStore';

import { Icon } from '../../../../helpers/Icon';
import { TooltipSingletonTarget } from '../../../../helpers/tooltips/TooltipSingletonTarget';

export interface ProductSidebarTextureProps {
  materialItem: MaterialItem;
  group: string;
  materialName: string;
  materialImage: string;
  currentMaterialItem: MaterialItem;
}

function ProductSidebarTexture({
  materialItem,
  group,
  materialName,
  materialImage,
  currentMaterialItem
}: ProductSidebarTextureProps) {
  const handleClick = useCallback(() => {
    sceneStore.set.setMaterial(group, materialItem);
  }, [group, materialItem]);

  const [wrapperEl, setWrapperEl] = useState<HTMLDivElement | null>(null);

  return (
    <div
      className="aspect-w-1 aspect-h-1 overflow-hidden rounded-md focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100"
      onClick={handleClick}
      ref={setWrapperEl}
    >
      <TooltipSingletonTarget
        referenceElement={wrapperEl}
        tooltipText={materialName}
      />
      <img
        src={materialImage}
        alt={materialName}
        className="object-cover w-full h-full"
      />

      {currentMaterialItem.name === materialItem.name && (
        <div className="p-1 flex justify-end items-start pointer-events-none">
          <div className="text-white p-0.5 rounded-full bg-blue-500">
            <Icon icon={IconsEnum.CHECK} className="h-4 w-4" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductSidebarTexture;
