import { Settings } from '../types/settings.types';

import { parseClearName } from './parseClearName';

export function parseMeshName(settings: Settings, name: string) {
  const { separator } = settings.naming;

  const clearFullName = parseClearName(settings, name);
  const parsedName = clearFullName.split(separator.group);

  return {
    clearFullName,
    materialGroup: parsedName[0],
    meshName: parsedName[1] || ''
  };
}
