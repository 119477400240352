import { memo, RefCallback, useCallback } from 'react';
import * as THREE from 'three';
import { Canvas, RootState } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, Stats } from '@react-three/drei';
import { Leva, useControls } from 'leva';

import { globalStore } from '../store/globalStore';

import { Scene } from './Scene';
import { DNDPointerControl } from './helpers/DNDPointerControl';
import { ConfiguratorEventsHandler } from './helpers/ConfiguratorEventsHandler';
import { UILayout } from './UILayout';
import { SceneMeasurements } from './components/SceneMeasurements';
import { CameraControl } from './CameraControl';

// DEV ONLY
const axesHelper = new THREE.AxesHelper(5);

const enabledDevTools = process.env.REACT_APP_DEV_TOOLS === 'true';

const onCreatedCanvas: (state: RootState) => void = ({ gl }) => {
  gl.physicallyCorrectLights = true;
  gl.toneMappingExposure = 0.8;
};

export const ConfiguratorView = memo(function () {
  // DEV ONLY
  const { enableStats } = useControls('stats', {
    enableStats: enabledDevTools
  });

  const { enableAxesHelper } = useControls('axesHelper', {
    enableAxesHelper: enabledDevTools
  });

  const handleSetCanvasWrapper = useCallback<RefCallback<HTMLDivElement>>(
    (el) => globalStore.set.canvasWrapper(el),
    []
  );

  return (
    <div className="canvas" ref={handleSetCanvasWrapper} tabIndex={-1}>
      <Canvas onCreated={onCreatedCanvas} shadows frameloop="demand">
        <PerspectiveCamera makeDefault position={[-1, 3, 7]} />
        <OrbitControls makeDefault />
        <DNDPointerControl />
        <CameraControl />
        <Scene />
        <SceneMeasurements />

        <ConfiguratorEventsHandler />
        {/* DEV ONLY */}
        {enableAxesHelper && <primitive object={axesHelper} />}
        {enableStats && <Stats />}
      </Canvas>
      <UILayout />
      <Leva hidden={!enabledDevTools} collapsed />
    </div>
  );
});
