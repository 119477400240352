import React from 'react';
import { Html, Sphere } from '@react-three/drei';
import { useControls } from 'leva';
import map from 'lodash/map';

import { SlotNode } from '../../types/scene.types';

import { NewSlotNode } from '../../store/sceneStore';

import { CheckSlotAvailable } from './CheckSlotAvailable';
import { SlotBox } from './SlotBox';

interface AvailableSlotsProps {
  node: SlotNode;
  interactiveItem: NewSlotNode | SlotNode | null;
  scope: string;
}

const enabledDevTools = process.env.REACT_APP_DEV_TOOLS === 'true';

export function AvailableSlots({
  node,
  interactiveItem,
  scope
}: AvailableSlotsProps) {
  // DEV ONLY
  const [{ snappingHelpers }] = useControls('snappingHelpers', () => ({
    snappingHelpers: enabledDevTools
  }));

  if (!interactiveItem) return null;

  return (
    <React.Fragment>
      {map(node.slots, (slot) =>
        map(slot.points, (point) => (
          <React.Fragment key={node.id + point.name}>
            {interactiveItem &&
              node !== interactiveItem &&
              map(interactiveItem.slots, (itemSlot) =>
                map(itemSlot.points, (itemSlotPoint) => (
                  <CheckSlotAvailable
                    key={node.id + itemSlot.name + itemSlotPoint.name}
                    parentSlot={slot}
                    parentSlotPoint={point}
                    itemSlotName={itemSlot.name}
                    itemSlotPointName={itemSlotPoint.name}
                  >
                    <SlotBox
                      parent={node}
                      parentSlot={slot}
                      parentSlotPoint={point}
                      item={interactiveItem}
                      itemSlot={itemSlot}
                      itemSlotPoint={itemSlotPoint}
                      scope={scope}
                    />
                  </CheckSlotAvailable>
                ))
              )}

            {/* DEV ONLY */}
            {snappingHelpers && (
              <group position={node.position} rotation={node.rotation}>
                <group position={point.position}>
                  {/* Slot point as box */}
                  <Sphere args={[0.05]} renderOrder={-1}>
                    <meshBasicMaterial
                      color={point.isChild ? [0, 1, 0] : [0, 0, 1]}
                      transparent
                      opacity={0.65}
                    />
                  </Sphere>

                  {/* Slot name & slot node id */}
                  <Html
                    className="scene-annotation"
                    distanceFactor={4}
                    center
                    position-y={0.1}
                  >
                    {`${slot.name}_${point.name}${
                      parseInt(point.node?.id || '0', 10) % 100 || ''
                    }`}
                  </Html>
                </group>
              </group>
            )}
          </React.Fragment>
        ))
      )}
    </React.Fragment>
  );
}
