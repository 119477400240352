import { useEffect, useRef, Suspense, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import { TransformControls } from 'three-stdlib';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import map from 'lodash/map';

import { sceneStore } from '../../../store/sceneStore';
import { globalStore } from '../../../store/globalStore';

import { SceneCamera } from './Components/SceneCamera';

export function SceneCameras() {
  const cameras = sceneStore.useStore(({ cameras }) => cameras);
  const camera = useThree(({ camera }) => camera);
  const cameraTransformMode = sceneStore.use.cameraTransformMode();
  const canvasWrapper = globalStore.use.canvasWrapper();
  const orbit = useThree(({ controls }) => controls) as OrbitControls;
  const gl = useThree(({ gl }) => gl);

  const control = useRef<TransformControls>(
    new TransformControls(camera, gl.domElement)
  );

  const setCameraTransformMode = useCallback((mode: string) => {
    control.current.setMode(mode);
  }, []);

  useEffect(() => {
    setCameraTransformMode(cameraTransformMode);
  }, [cameraTransformMode]);

  useEffect(() => {
    const switchMode = (e: KeyboardEvent) => {
      if (e.code === 'KeyR')
        setCameraTransformMode(
          control.current.getMode() === 'translate' ? 'rotate' : 'translate'
        );
    };
    control.current = new TransformControls(camera, gl.domElement);
    control.current.addEventListener('dragging-changed', function (event) {
      if (orbit !== null) orbit.enabled = !event.value;
    });
    canvasWrapper?.addEventListener('keydown', switchMode);
    return () => canvasWrapper?.removeEventListener('keydown', switchMode);
  }, [gl, orbit, canvasWrapper]);

  useEffect(() => {
    for (const key in cameras) {
      const camNode = cameras[key];
      if (camNode.position == undefined) {
        sceneStore.set.updateCameraNode(camNode, {
          ...camNode,
          position: camera.position.clone(),
          rotation: camera.rotation.clone()
        });
      }
    }
  }, [cameras]);

  return (
    <>
      {map(cameras, (node) => (
        <Suspense key={node.id}>
          {<SceneCamera node={node} transformControl={control.current} />}
        </Suspense>
      ))}
    </>
  );
}
