import each from 'lodash/each';
import { useCallback } from 'react';
import map from 'lodash/map';

import { sceneStore } from '../../../../../../store/sceneStore';

import { ProductSidebarSection } from '../../../ProductSidebarSection';

import { InputNumber } from '../../../../../../helpers/inputs/InputNumber';
import { Translate } from '../../../../../../helpers/Translate';

import { words } from '../../../../../../locales/keys';
import { mockRoomLibrary } from '../../../../../../mocks/lib/mockRoomLibrary';

function PageMenuWallsContent() {
  const handleWallsHeightChange = useCallback<(value: number | null) => void>(
    (value) => {
      sceneStore.set.roomSettings({
        ...sceneStore.get.roomSettings(),
        wallHeight: value || 3
      });
    },
    []
  );

  const handleWallsWidthChange = useCallback<(value: number | null) => void>(
    (value) => {
      const { plan } = sceneStore.get.roomSettings();

      each(plan, (point) => {
        point.setX(((point.x / Math.abs(point.x)) * (value || 1)) / 2);
      });

      sceneStore.set.roomSettings({
        ...sceneStore.get.roomSettings(),
        plan: [...plan]
      });
    },
    []
  );

  const handleWallsDepthChange = useCallback<(value: number | null) => void>(
    (value) => {
      const { plan } = sceneStore.get.roomSettings();

      each(plan, (point) => {
        point.setY(((point.y / Math.abs(point.y)) * (value || 1)) / 2);
      });

      sceneStore.set.roomSettings({
        ...sceneStore.get.roomSettings(),
        plan: [...plan]
      });
    },
    []
  );

  const roomSettings = sceneStore.use.roomSettings();

  return (
    <>
      <div className="p-4 space-y-4" data-toggle-item="add-section">
        <div className="uppercase text-gray-500">
          <Translate id={words.height} />
        </div>

        <InputNumber
          min={0.5}
          step={0.5}
          value={roomSettings.wallHeight}
          name="roomHeight"
          onChange={handleWallsHeightChange}
        />
      </div>

      <div className="p-4 space-y-4" data-toggle-item="add-section">
        <div className="uppercase text-gray-500">
          <Translate id={words.width} />
        </div>

        <InputNumber
          min={0.5}
          step={0.5}
          value={Math.abs(roomSettings.plan[0].x * 2)}
          name="roomWidth"
          onChange={handleWallsWidthChange}
        />
      </div>

      <div className="p-4 space-y-4" data-toggle-item="add-section">
        <div className="uppercase text-gray-500">
          <Translate id={words.depth} />
        </div>

        <InputNumber
          min={0.5}
          step={0.5}
          value={Math.abs(roomSettings.plan[0].y * 2)}
          name="roomDepth"
          onChange={handleWallsDepthChange}
        />
      </div>

      <div className="p-4 space-y-4" data-toggle-item="add-section">
        <div className="uppercase text-gray-500">
          <Translate id={words.components} />
        </div>

        <div className="grid grid-cols-2 gap-2 justify-center">
          {map(mockRoomLibrary, (item) => (
            <ProductSidebarSection
              key={item.id}
              id={item.id}
              name={item.name}
              img={item.previewImage}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default PageMenuWallsContent;
