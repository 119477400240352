import React from 'react';

import { IconProps } from '../types';

function RulerOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-6 w-6'}
      fill="currenColor"
      viewBox="0 0 32 32"
    >
      <path d="M9.5 2.2c.4 0 .8.3.8.8v2.4h-.8a.8.8 0 000 1.6h.8v3.3h-.8a.8.8 0 100 1.6h.8v3.3h-.8a.8.8 0 100 1.5h.8V20h-.8a.8.8 0 000 1.7h.8V25h-.8a.8.8 0 100 1.5h.8V29c0 .5-.4.8-.8.8H6.2c-.3 0-.8-.3-.8-.8V3c0-.4.5-.7 1-.7h3.2zm5.7 16.2v.9a.8.8 0 101.6 0v-.8H20v.8a.8.8 0 001.6 0v-.8H25v.8a.8.8 0 101.6 0v-.8H29c.4 0 .8.3.8.8v3.3c0 .4-.4.7-.8.7H12v-4.8h3.2zm-12 4.8c-.6 0-1-.3-1-.8v-3.3c0-.4.4-.7.8-.7h1v4.8H3zm.6-6.5H3a2.5 2.5 0 00-2.4 2.6v3.3c0 1 1 2.2 2.4 2.2h1v4.1c0 1.4 1 2.5 2.4 2.5h3.3c1.2 0 2.3-1.1 2.3-2.5v-4.1h17c1.4 0 2.5-1 2.5-2.2v-3.3c0-1.4-1-2.5-2.4-2.5H12V3.1C12 1.7 10.7.6 9.4.6H6.2C5 .6 3.9 1.6 3.9 3v13.7z" />
    </svg>
  );
}

export default RulerOutlineIcon;
