import { useMemo } from 'react';
import { Box3, Object3D, Vector3 } from 'three';

export const useBoxObject = (object: Object3D, angle?: number) =>
  useMemo(() => {
    angle && object.rotateY(angle);

    const box = new Box3().setFromObject(object);

    angle && object.rotateY(-angle);

    return {
      box,
      size: box.getSize(new Vector3()),
      center: box.getCenter(new Vector3())
    };
  }, [object]);
