import React, { ReactNode } from 'react';

import { MenuProvider } from '../../hooks/useMenu';
import { PrimaryMenu } from '../../components/menus/PrimaryMenu';

interface MainAppLayoutProps {
  children: ReactNode;
}

function MainAppLayout({ children }: MainAppLayoutProps) {
  return (
    <MenuProvider>
      <div className="relative flex flex-col-reverse sm:flex-row w-full h-full overflow-hidden">
        <PrimaryMenu />
        {children}
      </div>
    </MenuProvider>
  );
}

export default MainAppLayout;
