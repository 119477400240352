import { TreeViewer, TreeViewerProps } from '../../../../helpers/TreeViewer';

type ConfiguratorPageSidebarOutlinerProps = TreeViewerProps;

function ConfiguratorPageSidebarOutliner({
  nodes
}: ConfiguratorPageSidebarOutlinerProps) {
  return (
    <div className="flex-1 flex flex-col">
      <div className="px-2 py-1 items-center flex justify-between gap-2">
        <span className="text-sm font-thin text-gray-500">Outliner</span>
        <div>
          <div className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 sm:max-w-lg relative rounded text-gray-400 border border-transparent w-full">
            <div className="absolute flex inset-y-0 items-center left-2 pointer-events-none">
              {/* Icon: solid/search */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-3 w-3"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <label htmlFor="single_project_search_field" className="sr-only">
              Search items
            </label>
            <input
              id="single_project_search_field"
              type="search"
              className="text-xs bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded h-full pl-6 placeholder-gray-400 pr-2 py-0.5 text-gray-900 w-full"
              placeholder="Search items"
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <TreeViewer nodes={nodes} />
    </div>
  );
}

export default ConfiguratorPageSidebarOutliner;
