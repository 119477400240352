import { Line, Text } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useMemo, useRef, useState } from 'react';
import { Box3, Euler, Group, Object3D, Vector3, Quaternion } from 'three';
import map from 'lodash/map';
import { globalStore, MeasurementsSystem } from '../../store/globalStore';
import { sceneStore } from '../../store/sceneStore';

interface RoomMeasurementsProps {
  group: Group;
}

interface RoomMeasurementsPoint {
  position: Vector3;
  measurement: string;
  rotation: Euler;
  anchorX: 'center' | 'left' | 'right';
}
function isLeftSide(startLine: Vector3, endLine: Vector3, point: Vector3) {
  return (endLine.x - startLine.x) * (point.z - startLine.z) -
    (endLine.z - startLine.z) * (point.x - startLine.x) >
    0
    ? -1
    : 1;
}
const inchScalar = 0.3937;

function getMeasurements(size: number, measurementsSystem: MeasurementsSystem) {
  return `${parseFloat(
    (
      size *
      100 *
      (measurementsSystem === MeasurementsSystem.AMERICAN ? inchScalar : 1)
    ).toFixed(1)
  )} ${measurementsSystem === MeasurementsSystem.AMERICAN ? 'in' : 'cm'}`;
}

const getClosestSide = (sides: Vector3[], position: Vector3) => {
  let closest = null;
  for (const id in sides) {
    const side = sides[id];
    if (closest == null) {
      closest = side;
      continue;
    }
    if (side.distanceTo(position) < closest.distanceTo(position)) {
      closest = side;
    }
  }
  return closest;
};
export function RoomMeasurements({ group }: RoomMeasurementsProps) {
  const roomSettings = sceneStore.use.roomSettings();
  const boundingBox = useMemo<Box3>(
    () => new Box3().setFromObject(group),
    [group]
  );

  const boxSize = useMemo<Vector3>(
    () => boundingBox.getSize(new Vector3()),
    [boundingBox]
  );

  const boxCenter = useMemo<Vector3>(
    () => boundingBox.getCenter(new Vector3()),
    [boundingBox]
  );

  const camera = useThree(({ camera }) => camera);
  const [cameraPosition, setCameraPosition] = useState(camera.position.clone());

  const textRefs = useRef<Object3D[]>([]);

  useFrame(({ camera }) => {
    !camera.position.equals(cameraPosition) &&
      setCameraPosition(camera.position.clone());
  });

  const sides = useMemo<Vector3[]>(() => {
    const halfSize = boxSize.clone().divideScalar(2);
    const rightSide = new Vector3(boxCenter.x + halfSize.x, 0, boxCenter.z);
    const leftSide = new Vector3(boxCenter.x - halfSize.x, 0, boxCenter.z);
    const frontSide = new Vector3(boxCenter.x, 0, boxCenter.z - halfSize.z);
    const backSide = new Vector3(boxCenter.x, 0, boxCenter.z + halfSize.z);
    const results: Vector3[] = [rightSide, leftSide, frontSide, backSide];
    return results;
  }, [boxCenter, boxSize]);

  const lines = useMemo<Vector3[][] | undefined>(() => {
    if (roomSettings == undefined || roomSettings.boxes == null) return;
    const result: Vector3[][] = [];
    const worldDirection = group.getWorldDirection(new Vector3());
    const box = boxCenter.clone();
    box.y = 0;
    for (const id in roomSettings.boxes) {
      const wallPosition = roomSettings.boxes[id].getCenter(new Vector3());
      wallPosition.y = 0;
      const qrot = new Quaternion();
      qrot.setFromUnitVectors(wallPosition, worldDirection);
      const thickness = roomSettings.thickness / 2;
      const dir = new Vector3().sub(wallPosition).normalize();
      dir.multiplyScalar(thickness);
      wallPosition.add(dir);

      const projectedPoint = box.clone().projectOnPlane(dir);
      const wallProjectedPosition = wallPosition.add(projectedPoint);
      const closestSide = getClosestSide(sides, wallProjectedPosition);
      if (closestSide != null) {
        result.push([closestSide, wallProjectedPosition]);
      }
    }
    return result;
  }, [sides, roomSettings]);

  const measurementsSystem = globalStore.use.measurementsSystem();

  const points = useMemo<RoomMeasurementsPoint[]>(() => {
    const result: RoomMeasurementsPoint[] = [];
    if (lines == undefined) return result;

    const worldDirection = group.getWorldDirection(new Vector3());
    for (let i = 0; i < lines?.length; i++) {
      const line = lines[i];
      const distance = line[0].distanceTo(line[1]);
      const lineCenter = new Vector3(
        (line[1].x + line[0].x) / 2,
        (line[1].y + line[0].y) / 2,
        (line[1].z + line[0].z) / 2
      );
      const side = line[0].clone().sub(boxCenter);
      side.y = 0;
      side.normalize();
      side.multiply(side);
      const qrot = new Quaternion();
      qrot.setFromUnitVectors(side, worldDirection);
      const dir = new Vector3(-0.1, 0, 0);
      dir.applyQuaternion(qrot);
      const camerapos = cameraPosition.clone();
      camerapos.y = 0;
      let sideSign = 1;
      const lineDir = line[0].clone().sub(line[1]).normalize();
      if (lineDir.x > 0.01 || lineDir.z < -0.01) {
        sideSign = isLeftSide(line[0], line[1], camerapos);
      } else {
        sideSign = isLeftSide(line[1], line[0], camerapos);
      }
      qrot.multiply(
        new Quaternion().setFromEuler(
          new Euler(-Math.PI / 2, 0, (Math.PI / 2) * sideSign)
        )
      );
      lineCenter.add(dir);
      result.push({
        measurement: getMeasurements(distance, measurementsSystem),
        position: lineCenter,
        rotation: new Euler().setFromQuaternion(qrot),
        anchorX: 'center'
      });
    }
    return result;
  }, [lines, sides, measurementsSystem, cameraPosition]);

  return (
    <group>
      {map(lines, (points, index) => (
        <Line key={index} points={points} color="black" lineWidth={1} />
      ))}
      {map(points, (point, index) => (
        <Text
          key={index}
          ref={(el) => (textRefs.current[index] = el as Object3D)}
          position={point.position}
          rotation={point.rotation}
          anchorX={point.anchorX}
          anchorY="middle"
          color="black"
          renderOrder={-2}
        >
          {point.measurement}
        </Text>
      ))}
    </group>
  );
}
