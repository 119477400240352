import { ClassName, IsDisabled } from '../../../types/general.types';

export const enum FieldIcons {
  DOLLAR = '$',
  METER = 'm'
}

export type InputNumberProps = {
  disabled?: IsDisabled;
  error?: string;
  inputClassName?: ClassName;
  leftIcon?: FieldIcons;
  max?: number;
  min?: number;
  name: string;
  placeholder?: string;
  step?: number | 'any';
  value: number | null;
  onChange: (value: number | null) => void;
};
