import { useCallback, useRef } from 'react';
import { Vector2 } from 'three';
import { ThreeEvent, useThree } from '@react-three/fiber';
import isEmpty from 'lodash/isEmpty';

import { SceneNode, CameraNode } from '../types/scene.types';

import { sceneStore } from '../store/sceneStore';

const maxCursorShift = 16;

export function useSelectable(node: SceneNode | CameraNode) {
  const object = useRef<THREE.Object3D | null>(null);
  const mousePosition = useRef(new Vector2());

  const { gl, raycaster } = useThree(({ gl, raycaster }) => ({
    gl,
    raycaster
  }));

  const handlePointerDown = useCallback<(event: MouseEvent) => void>(
    (event) => {
      mousePosition.current.set(event.clientX, event.clientY);
    },
    []
  );

  const handlePointerUp = useCallback<(event: MouseEvent) => void>(
    (event) => {
      const distance = mousePosition.current.distanceTo(
        new Vector2(event.clientX, event.clientY)
      );

      if (
        distance <= maxCursorShift &&
        object.current &&
        isEmpty(raycaster.intersectObject(object.current))
      ) {
        sceneStore.set.selectedNode(null);
        gl.domElement.removeEventListener('pointerdown', handlePointerDown);
        gl.domElement.removeEventListener('pointerup', handlePointerUp);
      }
    },
    [gl.domElement]
  );

  const handleClick = useCallback<(event: ThreeEvent<MouseEvent>) => void>(
    (event) => {
      if (sceneStore.get.selectedNode() === node) {
        sceneStore.set.selectedNode(null);
        object.current = null;

        gl.domElement.removeEventListener('pointerdown', handlePointerDown);
        gl.domElement.removeEventListener('pointerup', handlePointerUp);
        return;
      }

      object.current = event.eventObject;
      sceneStore.set.selectedNode(node);

      setTimeout(() => {
        gl.domElement.addEventListener('pointerdown', handlePointerDown);
        gl.domElement.addEventListener('pointerup', handlePointerUp);
      }, 0);
    },
    [gl.domElement, node]
  );

  return {
    selectedNode: sceneStore.use.selectedNode(),
    handleClick
  };
}
