import { ReactNode } from 'react';

import { MainAppLayout } from '../MainAppLayout';
import { useChangeAppLocale } from '../../../common/hooks/useChangeAppLocale';
import { useMountEffect } from '../../../common/hooks/useMountEffect';

interface AppLayoutProps {
  children: ReactNode;
}

function AppLayout({ children }: AppLayoutProps) {
  const { changeAppLocale } = useChangeAppLocale();

  useMountEffect(() => {
    changeAppLocale('en');
  });

  return <MainAppLayout>{children}</MainAppLayout>;
}

export default AppLayout;
