import { useEffect, useMemo } from 'react';
import { useGLTF } from '@react-three/drei';
import each from 'lodash/each';

import { MaterialsLib, MaterialType } from '../../../../types/scene.types';

import {
  defaultSceneStoreState,
  sceneStore
} from '../../../../store/sceneStore';
import { globalStore } from '../../../../store/globalStore';

interface RealWorldMaterialsProps {
  mats: Record<string, string>;
}

export function RealWorldMaterials({ mats }: RealWorldMaterialsProps) {
  const settings = globalStore.use.settings();

  if (!settings) return null;

  const realWorldMaterialsLib = useMemo(() => {
    const lib: MaterialsLib = {
      ...defaultSceneStoreState.materialsLib
    };

    each(mats, (matItem) => {
      const { materials } = useGLTF(matItem);

      each(materials, (material) => {
        material.userData.fileUrl = matItem;
        material.userData.materialType = MaterialType.REAL_WORLD;
      });

      Object.assign(lib, materials);
    });

    return lib;
  }, [mats, defaultSceneStoreState, settings.materials.assets]);

  useEffect(() => {
    each(realWorldMaterialsLib, (material) => {
      if (!material.name) return;

      const materialsLib = sceneStore.get.materialsLib();

      if (material.uuid === materialsLib[material.name]?.uuid) return;

      sceneStore.set.updateMaterialLib(material.name, material);
    });
  }, [realWorldMaterialsLib]);

  return null;
}
