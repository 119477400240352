import { createStore } from '@udecode/zustood';
import { Plane, Vector3 } from 'three';
import omit from 'lodash/omit';

import { Settings } from '../types/settings.types';
import each from 'lodash/each';
import { useGLTF } from '@react-three/drei';

export interface GlobalStore {
  plane: Plane;
  canvasWrapper: HTMLDivElement | null;
  settings: Settings | null;
  enableMeasurements: boolean;
  enableRoomMeasurements: boolean;
  measurementsSystem: MeasurementsSystem;
  enableDoor: boolean;
  enableWindow: boolean;
  collisionEnabled: boolean;
  realWorldUVScale: number;
}

export const enum MeasurementsSystem {
  METRICAL = 'metrical',
  AMERICAN = 'american'
}

const defaultGlobalStoreState: GlobalStore = {
  plane: new Plane(new Vector3(0, 1, 0)),
  canvasWrapper: null,
  settings: null,
  enableMeasurements: false,
  enableRoomMeasurements: false,
  measurementsSystem: MeasurementsSystem.AMERICAN,
  enableDoor: true,
  enableWindow: true,
  collisionEnabled: false,
  realWorldUVScale: 0.01
};

export const globalStore = createStore('GlobalStore')(
  defaultGlobalStoreState
).extendActions((set) => ({
  clear: () => {
    set.state((draft) => {
      Object.assign(draft, omit(defaultGlobalStoreState, 'canvasWrapper'));
    });
  }
}));

globalStore.store.subscribe(({ settings }, { settings: prevSettings }) => {
  if (settings === prevSettings || !settings) return;

  each(
    {
      ...settings.models.assets
    },
    (item) => {
      useGLTF.preload(item.fileUrl);
    }
  );
});
