import { MaterialType } from '../../types/scene.types';
import { SettingsMaterialAssetItems } from '../../types/settings.types';

export const mockDecorationsTextures: SettingsMaterialAssetItems = {
  'Fabric-Knit-Brushed-Grey': {
    id: 'Fabric-Knit-Brushed-Grey',
    previewImage: '/assets/materials/preview/Fabric-Knit-Brushed-Grey.jpg',
    name: 'Fabric Knit Brushed Grey',
    filesUrl: {
      [MaterialType.ATLAS]: {
        fabric:
          '/assets/materials/atlas/lib/aria-ultra-ww-armchair/Fabric-Knit-Brushed-Grey.glb'
      }
    }
  },
  'Fabric-Canvas-Weave': {
    id: 'Fabric-Canvas-Weave',
    previewImage: '/assets/materials/preview/Fabric-Canvas-Weave.jpg',
    name: 'Fabric Canvas Weave',
    filesUrl: {
      [MaterialType.ATLAS]: {
        fabric:
          '/assets/materials/atlas/lib/aria-ultra-ww-armchair/Fabric-Canvas-Weave.glb'
      }
    }
  },
  'Fabric-Hessian-Blue': {
    id: 'Fabric-Hessian-Blue',
    previewImage: '/assets/materials/preview/Fabric-Hessian-Blue.jpg',
    name: 'Fabric Hessian Blue',
    filesUrl: {
      [MaterialType.ATLAS]: {
        fabric:
          '/assets/materials/atlas/lib/aria-ultra-ww-armchair/Fabric-Hessian-Blue.glb'
      }
    }
  },
  'Wood-Birch-European': {
    id: 'Wood-Birch-European',
    previewImage: '/assets/materials/preview/Wood-Birch-European.jpg',
    name: 'Wood Birch European',
    filesUrl: {
      [MaterialType.ATLAS]: {
        wood: '/assets/materials/atlas/lib/aria-ultra-ww-armchair/Wood-Birch-European.glb'
      }
    }
  },
  'Wood-Cherry': {
    id: 'Wood-Cherry',
    previewImage: '/assets/materials/preview/Wood-Cherry.jpg',
    name: 'Wood Cherry',
    filesUrl: {
      [MaterialType.ATLAS]: {
        wood: '/assets/materials/atlas/lib/aria-ultra-ww-armchair/Wood-Cherry.glb'
      }
    }
  },
  'Wood-Chestnut-Sweet': {
    id: 'Wood-Chestnut-Sweet',
    previewImage: '/assets/materials/preview/Wood-Chestnut-Sweet.jpg',
    name: 'Wood Chestnut Sweet',
    filesUrl: {
      [MaterialType.ATLAS]: {
        wood: '/assets/materials/atlas/lib/aria-ultra-ww-armchair/Wood-Chestnut-Sweet.glb'
      }
    }
  },
  'Black-python-legs': {
    id: 'Black-python-legs',
    previewImage: '/assets/materials/preview/Black-python-legs.jpg',
    name: 'Black',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'python-sofa-legs':
          '/assets/materials/atlas/lib/python-sofa/Black-python-legs.glb'
      }
    }
  },
  'Golden-python-legs': {
    id: 'Golden-python-legs',
    previewImage: '/assets/materials/preview/Golden-python-legs.jpg',
    name: 'Gold',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'python-sofa-legs':
          '/assets/materials/atlas/lib/python-sofa/Golden-python-legs.glb'
      }
    }
  },
  'Silver-python-legs': {
    id: 'Silver-python-legs',
    previewImage: '/assets/materials/preview/Silver-python-legs.jpg',
    name: 'Silver',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'python-sofa-legs':
          '/assets/materials/atlas/lib/python-sofa/Silver-python-legs.glb'
      }
    }
  },
  'Wood-python-legs': {
    id: 'Wood-python-legs',
    previewImage: '/assets/materials/preview/Wood-python-legs.jpg',
    name: 'Wood',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'python-sofa-legs':
          '/assets/materials/atlas/lib/python-sofa/Wood-python-legs.glb'
      }
    }
  },
  'Fabric-Cream': {
    id: 'Fabric-Cream',
    previewImage: '/assets/materials/preview/Fabric-Cream.jpg',
    name: 'Fabric Cream',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'python-sofa-cloth':
          '/assets/materials/atlas/lib/python-sofa/Fabric-Cream.glb'
      }
    }
  },
  'Fabric-Grey': {
    id: 'Fabric-Grey',
    previewImage: '/assets/materials/preview/Fabric-Grey.jpg',
    name: 'Fabric Grey',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'python-sofa-cloth':
          '/assets/materials/atlas/lib/python-sofa/Fabric-Grey.glb'
      }
    }
  },
  'Fabric-Light': {
    id: 'Fabric-Light',
    previewImage: '/assets/materials/preview/Fabric-Light.jpg',
    name: 'Fabric Light',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'python-sofa-cloth':
          '/assets/materials/atlas/lib/python-sofa/Fabric-Light.glb'
      }
    }
  },
  'Velur-Blue': {
    id: 'Velur-Blue',
    previewImage: '/assets/materials/preview/Velur-Blue.jpg',
    name: 'Velur Blue',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'python-sofa-cloth':
          '/assets/materials/atlas/lib/python-sofa/Velur-Blue.glb'
      }
    }
  },
  'Velur-Olive': {
    id: 'Velur-Olive',
    previewImage: '/assets/materials/preview/Velur-Olive.jpg',
    name: 'Velur Olive',
    filesUrl: {
      [MaterialType.ATLAS]: {
        'python-sofa-cloth':
          '/assets/materials/atlas/lib/python-sofa/Velur-Olive.glb'
      }
    }
  }
};
