import { useEffect, useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { sceneStore } from '../store/sceneStore';
import { Vector3 } from 'three';
import { OrbitControls } from 'three-stdlib';

import { CameraOrientation, SceneNodeType } from '../types/scene.types';

export function CameraControl() {
  const draggedNode = sceneStore.use.draggedNode();
  const configureNode = sceneStore.use.configureNode();

  const orbitControls = useThree((state) => state.controls) as OrbitControls;
  const requestRender = useThree(({ invalidate }) => invalidate);
  const camera = useThree(({ camera }) => camera);
  const activeCamera = sceneStore.use.activeCamera();
  const cameraOrientation = sceneStore.use.cameraOrientation();
  const [center, setValue] = useState(new Vector3());
  const originalTarget = useRef(new Vector3());
  const iteration = useRef(0);

  useEffect(() => {
    if (activeCamera && activeCamera.position && activeCamera.rotation) {
      const perspective = sceneStore.get.perspectiveCamera();
      if (!perspective)
        sceneStore.set.perspectiveCamera({
          id: '',
          model: '',
          type: SceneNodeType.CAMERA,
          position: camera.position.clone(),
          rotation: camera.rotation.clone()
        });
      orbitControls.enabled = false;
      camera.position.copy(activeCamera.position);
      camera.rotation.copy(activeCamera.rotation);
      const distance = camera.position.distanceTo(new Vector3(0, 0, 0));
      orbitControls.target.copy(
        camera.position
          .clone()
          .add(camera.getWorldDirection(new Vector3()).multiplyScalar(distance))
      );
      camera.updateMatrixWorld();
      requestRender();
      orbitControls.enabled = true;
      if (perspective && activeCamera.id === perspective.id)
        sceneStore.set.perspectiveCamera(null);
    }
  }, [activeCamera]);

  useEffect(() => {
    if (!sceneStore.get.perspectiveCamera())
      sceneStore.set.perspectiveCamera({
        id: '',
        model: '',
        type: SceneNodeType.CAMERA,
        position: camera.position.clone(),
        rotation: camera.rotation.clone()
      });
    const roomSettings = sceneStore.get.roomSettings();
    const y = roomSettings.wallHeight / 2;
    const sideWidth = roomSettings.plan[0].distanceTo(roomSettings.plan[1]);
    const frontWidth = roomSettings.plan[1].distanceTo(roomSettings.plan[2]);
    if (cameraOrientation?.orient === CameraOrientation.FRONT) {
      camera.position.copy(new Vector3(0, y, (-1 * sideWidth) / 1.5));
    }
    if (cameraOrientation?.orient === CameraOrientation.SIDE) {
      camera.position.copy(new Vector3((1 * frontWidth) / 1.5, y, 0));
    }
    if (cameraOrientation?.orient === CameraOrientation.TOP) {
      camera.position.copy(new Vector3(0, y * 5, 0));
    }
    camera.updateMatrixWorld();
    requestRender();
  }, [cameraOrientation]);

  useEffect(() => {
    function startOrbitDrag() {
      if (iteration.current !== 0) {
        camera.updateProjectionMatrix();
      }
      iteration.current = 1;
    }
    orbitControls?.addEventListener('start', startOrbitDrag);
    return () => {
      orbitControls?.removeEventListener('start', startOrbitDrag);
    };
  }, [orbitControls]);

  useEffect(() => {
    if (draggedNode != null || configureNode?.type !== SceneNodeType.GROUP)
      return;
    const righClose = new Vector3();
    const leftFar = new Vector3();

    for (const key in configureNode.model) {
      const sceneObject = configureNode.model[key];
      if (sceneObject.position.x > righClose.x) {
        righClose.x = sceneObject.position.x;
      }
      if (sceneObject.position.z > righClose.z) {
        righClose.z = sceneObject.position.z;
      }
      if (sceneObject.position.z < leftFar.z) {
        leftFar.z = sceneObject.position.z;
      }
      if (sceneObject.position.x < leftFar.x) {
        leftFar.x = sceneObject.position.x;
      }
      leftFar.y = sceneObject.position.y;
    }
    if (orbitControls) originalTarget.current = orbitControls.target.clone();
    iteration.current = 0;
    setValue(
      new Vector3(
        (righClose.x + leftFar.x) / 2,
        leftFar.y,
        (righClose.z + leftFar.z) / 2
      )
    );
    requestRender();
  }, [configureNode, draggedNode]);

  useFrame((state, delta) => {
    if (orbitControls !== null) {
      if (iteration.current < 1) {
        iteration.current += delta;
        orbitControls.enabled = false;
        orbitControls.target.lerpVectors(
          originalTarget.current,
          center,
          -(Math.cos(Math.PI * iteration.current) - 1) / 2
        );
        orbitControls.enabled = true;
        requestRender();
      }
    }
  });

  return null;
}
