import { IndexLayout } from '../../../../common/layouts/IndexLayout';
import { ProductsIndexPageSubHeader } from '../../components/ProductsIndexPageSubHeader';
import { ProductsList } from '../../components/ProductsList';

function ProductsIndexPage() {
  return (
    <IndexLayout subHeader={<ProductsIndexPageSubHeader />}>
      <ProductsList />
    </IndexLayout>
  );
}

export default ProductsIndexPage;
