import { Mesh, Object3D } from 'three';
import values from 'lodash/values';

import { Settings } from '../types/settings.types';
import { MaterialType } from '../types/scene.types';
import { reduce } from 'lodash';
import { parseMeshName } from './parseMeshName';

export function parseModel(settings: Settings, model: Object3D) {
  const materialTypes = values(MaterialType);
  const materialTypeInverse = reduce(
    MaterialType,
    (acc, val, key) => (acc[val] = key as keyof typeof MaterialType) && acc,
    {} as Record<string, keyof typeof MaterialType>
  );

  const parsedModel = reduce(
    model.children,
    (acc, node) => {
      const { clearFullName } = parseMeshName(settings, node.name);

      if (
        materialTypes.includes(clearFullName as MaterialType) &&
        node instanceof Mesh
      ) {
        acc[materialTypeInverse[clearFullName]] = node;
      }

      return acc;
    },
    {} as Record<keyof typeof MaterialType, Mesh | undefined>
  );

  return parsedModel;
}
