import { IconsEnum } from '../../../../assets/icons/types';

import { Icon } from '../../../../helpers/Icon';

export interface ContentBrowserSelectItemProps {
  img: string;
  name: string;
  category: string;
  selected: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

function ContentBrowserSelectItem({
  img,
  name,
  category,
  selected,
  onClick
}: ContentBrowserSelectItemProps) {
  return (
    <div>
      <div
        className="aspect-1 rounded dark:bg-gray-850 relative"
        onClick={onClick}
      >
        <img
          src={img}
          alt={name}
          className="object-cover h-full w-full rounded"
        />

        {selected && (
          <div className="absolute left-0 top-0 p-1 flex justify-end items-start pointer-events-none">
            <div className="text-white p-0.5 rounded-full bg-blue-500">
              <Icon icon={IconsEnum.CHECK} className="h-4 w-4" />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-0.5 py-1">
        <div className="text-2xs line-clamp-1 text-gray-500">{category}</div>
        <div className="text-2xs line-clamp-3">{name}</div>
      </div>
    </div>
  );
}

export default ContentBrowserSelectItem;
