import { Select } from '@react-three/postprocessing';
import { ReactNode } from 'react';

import { SceneNode } from '../../types/scene.types';

import { sceneStore, SceneStore } from '../../store/sceneStore';
import { dndStore } from '../../store/dndStore';

interface SelectionProps {
  node: SceneNode;
  children: ReactNode;
}

const sceneStoreSelector = ({
  hoveredNode,
  selectedNode,
  draggedNode
}: SceneStore) => ({
  hoveredNode,
  selectedNode,
  draggedNode
});

export function Selection({ node, children }: SelectionProps) {
  const { hoveredNode, selectedNode, draggedNode } =
    sceneStore.useStore(sceneStoreSelector);

  const active = dndStore.use.active();

  return (
    <Select
      enabled={
        !active &&
        (selectedNode === node || (!draggedNode && hoveredNode === node))
      }
    >
      {children}
    </Select>
  );
}
