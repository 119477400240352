import { MergeState, SetImmerState, SetRecord } from '@udecode/zustood';

import { SceneNode, SceneNodeType } from '../../types/scene.types';
import { sceneStore, SceneStore } from '../sceneStore';

import { detachSlotsForSlotNode } from './detachSlotsForSlotNode';

export function deleteSceneNode(
  set: SetRecord<SceneStore> & {
    state: SetImmerState<SceneStore>;
    mergeState: MergeState<SceneStore>;
  },
  get: typeof sceneStore.get,
  node: SceneNode
) {
  get.draggedNode() === node && set.draggedNode(null);
  get.hoveredNode() === node && set.hoveredNode(null);
  get.selectedNode() === node && set.selectedNode(null);

  node?.type === SceneNodeType.SLOT &&
    detachSlotsForSlotNode(node, (childNode: SceneNode) =>
      deleteSceneNode(set, get, childNode)
    );

  const scene = get.scene();
  const parent = node.parent;

  parent
    ? delete parent.model[node.id]
    : delete scene[node.scope || '']?.[node.id];
}
