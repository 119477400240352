export const products = [
  {
    brand: 'Article',
    brandKey: 'article',
    category: 'Sectional sofas',
    id: '1',
    nda: true,
    preview: '/assets/models/sku446/preview/sku446.jpg',
    sku: '60849083',
    style: 'Modern',
    name: 'Quadra'
  },
  {
    brand: 'Article',
    brandKey: 'article',
    category: 'Sectional sofas',
    id: '2',
    nda: true,
    preview: '/assets/models/sku14404/preview/sku14404.jpg',
    sku: '60849083',
    style: 'Modern',
    name: 'Quadra Duo'
  },
  {
    brand: 'West Elm',
    brandKey: 'westelm',
    category: 'Sectional sofas',
    id: '3',
    nda: true,
    preview: '/assets/models/harmony/preview/harmony.jpg',
    sku: '60849083',
    style: 'Modern',
    name: 'Harmony'
  }
];
