import { ModelBind, Settings } from '../types/settings.types';

import { mockSku446 } from './models/mockSku446';
import { mockDecorations } from './models/mockDecorations';
import { mockRoomTextures } from './textures/mockRoomTextures';
import { mockTextures } from './textures/mockTextures';
import { mockDecorationsTextures } from './textures/mockDecorationsTextures';

export const sku446Settings: Settings = {
  info: {
    preview: '/assets/models/sku446/preview/sku446.jpg',
    previewLarge: '/assets/models/sku446/preview/sku446_large.jpg',
    brand: 'Article',
    name: 'Quadra',
    sku: '60849083',
    nda: true,
    style: 'Modern',
    category: 'Sectional sofas'
  },

  models: {
    assets: { ...mockDecorations, ...mockSku446 },

    bind: {
      [ModelBind.MODEL]: Object.keys(mockSku446),
      [ModelBind.DECORATIONS]: Object.keys(mockDecorations)
    }
  },

  materials: {
    assets: {
      ...mockTextures,
      ...mockDecorationsTextures,
      ...mockRoomTextures
    },

    bind: {
      cloth: [
        {
          name: 'twiling-blue'
        },
        {
          name: 'lull-blue'
        },
        {
          name: 'charm'
        },
        {
          name: 'vine-green'
        },
        {
          name: 'carbon'
        },
        {
          name: 'winter-gray'
        }
      ],
      legs: [
        {
          name: 'alumin'
        },
        {
          name: 'gold'
        },
        {
          name: 'plastic'
        }
      ],
      fabric: [
        {
          name: 'Fabric-Knit-Brushed-Grey'
        },
        {
          name: 'Fabric-Canvas-Weave'
        },
        {
          name: 'Fabric-Hessian-Blue'
        }
      ],
      wood: [
        {
          name: 'Wood-Birch-European'
        },
        {
          name: 'Wood-Cherry'
        },
        {
          name: 'Wood-Chestnut-Sweet'
        }
      ],
      'p-legs': [
        { name: 'Wood-python-legs' },
        { name: 'Black-python-legs' },
        { name: 'Silver-python-legs' },
        { name: 'Golden-python-legs' }
      ],
      'p-cloth': [
        { name: 'Fabric-Cream' },
        { name: 'Fabric-Grey' },
        { name: 'Fabric-Light' },
        { name: 'Velur-Blue' },
        { name: 'Velur-Olive' }
      ]
    }
  },

  naming: {
    prefix: {
      model: 'model',
      slots: 'slots',
      childs: 'childs'
    },

    separator: {
      empty: '#',
      group: '_',
      points: '+'
    }
  }
};
