import { Euler, Material, Vector2, Vector3, Box3 } from 'three';

export const enum SceneNodeType {
  SIMPLE = 'simple',
  SLOT = 'slot',
  GROUP = 'group',
  CAMERA = 'camera'
}
export const enum CameraOrientation {
  TOP = 'top',
  FRONT = 'front',
  SIDE = 'side'
}

export const enum SceneNodeAttach {
  DEFAULT = 'default',
  WALL = 'wall'
}

export const enum SceneNodeStick {
  DEFAULT = 'default',
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum MaterialType {
  ATLAS = 'atlas',
  // UDIM = 'udim',
  REAL_WORLD = 'real-world'
}

export type MaterialItem = {
  name: string;
};

export interface Materials {
  [key: string]: MaterialItem;
}

export interface MaterialsLib {
  [key: string]: Material;
}

export interface Scene {
  [key: string]: SceneNodeItems;
}

export interface CameraNodeItems {
  [key: string]: CameraNode;
}

export interface SceneNodeItems {
  [key: string]: SceneNode;
}

export type SceneNode = SimpleNode | SlotNode | GroupNode;
export interface DraggableNode {
  position: Vector3;
  rotation: Euler;
  type: SceneNodeType.SIMPLE;
}
export interface BaseNode extends DraggableNode {
  id: string;
  sku: string;
  attach: SceneNodeAttach;
  stick: SceneNodeStick;
  box?: Box3;
  groupDif?: Vector3;

  parent: GroupNode | null;
  scope?: string;
  size?: string;
}

export interface SimpleNode extends Omit<BaseNode, 'type'> {
  type: SceneNodeType.SIMPLE;
  model: string;
}

export interface SlotNode extends Omit<BaseNode, 'type'> {
  type: SceneNodeType.SLOT;
  model: string;
  slots: Slots;
}

export interface GroupNode extends Omit<BaseNode, 'type'> {
  type: SceneNodeType.GROUP;
  model: SceneNodeItems;
}

export interface CameraNode
  extends Omit<DraggableNode, 'type' | 'position' | 'rotation'> {
  id: string;
  scope?: string;
  model: string;
  position?: Vector3;
  rotation?: Euler;
  type: SceneNodeType.CAMERA;
}

export interface Slots {
  [key: string]: SlotItem;
}

export interface SlotItem {
  name: string;
  points: SlotPoints;
  point?: SlotItemPoint;
}

export interface SlotPoints {
  [key: string]: SlotItemPoint;
}

export interface SlotItemPoint {
  name: string;
  position: Vector3;
  rotation: Euler;
  node?: SlotNode;
  isChild: boolean;
  available: SlotItemPointAvailable[];
}

export interface SlotItemPointAvailable {
  sideName: string;
  pointName: string;
}

export interface RoomSettings {
  plan: Vector2[];
  wallHeight: number;
  materials: RoomSettingsMaterials;
  thickness: number;
  boxes: Box3[];
  floor: Box3;
}

export interface RoomSettingsMaterials {
  floor: MaterialItem;
  walls: MaterialItem;
  ceiling: MaterialItem;
}

export interface ConfigureItemDescriptionNode {
  productName: string | undefined;
  productBrand: string | undefined;
  sku: string | undefined;
  size: string | undefined;
}

export interface SimpleItemDescriptionNode {
  productName: string | undefined;
  size: string | undefined;
}

export interface CameraItemDescriptionNode {
  cameraName: string | undefined;
  cameraPosition: string | undefined;
  cameraRotation: string | undefined;
}

export interface SidebarDescriptionNode {
  materials: { [key: string]: string } | null;
  description:
    | ConfigureItemDescriptionNode
    | SimpleItemDescriptionNode
    | CameraItemDescriptionNode
    | null;
}
