import { useCallback, useRef, useEffect } from 'react';
import { Mesh } from 'three';

import { sceneStore } from '../store/sceneStore';
import { HandleDraggableEvent } from '../hooks/useDraggable';
import { useSelectable } from '../hooks/useSelectable';

import { Model, ModelProps } from './Model';

import { Draggable } from './helpers/Draggable';
import { Hoverable } from './helpers/Hoverable';
import { dndStore } from '../store/dndStore';

type DraggableModelProps = ModelProps;

export function DraggableModel({ node }: DraggableModelProps) {
  const selectedNode = sceneStore.use.selectedNode();

  const { handleClick } = useSelectable(node);

  const mesh = useRef<Mesh>();

  const handlePointerDown = useCallback<HandleDraggableEvent>(
    ({ planeIntersectPoint }) => {
      dndStore.get
        .modelShift()
        .set(
          node.position.x - planeIntersectPoint.x,
          0,
          node.position.z - planeIntersectPoint.z
        );
    },
    [node.position.x, node.position.y]
  );

  const handleDragStart = useCallback<HandleDraggableEvent>(() => {
    sceneStore.set.updatePreview(node);

    sceneStore.set.deleteSceneNode(node);
    sceneStore.set.draggedNode(node);
    sceneStore.set.selectedNode(node);
  }, [node]);

  useEffect(() => {
    mesh.current &&
      selectedNode === node &&
      sceneStore.set.draggedObject(mesh.current);
  }, [selectedNode]);

  return (
    <Hoverable node={node}>
      <Draggable
        onPointerDown={handlePointerDown}
        onDragStart={handleDragStart}
        onClick={handleClick}
      >
        <Model ref={mesh} node={node} />
      </Draggable>
    </Hoverable>
  );
}
