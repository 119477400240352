import { Group, Object3D } from 'three';
import keys from 'lodash/keys';
import map from 'lodash/map';

import {
  NamingPrefix,
  Settings,
  SettingsNamingPrefix
} from '../types/settings.types';

export function parseGLTFNodes(settings: Settings, scene: Group) {
  const paresedNodes: Partial<NamingPrefix<Object3D>> = {};

  const notFoundedNodes = map(scene.children, (node, i) => ({
    index: i,
    key: node.name
  }));
  const notFoundedPrefix = keys(settings.naming.prefix);

  for (let i = 0; i < notFoundedNodes.length; ++i) {
    const key = notFoundedNodes[i].key;
    const node = scene.children[notFoundedNodes[i].index];

    for (let j = 0; j < notFoundedPrefix.length; ++j) {
      const prefixKey = notFoundedPrefix[j] as keyof SettingsNamingPrefix;
      const prefixValue = settings.naming.prefix[prefixKey];

      if (key.indexOf(prefixValue) === 0) {
        paresedNodes[prefixKey as keyof NamingPrefix<Object3D>] = node;

        notFoundedNodes.splice(i, 1);
        --i;
        notFoundedPrefix.splice(j, 1);
        --j;
        break;
      }
    }
  }

  if (!paresedNodes.model) {
    paresedNodes.model = scene;
  }

  return paresedNodes;
}
