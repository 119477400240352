import map from 'lodash/map';
import { ReactNode } from 'react';

import { SidebarDescriptionNode } from '../../../../types/scene.types';

type ConfiguratorPageSidebarDescriptionProps = {
  descriptionNode: SidebarDescriptionNode | null;
  actions: ReactNode;
};

function ConfiguratorPageSidebarDescription({
  descriptionNode,
  actions
}: ConfiguratorPageSidebarDescriptionProps) {
  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1 relative">
        <div className="absolute inset-0 overflow-y-auto">
          <div>
            {descriptionNode && descriptionNode.description && (
              <div className="">
                <div className="grid grid-cols-2 gap-1 p-2">
                  <div className="col-span-full text-sm font-thin text-gray-500">
                    Description
                  </div>
                </div>
                <div className="p-[8px]">
                  {map(descriptionNode.description, (node, key) => (
                    <div className="grid grid-cols-2 gap-1 p-[2px]">
                      <div className="">
                        {(key.charAt(0).toUpperCase() + key.slice(1))
                          .split(/(?=[A-Z])/)
                          ?.join(' ')}
                      </div>
                      <div className="">{node}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {descriptionNode && descriptionNode.materials && (
              <div className="">
                <div className="grid grid-cols-2 gap-1 p-2">
                  <div className="col-span-full text-sm font-thin text-gray-500">
                    Materials
                  </div>
                </div>
                <div className="p-[8px]">
                  {map(descriptionNode.materials, (node, key) => (
                    <div className="grid grid-cols-2 gap-1 p-[2px]">
                      <div className="">
                        {(key.charAt(0).toUpperCase() + key.slice(1))
                          .split(/(?=[A-Z])/)
                          ?.join(' ')}
                      </div>
                      <div className="">{node}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex flex-col p-2 gap-1">
              <div className="text-sm font-thin text-gray-500">Actions</div>
              {actions}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfiguratorPageSidebarDescription;
