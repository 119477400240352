import React, { useState } from 'react';

import { I18nText } from '../../types/general.types';
import { TooltipOffset } from '../tooltips/types';
import { Tooltip } from '../tooltips/Tooltip';

import { TooltipPlacement } from '../tooltips/tooltipsConstants';
import Translate from '../Translate/Translate';

interface TextWithTooltipProps {
  text: string;
  tooltipI18nText?: I18nText;
  className?: string;
  tooltipPlacement?: TooltipPlacement;
  withArrow?: boolean;
  withoutOffset?: boolean;
}

const offset: TooltipOffset = [-12, 0];

function TextWithTooltip({
  text,
  tooltipI18nText,
  className,
  tooltipPlacement,
  withArrow,
  withoutOffset
}: TextWithTooltipProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  if (!text) {
    return null;
  }

  return (
    <>
      <span className={className} ref={setReferenceTooltipElement}>
        {text}
      </span>
      <Tooltip
        referenceElement={referenceTooltipElement}
        placement={tooltipPlacement}
        interactive={true}
        followCursor="horizontal"
        offset={withoutOffset ? undefined : offset}
        withArrow={withArrow}
      >
        {tooltipI18nText ? <Translate id={tooltipI18nText} /> : text}
      </Tooltip>
    </>
  );
}

export default TextWithTooltip;
