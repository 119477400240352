import { Box3, Euler, Vector3 } from 'three';
import { useGLTF } from '@react-three/drei';
import { useCallback, useMemo } from 'react';
import { ThreeEvent } from '@react-three/fiber';

import {
  SlotNode,
  SlotItem,
  SlotItemPoint,
  SceneNodeAttach,
  SceneNodeType,
  SceneNodeStick
} from '../../types/scene.types';

import { NewSlotNode, sceneStore } from '../../store/sceneStore';

import { parseGLTFNodes } from '../../utils/parseGLTFNodes';
import { globalStore } from '../../store/globalStore';
import find from 'lodash/find';

interface SlotBoxProps {
  parent: SlotNode;
  parentSlot: SlotItem;
  parentSlotPoint: SlotItemPoint;
  item: NewSlotNode | SlotNode;
  itemSlot: SlotItem;
  itemSlotPoint: SlotItemPoint;
  scope: string;
}

export function SlotBox({
  parent,
  parentSlot,
  parentSlotPoint,
  item,
  itemSlot,
  itemSlotPoint,
  scope
}: SlotBoxProps) {
  const settings = globalStore.useTracked.settings();

  if (!settings || !item.model) return null;

  const { scene } = useGLTF(item.model);

  const { model: gltfModel } = parseGLTFNodes(settings, scene);

  const boundingBox = useMemo<Box3>(() => {
    const box = new Box3();

    if (!gltfModel) return box;

    const model = gltfModel.clone();
    model.position.set(0, 0, 0);

    box.setFromObject(model);

    return box;
  }, [gltfModel]);

  const boxSize = useMemo<Vector3>(() => {
    return boundingBox.getSize(new Vector3());
  }, [boundingBox]);

  const modelPosition = useMemo<Vector3>(() => {
    const slotPosition = parent.position
      .clone()
      .add(parentSlotPoint.position)
      .sub(itemSlotPoint.position);

    const parentRotateShift = new Vector3();
    parentRotateShift.add(parentSlotPoint.position);
    parentRotateShift.sub(itemSlotPoint.position);
    parentRotateShift.applyEuler(parent.rotation);
    parentRotateShift.add(itemSlotPoint.position);
    parentRotateShift.sub(parentSlotPoint.position);

    slotPosition.add(parentRotateShift);

    const itemSlotRotateShift = new Vector3();
    itemSlotRotateShift.sub(itemSlotPoint.position);
    itemSlotRotateShift.applyEuler(itemSlotPoint.rotation);
    itemSlotRotateShift.add(itemSlotPoint.position);
    itemSlotRotateShift.applyEuler(parent.rotation);

    slotPosition.add(itemSlotRotateShift);

    return slotPosition;
  }, [parent.position, parentSlotPoint, itemSlotPoint]);

  const boxPosition = useMemo<Vector3>(() => {
    const slotPosition = modelPosition.clone();

    const boxCenter = boundingBox.getCenter(new Vector3());

    const boxCenterShift = new Vector3();
    boxCenterShift.sub(boxCenter);
    boxCenterShift.applyEuler(parent.rotation);
    boxCenterShift.applyEuler(itemSlotPoint.rotation);
    boxCenterShift.add(boxCenter);

    slotPosition.add(boxCenter);
    slotPosition.sub(boxCenterShift);

    return slotPosition;
  }, [parent.position, parentSlotPoint, itemSlotPoint, boundingBox]);

  const modelRotation = useMemo<Euler>(() => {
    const parentRotation = new Vector3().setFromEuler(parent.rotation);
    const slotRotation = new Vector3().setFromEuler(itemSlotPoint.rotation);

    return new Euler().setFromVector3(parentRotation.add(slotRotation));
  }, []);

  const handlePointerEnter = useCallback<
    (event: ThreeEvent<PointerEvent>) => void
  >(
    (event) => {
      event.stopPropagation();
      sceneStore.set.previewInSlot(true);

      const position = modelPosition.clone();
      const rotation = modelRotation.clone();

      const sectioningMode = sceneStore.get.sectioningMode();
      const sceneMode = sceneStore.get.sceneMode();
      const preview = sceneStore.get.techScope().preview;

      if (sceneMode && sectioningMode && parent.parent) {
        position.add(parent.parent.position);
      }

      preview &&
        sceneStore.set.updatePreview({
          position,
          rotation
        });
    },
    [modelPosition, modelRotation]
  );

  const handlePointerLeave = useCallback<
    (event: ThreeEvent<PointerEvent>) => void
  >((event) => {
    event.stopPropagation();

    sceneStore.set.previewInSlot(false);
  }, []);

  const handlePointerUp = useCallback<
    (event: ThreeEvent<PointerEvent>) => void
  >(
    (event) => {
      event.stopPropagation();

      if (item) {
        itemSlotPoint.node = parent;
        itemSlot.point = itemSlotPoint;

        const sectioningMode = sceneStore.get.sectioningMode();
        const sceneMode = sceneStore.get.sceneMode();

        if (sceneMode && sectioningMode && parent.parent) {
          item.position.sub(parent.parent.position);
        }

        const configureNode = sceneStore.get.configureNode();

        if (!configureNode) {
          const groupNode = sceneStore.set.addSceneNode(scope, {
            sku:
              find(settings.models.assets, (asset) => asset.id === item.sku)
                ?.parentId || '',
            rotation: new Euler(),
            position: new Vector3(),
            model: {},
            attach: SceneNodeAttach.DEFAULT,
            type: SceneNodeType.GROUP,
            stick: SceneNodeStick.DEFAULT
          });

          sceneStore.set.configureNode(groupNode);
        }

        const node = sceneStore.set.addToConfigureNode(item);

        sceneStore.set.selectedNode(node);

        parentSlotPoint.node = node as SlotNode;
        parentSlot.point = parentSlotPoint;
      }

      sceneStore.set.draggedNode(null);
      sceneStore.set.updatePreview(null);

      setTimeout(() => {
        sceneStore.set.previewInSlot(false);
      }, 0);
    },
    [item, itemSlot, parentSlot]
  );

  return (
    <group
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
      onPointerUp={handlePointerUp}
    >
      <mesh position={boxPosition} rotation={modelRotation}>
        <boxGeometry args={[boxSize.x, boxSize.y, boxSize.z]} />
        <meshStandardMaterial
          color={[0, 0, 0]}
          wireframe
          wireframeLinewidth={2}
        />
      </mesh>
    </group>
  );
}
