import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import {
  ConfiguratorEvents,
  configuratorEvents
} from '../../events/configuratorEvents';
import { sceneStore } from '../../store/sceneStore';

import { orbitCameraZoomIn, orbitCameraZoomOut } from '../../utils/threeUtils';

export function ConfiguratorEventsHandler() {
  const camera = useThree(({ camera }) => camera);
  const controls = useThree(({ controls }) => controls) as OrbitControls;
  const requestRender = useThree(({ invalidate }) => invalidate);

  useEffect(() => {
    const handleZoomIn = () => {
      orbitCameraZoomIn(camera, controls);
      requestRender();
    };

    const handleZoomOut = () => {
      orbitCameraZoomOut(camera, controls);
      requestRender();
    };

    configuratorEvents.on(ConfiguratorEvents.ZOOM_IN, handleZoomIn);
    configuratorEvents.on(ConfiguratorEvents.ZOOM_OUT, handleZoomOut);

    return () => {
      configuratorEvents.off(ConfiguratorEvents.ZOOM_IN, handleZoomIn);
      configuratorEvents.off(ConfiguratorEvents.ZOOM_OUT, handleZoomOut);
    };
  }, [camera, controls]);

  const gl = useThree(({ gl }) => gl);

  useEffect(() => {
    const handleGLPointerUp = () => {
      sceneStore.set.previewInSlot(false);
    };

    gl.domElement.addEventListener('pointerup', handleGLPointerUp);

    return () => {
      gl.domElement.removeEventListener('pointerup', handleGLPointerUp);
    };
  }, [gl]);

  return null;
}
