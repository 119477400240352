import { forwardRef } from 'react';
import { Group } from 'three';
import map from 'lodash/map';

import { GroupNode, SceneNodeType } from '../types/scene.types';

import { Model } from './Model';
import { Selection } from './helpers/Selection';

type GroupModelProps = {
  node: GroupNode;
};

export const GroupModel = forwardRef(({ node }: GroupModelProps, ref) => {
  return (
    <Selection node={node}>
      <group
        position={node.position}
        rotation={node.rotation}
        ref={ref as React.RefObject<Group>}
      >
        {map(node.model, (node) =>
          node.type === SceneNodeType.GROUP ? (
            <GroupModel key={node.id} node={node} />
          ) : (
            <Model key={node.id} node={node} />
          )
        )}
      </group>
    </Selection>
  );
});
