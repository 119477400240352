import { omit } from 'lodash';
import find from 'lodash/find';
import reduce from 'lodash/reduce';

import {
  ConfiguratorFile,
  ConfiguratorFileScopeSceneBackup,
  ConfiguratorFileSceneBackupSlotsMap,
  ConfiguratorFileSceneBackup
} from '../../types/configuratorFile.types';
import { SceneNodeType } from '../../types/scene.types';

import { globalStore } from '../globalStore';
import { sceneStore } from '../sceneStore';

export function exportConfiguratorFile(): ConfiguratorFile {
  const globalStoreState = globalStore.store.getState();

  const materials = sceneStore.get.materials();
  const sceneMode = sceneStore.get.sceneMode();
  const sectioningMode = sceneStore.get.sectioningMode();
  const scene = sceneStore.get.scene();
  const roomSettings = sceneStore.get.roomSettings();
  const configureNode = sceneStore.get.configureNode();

  const sceneBackup = reduce(
    scene,
    (acc, scopeItems, scope) => {
      const items = reduce(
        scopeItems,
        (acc, node, key) => {
          let model: string | ConfiguratorFileScopeSceneBackup = '';
          if (node.type === SceneNodeType.GROUP) {
            const modelNodes = reduce(
              node.model,
              (acc, node, key) => {
                if (node.type !== SceneNodeType.SLOT) return acc;

                const slotsMap = reduce(
                  node.slots,
                  (acc, slot, key) => {
                    const point = find(slot.points, (point) => !!point.node);

                    if (!point || !point.node) return acc;

                    acc[key] = {
                      pointName: point.name,
                      pointNodeId: point.node.id
                    };

                    return acc;
                  },
                  {} as ConfiguratorFileSceneBackupSlotsMap
                );

                acc[key] = {
                  sku: node.sku,
                  position: node.position,
                  rotation: {
                    x: node.rotation.x,
                    y: node.rotation.y,
                    z: node.rotation.z,
                    order: node.rotation.order
                  },
                  model: node.model,
                  slotsMap,
                  type: node.type,
                  attach: node.attach,
                  stick: node.stick
                };

                return acc;
              },
              {} as ConfiguratorFileScopeSceneBackup
            );

            model = modelNodes;
          }

          if (node.type === SceneNodeType.SIMPLE) {
            model = node.model as string;
          }

          acc[key] = {
            sku: node.sku,
            position: node.position,
            rotation: {
              x: node.rotation.x,
              y: node.rotation.y,
              z: node.rotation.z,
              order: node.rotation.order
            },
            type: node.type,
            attach: node.attach,
            stick: node.stick,
            model
          };

          return acc;
        },
        {} as ConfiguratorFileScopeSceneBackup
      );

      acc[scope] = items;

      return acc;
    },
    {} as ConfiguratorFileSceneBackup
  );
  return {
    globalStore: {
      ...omit(globalStoreState, 'plane', 'canvasWrapper')
    },
    sceneStore: {
      materials,
      sceneMode,
      sectioningMode
    },
    sceneStoreBackup: {
      scene: sceneBackup,
      roomSettings: roomSettings,
      configureId: configureNode?.id || null
    }
  };
}
