import { useMemo, useCallback, useEffect } from 'react';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import each from 'lodash/each';
import find from 'lodash/find';
import compact from 'lodash/compact';

import {
  SceneNode,
  SceneNodeType,
  CameraNode,
  CameraNodeItems,
  SidebarDescriptionNode
} from '../../../../types/scene.types';
import { Settings } from '../../../../types/settings.types';
import { IconsEnum } from '../../../../assets/icons/types';

import { SceneMode, sceneStore } from '../../../../store/sceneStore';
import { globalStore } from '../../../../store/globalStore';

import { ConfiguratorPageContentBrowser } from '../../components/ConfiguratorPageContentBrowser';
import { EditorLayout } from '../../../../common/layouts/EditorLayout';
import { ItemLayout } from '../../../../common/layouts/ItemLayout';
import { ConfiguratorPageToolbar } from '../../components/ConfiguratorPageToolbar';
import { ConfiguratorPageTabs } from '../../components/ConfiguratorPageTabs';
import { ConfiguratorPageSidebar } from '../../components/ConfiguratorPageSidebar';
import { ConfiguratorPageSidebarOutliner } from '../../components/ConfiguratorPageSidebarOutliner';
import { ConfiguratorPageSidebarDescription } from '../../components/ConfiguratorPageSidebarDescription';
import { ConfiguratorView } from '../../../../scene/ConfiguratorView';
import { TreeViewerNodeProps } from '../../../../helpers/TreeViewer/components/TreeViewerNode';

import { PureTooltipIconButtonHelper } from '../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';

import { ConfiguratorTabs } from '../../roomsConstants';

interface ConfiguratorPageProps {
  activeTab: ConfiguratorTabs;
}

interface SceneNodeToTreeNodeOptions {
  sceneNodes: SceneNode[];
  settings: Settings;
  selectedNode: SceneNode | CameraNode | null;
  cameras: CameraNodeItems;
}

function sceneNodeToTreeNode({
  sceneNodes,
  settings,
  selectedNode,
  cameras
}: SceneNodeToTreeNodeOptions): TreeViewerNodeProps[] {
  const memory = compact(
    map(sceneNodes, (node) =>
      node
        ? {
            node,
            item: {
              icon: IconsEnum.CUBE_OUTLINE,
              selected: selectedNode === node,
              onClick: () =>
                selectedNode === node
                  ? sceneStore.set.selectedNode(null)
                  : sceneStore.set.selectedNode(node)
            } as TreeViewerNodeProps,
            parentItem: undefined as TreeViewerNodeProps | undefined
          }
        : undefined
    )
  );

  let memoryItem: typeof memory[0] | undefined;

  const result = [] as TreeViewerNodeProps[];

  while ((memoryItem = memory.pop())) {
    const mockItem = find(
      settings.models.assets,
      (asset) => asset.id === memoryItem?.node.sku
    );
    memoryItem.item.label = mockItem?.name || memoryItem.node.sku;

    if (memoryItem.parentItem) {
      memoryItem.parentItem.childrenNodes =
        memoryItem.parentItem.childrenNodes || [];
      memoryItem.parentItem.childrenNodes.push(memoryItem.item);
    } else {
      result.push(memoryItem.item);
    }

    if (memoryItem.node.type === SceneNodeType.GROUP) {
      each(memoryItem.node.model, (node) => {
        memory.push({
          node,
          item: {
            icon: IconsEnum.CUBE_OUTLINE,
            selected: selectedNode === node,
            onClick: () =>
              selectedNode === node
                ? sceneStore.set.selectedNode(null)
                : sceneStore.set.selectedNode(node)
          } as TreeViewerNodeProps,
          parentItem: (memoryItem as typeof memory[0]).item
        });
      });
    }
  }
  let iterator = 1;
  for (const key in cameras) {
    result.push({
      icon: IconsEnum.VIDEO_CAMERA_SOLID,
      selected: false,
      label: 'Camera ' + iterator,
      onClick: () =>
        selectedNode === cameras[key]
          ? sceneStore.set.selectedNode(null)
          : sceneStore.set.selectedNode(cameras[key])
    } as TreeViewerNodeProps);
    iterator++;
  }
  return result;
}

function ConfiguratorPage({ activeTab }: ConfiguratorPageProps) {
  useEffect(() => {
    switch (activeTab) {
      case ConfiguratorTabs.PRODUCT_EDITOR:
        sceneStore.set.switchSceneMode(SceneMode.PRODUCT_EDITOR);
        break;
      case ConfiguratorTabs.ROOM_DESIGNER:
        sceneStore.set.switchSceneMode(SceneMode.ROOM_DESIGNER);
        break;
    }
  }, [activeTab]);

  const scene = sceneStore.use.scene();
  const configureNode = sceneStore.use.configureNode();
  const selectedNode = sceneStore.use.selectedNode();
  const sceneMode = sceneStore.use.sceneMode();
  const settings = globalStore.use.settings();
  const materials = sceneStore.use.materials();

  const cameras = sceneStore.useStore(({ cameras }) => cameras);
  const size = sceneStore.useStore(({ selectedNode }) => {
    if (selectedNode?.type !== SceneNodeType.CAMERA) return selectedNode?.size;
    else return null;
  });

  const outlinerSceneNodes = useMemo(() => {
    const sceneNodes = sceneMode
      ? flatten(map(scene, (scope) => map(scope, (node) => node)))
      : [configureNode as SceneNode];

    return sceneNodeToTreeNode({
      sceneNodes,
      settings: settings as Settings,
      selectedNode,
      cameras
    });
  }, [scene, configureNode, sceneMode, settings, selectedNode]);

  const outlinerNodes = useMemo(() => {
    const result: TreeViewerNodeProps[] = [
      {
        label: 'Scene',
        icon: IconsEnum.HOME,
        selected: false,
        childrenNodes: outlinerSceneNodes
      }
    ];

    return result;
  }, [outlinerSceneNodes]);

  const descriptionNode = useMemo(() => {
    if (!selectedNode) return null;
    if (
      selectedNode?.type === SceneNodeType.SLOT ||
      selectedNode?.type === SceneNodeType.GROUP
    ) {
      const mappedMaterials = {} as { [key: string]: string };
      for (const mat in materials) {
        const name = settings?.materials.assets[materials[mat].name].name;
        mappedMaterials[mat] = name == undefined ? '' : name;
      }
      const productName =
        selectedNode.type === SceneNodeType.SLOT
          ? settings?.models.assets[selectedNode.sku].name
          : settings?.info.category;
      const result: SidebarDescriptionNode = {
        description: {
          productName: productName,
          productBrand: settings?.info.brand,
          sku: settings?.info.sku,
          size: selectedNode.size
        },
        materials: mappedMaterials
      };
      return result;
    } else if (selectedNode?.type === SceneNodeType.SIMPLE) {
      const result: SidebarDescriptionNode = {
        description: {
          productName: settings?.models.assets[selectedNode.sku].name,
          size: selectedNode.size
        },
        materials: null
      };
      return result;
    } else if (selectedNode?.type === SceneNodeType.CAMERA) {
      const result: SidebarDescriptionNode = {
        description: {
          cameraName:
            'Camera ' +
            (map(cameras, (o) => o.id).indexOf(selectedNode.id) + 1),
          cameraPosition: `(${selectedNode.position?.x.toFixed(
            2
          )}, ${selectedNode.position?.y.toFixed(
            2
          )}, ${selectedNode.position?.z.toFixed(2)})`,
          cameraRotation: `(${selectedNode.rotation?.x.toFixed(
            2
          )}, ${selectedNode.rotation?.y.toFixed(
            2
          )}, ${selectedNode.rotation?.z.toFixed(2)})`
        },
        materials: null
      };
      return result;
    }
    return null;
  }, [settings, materials, selectedNode, cameras, size]);

  const handleDelete = useCallback(() => {
    if (selectedNode && selectedNode.type !== SceneNodeType.CAMERA)
      selectedNode && sceneStore.set.deleteSceneNode(selectedNode);
    if (selectedNode && selectedNode.type === SceneNodeType.CAMERA)
      selectedNode && sceneStore.set.deleteCameraNode(selectedNode);
  }, [selectedNode]);

  return (
    <ItemLayout>
      <EditorLayout
        tabs={<ConfiguratorPageTabs active={activeTab} />}
        toolbar={<ConfiguratorPageToolbar />}
        contentBrowser={
          <ConfiguratorPageContentBrowser activeTab={activeTab} />
        }
        sidebar={
          <ConfiguratorPageSidebar>
            <>
              <ConfiguratorPageSidebarDescription
                descriptionNode={descriptionNode}
                actions={
                  <>
                    {selectedNode && (
                      <PureTooltipIconButtonHelper
                        className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
                        icon={IconsEnum.X}
                        iconClassName="h-6 w-6 stroke-2"
                        tooltipText="Rotate product"
                        text="Remove"
                        tooltipPlacement={TooltipPlacement.TOP}
                        onClick={handleDelete}
                      />
                    )}
                  </>
                }
              />
              <ConfiguratorPageSidebarOutliner nodes={outlinerNodes} />
            </>
          </ConfiguratorPageSidebar>
        }
      >
        <ConfiguratorView />
      </EditorLayout>
    </ItemLayout>
  );
}

export default ConfiguratorPage;
