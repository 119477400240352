import { Camera, Spherical, Vector3, Quaternion } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
let scale = 1;
export function orbitCameraZoomIn(camera: Camera, controls: OrbitControls) {
  scale *= Math.pow(0.95, controls.zoomSpeed);
  zoomScale(controls, camera, scale);
  scale = 1;
  controls.update();
}

export function orbitCameraZoomOut(camera: Camera, controls: OrbitControls) {
  scale /= Math.pow(0.95, controls.zoomSpeed);
  zoomScale(controls, camera, scale);
  controls.update();
  scale = 1;
}
const spherical = new Spherical();
const offset = new Vector3();
const zoomScale = function (
  scope: OrbitControls,
  camera: Camera,
  scale: number
) {
  const quat = new Quaternion().setFromUnitVectors(
    camera.up,
    new Vector3(0, 1, 0)
  );
  const quatInverse = quat.clone().invert();
  const position = camera.position;
  offset.copy(position).sub(scope.target);
  offset.applyQuaternion(quat);
  spherical.setFromVector3(offset);
  spherical.makeSafe();
  spherical.radius *= scale;
  offset.setFromSpherical(spherical);
  offset.applyQuaternion(quatInverse);
  position.copy(scope.target).add(offset);
  camera.updateMatrixWorld();
};
