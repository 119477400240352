import { Suspense, useEffect } from 'react';
import {
  Selection,
  EffectComposer,
  Outline
} from '@react-three/postprocessing';
import { Vector3, Mesh, Box3, Group, Euler, Matrix4 } from 'three';
import { BlendFunction, Resolution, KernelSize } from 'postprocessing';

import { globalStore } from '../store/globalStore';
import { sceneStore } from '../store/sceneStore';
import { RoomSettings, SceneNodeItems } from '../types/scene.types';
import { SceneNodeType } from '../types/scene.types';

import { Lights } from './Lights';
import { Floor } from './Floor';
import { Ceiling } from './Ceiling';
import { Walls } from './components/Walls';
import { SceneCameras } from './components/SceneCameras';
import { RoomModels } from './components/RoomModels';
import { Preview } from './components/Preview';
import { TestObject } from './components/TestObject';

import { Materials } from './helpers/Materials/Materials';

import {
  objectCollideWithRoom,
  boxCollideWithRoom
} from '../utils/collideWithRoom';

const rotateAroundPoint = (
  position: Vector3,
  rotation: Euler,
  point: Vector3
) => {
  position.sub(point);
  position.applyEuler(rotation);
  position.add(point);
  return position;
};

function collisionCheck(
  object: Mesh | Group | Box3,
  originalPosition: Vector3,
  moveTowards: Vector3,
  roomSettings: RoomSettings
) {
  const prevPosition = originalPosition;
  const steps = 5;
  const moveTowardsDir = moveTowards.clone();

  let collided = false;
  moveTowardsDir.z = originalPosition.z;

  const collisionCheckResult =
    object instanceof Box3
      ? (position: Vector3) =>
          boxCollideWithRoom(object, roomSettings, position, undefined)
      : (position: Vector3) =>
          objectCollideWithRoom(object, roomSettings, position);

  if (collisionCheckResult === undefined) return new Vector3();

  for (let i = 0; i < steps; i++) {
    const newPosition = new Vector3().lerpVectors(
      originalPosition,
      moveTowardsDir,
      (i + 1) * (1 / steps)
    );

    collided = collisionCheckResult(newPosition);

    if (collided) break;

    prevPosition.x = newPosition.x;
  }

  moveTowardsDir.z = moveTowards.z;
  moveTowardsDir.x = originalPosition.x;

  for (let i = 0; i < steps; i++) {
    const newPosition = new Vector3().lerpVectors(
      originalPosition,
      moveTowardsDir,
      (i + 1) * (1 / steps)
    );

    collided = collisionCheckResult(newPosition);

    if (collided) break;

    prevPosition.z = newPosition.z;
  }

  return prevPosition;
}

function collisionRotation(
  object: Mesh | Group | Box3,
  rotateBy: Euler,
  roomSettings: RoomSettings
) {
  const collided =
    object instanceof Box3
      ? boxCollideWithRoom(object, roomSettings, undefined, rotateBy)
      : objectCollideWithRoom(object, roomSettings, undefined, rotateBy);

  return collided ? new Euler() : rotateBy;
}

export function Scene() {
  const sceneMode = sceneStore.use.sceneMode();
  const moveTowards = sceneStore.use.draggedToPosition();
  const rotateBy = sceneStore.use.rotateBy();
  const object = sceneStore.use.draggedObject();

  useEffect(() => {
    const preview = sceneStore.get.techScope().preview;
    const roomSettings = sceneStore.get.roomSettings();
    const sceneMode = sceneStore.get.sceneMode();
    const previewInSlot = sceneStore.get.previewInSlot();
    const collisionEnabled = globalStore.get.collisionEnabled();
    if (preview && !previewInSlot) {
      let prevPosition = moveTowards.clone();
      if (preview.type !== SceneNodeType.CAMERA) {
        const position = preview.box?.getCenter(new Vector3()) || new Vector3();
        position.y = 0;
        const posDiff = preview.groupDif || new Vector3();
        posDiff.y = 0;

        if (sceneMode && collisionEnabled) {
          if (preview.box == undefined) {
            return;
          }
          const moveTo = moveTowards.clone().sub(posDiff);
          const box = new Box3().setFromCenterAndSize(
            preview.position.clone().sub(posDiff),
            preview.box.getSize(new Vector3())
          );
          const originalPosition = preview.position.clone().sub(posDiff);
          prevPosition = collisionCheck(
            box,
            originalPosition,
            moveTo,
            roomSettings
          );
          prevPosition = box.getCenter(new Vector3()).add(posDiff);
        }
      }

      sceneStore.set.updatePreview({
        position: prevPosition
      });
    }
  }, [moveTowards, object]);

  useEffect(() => {
    const preview = sceneStore.get.techScope().preview;
    const roomSettings = sceneStore.get.roomSettings();
    const selectedNode = sceneStore.get.selectedNode();
    const sectioningMode = sceneStore.get.sectioningMode();
    const object = sceneStore.get.draggedObject();
    const sceneMode = sceneStore.get.sceneMode();
    const collisionEnabled = globalStore.get.collisionEnabled();
    if (
      selectedNode?.type === SceneNodeType.CAMERA ||
      preview?.type === SceneNodeType.CAMERA
    )
      return;
    let objectToRotate = null;

    if (object !== null) objectToRotate = object;
    else if (preview !== null && preview.box !== undefined) {
      const mat4 = new Matrix4().makeRotationFromEuler(preview.rotation);

      const box = new Box3().setFromCenterAndSize(
        new Vector3(),
        preview.box.getSize(new Vector3())
      );
      box.applyMatrix4(mat4);
      box.setFromCenterAndSize(preview.position, box.getSize(new Vector3()));

      objectToRotate = box;
    }
    if (objectToRotate === null || objectToRotate === undefined) return;

    const newRotateBy = collisionEnabled
      ? collisionRotation(objectToRotate, rotateBy, roomSettings)
      : rotateBy;

    if (preview != null) {
      preview.rotation.set(
        preview.rotation.x,
        preview.rotation.y + newRotateBy.y,
        preview.rotation.z
      );

      sceneStore.set.updatePreview({});
    } else if (selectedNode) {
      if (selectedNode.type === SceneNodeType.SIMPLE) {
        const roomSceneItem = selectedNode;
        sceneStore.set.updateSceneNode(selectedNode, {
          ...selectedNode,
          rotation: new Euler(
            roomSceneItem.rotation.x,
            roomSceneItem.rotation.y + newRotateBy.y,
            roomSceneItem.rotation.z
          )
        });
      } else if (sceneMode === true && sectioningMode === false) {
        const roomSceneItem = selectedNode;
        const configureScene = roomSceneItem.model as SceneNodeItems;
        let centerPosition = null;
        for (const configureItem in configureScene) {
          const configureSceneItem = configureScene[configureItem];
          const rotation = new Euler(
            configureSceneItem.rotation.x,
            configureSceneItem.rotation.y + newRotateBy.y,
            configureSceneItem.rotation.z
          );
          if (centerPosition === null)
            centerPosition = configureSceneItem.position;
          const newPos = rotateAroundPoint(
            configureSceneItem.position.clone(),
            newRotateBy,
            centerPosition
          );
          sceneStore.set.updateSceneNode(configureScene[configureItem], {
            ...configureScene[configureItem],
            position: newPos,
            rotation: rotation
          });
        }
      } else {
        const configureScene = selectedNode;
        const previewInSlot = sceneStore.get.previewInSlot();
        if (configureScene != undefined && previewInSlot === false) {
          const roomSceneItem = selectedNode;
          sceneStore.set.updateSceneNode(selectedNode, {
            ...selectedNode,
            rotation: new Euler(
              roomSceneItem.rotation.x,
              roomSceneItem.rotation.y + newRotateBy.y,
              roomSceneItem.rotation.z
            )
          });
        }
      }
    }
  }, [rotateBy]);

  return (
    <group dispose={null}>
      <Suspense>
        <Materials />
      </Suspense>

      <Suspense>
        <Lights />
      </Suspense>

      {sceneMode && (
        <Suspense>
          <Walls />
        </Suspense>
      )}

      <Suspense>
        <Floor />
      </Suspense>

      <Suspense>
        <SceneCameras />
      </Suspense>

      {sceneMode && <Ceiling />}

      <Selection>
        <EffectComposer multisampling={4} autoClear={false}>
          <Outline
            blur
            blendFunction={BlendFunction.ALPHA}
            visibleEdgeColor={0x000000}
            hiddenEdgeColor={0x000000}
            edgeStrength={8}
            width={Resolution.AUTO_SIZE}
            height={Resolution.AUTO_SIZE}
            kernelSize={KernelSize.VERY_SMALL}
          />
        </EffectComposer>
        <Suspense>
          <RoomModels />
        </Suspense>
        <Suspense>
          <Preview />
        </Suspense>
      </Selection>

      <Suspense>
        <TestObject />
      </Suspense>
    </group>
  );
}
