import { ModelBind, Settings } from '../types/settings.types';

import { mockHarmony } from './models/mockHarmony';
import { mockDecorations } from './models/mockDecorations';
import { mockHarmonyTextures } from './textures/mockHarmonyTextures';
import { mockRoomTextures } from './textures/mockRoomTextures';
import { mockDecorationsTextures } from './textures/mockDecorationsTextures';

export const harmonySettings: Settings = {
  info: {
    preview: '/assets/models/harmony/preview/harmony.jpg',
    previewLarge: '/assets/models/harmony/preview/harmony_large.jpg',
    brand: 'West Elm',
    name: 'Harmony',
    sku: '60849083',
    nda: true,
    style: 'Modern',
    category: 'Sectional sofas'
  },

  models: {
    assets: { ...mockDecorations, ...mockHarmony },

    bind: {
      [ModelBind.MODEL]: Object.keys(mockHarmony),
      [ModelBind.DECORATIONS]: Object.keys(mockDecorations)
    }
  },

  materials: {
    assets: {
      ...mockHarmonyTextures,
      ...mockDecorationsTextures,
      ...mockRoomTextures
    },

    bind: {
      cloth: [
        {
          name: 'Chenill-Tweeted-Pewter'
        },
        {
          name: 'Chenill-Tweeted-Frost-Gray'
        },
        {
          name: 'Performance-Basketweawe-Camel'
        },
        {
          name: 'Blue-fabric'
        }
      ],
      legs: [
        {
          name: 'wood'
        },
        {
          name: 'dark-metal'
        },
        {
          name: 'light-metal'
        }
      ],
      fabric: [
        {
          name: 'Fabric-Knit-Brushed-Grey'
        },
        {
          name: 'Fabric-Canvas-Weave'
        },
        {
          name: 'Fabric-Hessian-Blue'
        }
      ],
      wood: [
        {
          name: 'Wood-Birch-European'
        },
        {
          name: 'Wood-Cherry'
        },
        {
          name: 'Wood-Chestnut-Sweet'
        }
      ],
      'p-legs': [
        { name: 'Wood-python-legs' },
        { name: 'Black-python-legs' },
        { name: 'Silver-python-legs' },
        { name: 'Golden-python-legs' }
      ],
      'p-cloth': [
        { name: 'Fabric-Cream' },
        { name: 'Fabric-Grey' },
        { name: 'Fabric-Light' },
        { name: 'Velur-Blue' },
        { name: 'Velur-Olive' }
      ]
    }
  },

  naming: {
    prefix: {
      model: 'model',
      slots: 'slots',
      childs: 'childs'
    },

    separator: {
      empty: '#',
      group: '_',
      points: '+'
    }
  }
};
