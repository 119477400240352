import { useCallback, useRef } from 'react';
import { Vector2, Vector3 } from 'three';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { OrbitControls } from 'three-stdlib';

import { globalStore } from '../store/globalStore';

type HandlePointerEvent = (event: ThreeEvent<PointerEvent>) => void;

export type HandleDraggableEvent = (state: {
  event: ThreeEvent<PointerEvent>;
  planeIntersectPoint: Vector3;
}) => void;

export interface DraggableOptions {
  onPointerDown?: HandleDraggableEvent;
  onPointerMove?: HandleDraggableEvent;
  onPointerUp?: HandleDraggableEvent;
  onDragStart?: HandleDraggableEvent;
  onClick?: HandlePointerEvent;
  durationLimit?: number;
  distanceLimit?: number;
}

const maxDuration = 180;
const maxDistance = 16;

export function useDraggable({
  onPointerDown,
  onPointerMove,
  onPointerUp,
  onDragStart,
  onClick,
  durationLimit = maxDuration,
  distanceLimit = maxDistance
}: DraggableOptions) {
  const orbitControls = useThree((state) => state.controls) as OrbitControls;

  const plane = globalStore.use.plane();

  const planeIntersectPoint = useRef(new Vector3());
  const isClicked = useRef(false);
  const isDragged = useRef(false);
  const startTime = useRef(0);
  const startMouse = useRef(new Vector2());

  const handlePointerDown = useCallback<HandlePointerEvent>(
    (event) => {
      event.stopPropagation();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const noTypedEvent = event as any;
      noTypedEvent.target.setPointerCapture(noTypedEvent.pointerId);
      orbitControls.enableRotate = false;

      plane.set(new Vector3(0, 1, 0), 0);
      plane.translate(new Vector3(0, event.point.y, 0));

      event.ray.intersectPlane(plane, planeIntersectPoint.current);

      onPointerDown?.({
        event,
        planeIntersectPoint: planeIntersectPoint.current
      });

      isClicked.current = true;

      startTime.current = performance.now();
      startMouse.current = new Vector2(
        event.nativeEvent.clientX,
        event.nativeEvent.clientY
      );
    },
    [orbitControls, plane, onPointerDown]
  );

  const handlePointerMove = useCallback<HandlePointerEvent>(
    (event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const noTypedEvent = event as any;
      if (
        !isClicked.current ||
        !noTypedEvent.target.hasPointerCapture(noTypedEvent.pointerId)
      )
        return;

      if (!isDragged.current) {
        const duration = performance.now() - startTime.current;

        const distane = new Vector2(
          event.nativeEvent.clientX,
          event.nativeEvent.clientY
        ).distanceTo(startMouse.current);

        isDragged.current = duration > durationLimit || distane > distanceLimit;

        // Drag start
        isDragged.current &&
          onDragStart?.({
            event,
            planeIntersectPoint: planeIntersectPoint.current
          });
      }

      // Drag move
      if (isDragged.current) {
        event.ray.intersectPlane(plane, planeIntersectPoint.current);
        onPointerMove?.({
          event,
          planeIntersectPoint: planeIntersectPoint.current
        });
      }
    },
    [plane, onDragStart, onPointerMove, durationLimit, distanceLimit]
  );

  const handlePointerUp = useCallback<HandlePointerEvent>(
    (event) => {
      event.stopPropagation();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const noTypedEvent = event as any;

      if (!noTypedEvent.target.hasPointerCapture(noTypedEvent.pointerId))
        return;

      noTypedEvent.target.releasePointerCapture(noTypedEvent.pointerId);

      isClicked.current = false;

      setTimeout(() => {
        orbitControls.enableRotate = true;
      }, 0);

      if (!isDragged.current) {
        onClick?.(event);
        return;
      }

      isDragged.current = false;

      event.ray.intersectPlane(plane, planeIntersectPoint.current);

      // Drag end
      onPointerUp?.({
        event,
        planeIntersectPoint: planeIntersectPoint.current
      });
    },
    [orbitControls, plane, onClick, onPointerUp]
  );

  const handlePointerCancel = useCallback(() => {
    isClicked.current = false;
    if (orbitControls) orbitControls.enableRotate = true;
    isDragged.current = false;
  }, []);

  return {
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handlePointerCancel
  };
}
