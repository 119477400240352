import React, { Fragment } from 'react';
import map from 'lodash/map';

import { Materials } from '../../../../../../types/scene.types';
import { SettingsMaterials } from '../../../../../../types/settings.types';

import { ProductSidebarTexture } from '../../../ProductSidebarTexture';

interface PageMenuChangeMaterialsContentProps {
  materials: Materials;
  settingsMaterials: SettingsMaterials;
}

function PageMenuChangeMaterialsContent({
  materials,
  settingsMaterials
}: PageMenuChangeMaterialsContentProps) {
  return (
    <div className="p-4 space-y-4" data-toggle-item="add-section">
      <div className="space-y-4">
        {map(materials, (materialItem, group) => (
          <Fragment key={group}>
            <div className="uppercase text-gray-500">{group}</div>
            <div className="grid grid-cols-2 gap-2 justify-center">
              {map(settingsMaterials.bind[group], (item, i) => (
                <ProductSidebarTexture
                  key={i}
                  materialItem={item}
                  group={group}
                  materialName={settingsMaterials.assets[item.name]?.name}
                  currentMaterialItem={materialItem}
                  materialImage={
                    settingsMaterials.assets[item.name]?.previewImage
                  }
                />
              ))}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default PageMenuChangeMaterialsContent;
