import { useEffect, useMemo } from 'react';
import { useGLTF } from '@react-three/drei';
import { Object3D, Texture } from 'three';
import each from 'lodash/each';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import values from 'lodash/values';

import {
  MaterialsLib,
  MaterialType,
  SceneNodeItems,
  SceneNodeType
} from '../../../../types/scene.types';

import { sceneStore } from '../../../../store/sceneStore';
import { globalStore } from '../../../../store/globalStore';

import { parseGLTFNodes } from '../../../../utils/parseGLTFNodes';
import { parseMeshName } from '../../../../utils/parseMeshName';

interface AtalsMaterialsProps {
  mats: Record<string, Record<string, string>>;
}

export function AtalsMaterials({ mats }: AtalsMaterialsProps) {
  const settings = globalStore.use.settings();
  const scene = sceneStore.use.scene();
  const preview = sceneStore.use.techScope().preview;

  if (!settings) return null;

  const usedModels = useMemo(() => {
    const models = [] as string[];

    const items: SceneNodeItems[] = values(scene);
    preview && items.push({ preview } as SceneNodeItems);

    let i = 0;

    while (i < items.length) {
      const group = items[i];

      each(group, (item) => {
        if (!item) return;

        if ('type' in item && item.type === SceneNodeType.GROUP) {
          items.push(item.model);
          return;
        }

        const { scene } = useGLTF(item.model as string);

        const { model } = parseGLTFNodes(settings, scene);

        model?.traverse((object) => {
          if (!(object instanceof Object3D)) return null;

          models.push(object.name);
        });
      });

      ++i;
    }

    return models;
  }, [scene]);

  const usedAtlasMaterialsLib = useMemo(() => {
    const materialsLib: MaterialsLib = {};

    each(mats, (atlasMaterials, group) => {
      each(usedModels, (objectName) => {
        const { materialGroup, meshName } = parseMeshName(settings, objectName);

        const meshMaterial = atlasMaterials?.[meshName];

        if (group === materialGroup && meshMaterial) {
          const { materials } = useGLTF(meshMaterial);
          each(materials, (material) => {
            material.userData.fileUrl = meshMaterial;
            material.userData.materialType = MaterialType.ATLAS;
          });
          Object.assign(materialsLib, materials);
        }
      });
    });

    return materialsLib;
  }, [mats, settings, usedModels]);

  useEffect(() => {
    each(usedAtlasMaterialsLib, (material) => {
      if (!material.name) return;

      const materialsLib = sceneStore.get.materialsLib();

      if (material.uuid === materialsLib[material.name]?.uuid) return;

      sceneStore.set.updateMaterialLib(material.name, material);
    });
  }, [usedAtlasMaterialsLib]);

  useEffect(() => {
    const materialsValues = keys(usedAtlasMaterialsLib);
    const materialsLib = sceneStore.get.materialsLib();

    each(materialsLib, (material, key) => {
      if (
        material.userData.materialType !== MaterialType.ATLAS ||
        includes(materialsValues, key)
      )
        return;

      sceneStore.set.updateMaterialLib(key, undefined);

      each(material, (value) => {
        if (value instanceof Texture) {
          value.source.data instanceof ImageBitmap && value.source.data.close();
          value.dispose();
        }
      });

      material.dispose();
      useGLTF.clear(material.userData.fileUrl);
    });
  }, [usedAtlasMaterialsLib]);

  return null;
}
