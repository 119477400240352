import { ReactNode } from 'react';

interface ConfiguratorPageSidebarProps {
  children: ReactNode;
}

function ConfiguratorPageSidebar({ children }: ConfiguratorPageSidebarProps) {
  return (
    <div className="w-60 xl:w-72 flex flex-col border-l dark:border-gray-700 divide-y dark:divide-gray-700 pb-4">
      {children}
    </div>
  );
}

export default ConfiguratorPageSidebar;
