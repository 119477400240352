import { Brush, Subtraction } from '@react-three/csg';
import { Clone, useGLTF } from '@react-three/drei';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box3, Matrix4, Vector2, Vector3 } from 'three';

import { globalStore } from '../../../../store/globalStore';
import { sceneStore } from '../../../../store/sceneStore';
import { useBoxObject } from '../../../../hooks/useBoxObject';

import { lineSideByPoint } from '../../../../utils/math/lineSideByPoint';
import { getLineSkewAngle } from '../../../../utils/math/getLineSkewAngle';
import { profiledContourUV } from '../../../../utils/threejs/profiledContourUV';
import { applyBoxUV } from '../../../../utils/threejs/applyBoxUV';
import { ThreeEvent } from '@react-three/fiber';

interface WallProps {
  id: number;
  points: Vector2[];
  profileShape: number[];
  cameraX: number;
  cameraZ: number;
}

const frameShift = 0.01;
const windowPosYShift = 0.25;
const wallIndexWithDoor = 0;
const wallIndexWithWindow = 3;

export function Wall({
  id,
  points,
  profileShape,
  cameraX,
  cameraZ
}: WallProps) {
  const arrayPoints = useRef<Vector2[]>([]);

  const enableDoor = globalStore.use.enableDoor();
  const enableWindow = globalStore.use.enableWindow();

  const geometry = useMemo(() => {
    arrayPoints.current = [
      points[id],
      points[(id + 1) % points.length],
      points[(id + 2) % points.length],
      points[(id + 3) % points.length]
    ];

    return profiledContourUV(
      profileShape,
      [
        points[id].x,
        points[id].y,
        points[(id + 1) % points.length].x,
        points[(id + 1) % points.length].y,
        points[(id + 2) % points.length].x,
        points[(id + 2) % points.length].y,
        points[(id + 3) % points.length].x,
        points[(id + 3) % points.length].y
      ],
      true,
      false,
      false,
      1,
      1
    ).rotateX(Math.PI / 2);
  }, [profileShape, points]);

  const materialsLib = sceneStore.use.materialsLib();
  const { materials } = sceneStore.use.roomSettings();

  const point = useRef(new Vector2());
  const sceneCenter = useRef(new Vector3());
  const sceneCenter2D = useRef(new Vector2());
  sceneCenter2D.current.set(sceneCenter.current.x, sceneCenter.current.z);
  point.current.set(cameraX, cameraZ);

  const sceneCenterSide = lineSideByPoint(
    [arrayPoints.current[1], arrayPoints.current[2]],
    sceneCenter2D.current
  );

  const cameraSide = lineSideByPoint(
    [arrayPoints.current[1], arrayPoints.current[2]],
    point.current
  );

  const { scene: doorScene } = useGLTF('/assets/models/room/door.glb');
  const { scene: windowScene } = useGLTF('/assets/models/room/window.glb');

  const wallAngle =
    getLineSkewAngle(arrayPoints.current[1], arrayPoints.current[2]) * -1;

  const { center: doorCenter, size: doorSize } = useBoxObject(doorScene);
  const { center: windowCenter, size: windowSize } = useBoxObject(windowScene);

  const {
    center: wallCenter,
    size: wallSize,
    box: box
  } = useMemo(() => {
    geometry.rotateY(-wallAngle % Math.PI);
    geometry.computeBoundingBox();

    const box = geometry.boundingBox || new Box3();
    const size = box.getSize(new Vector3());

    applyBoxUV(
      geometry,
      new Matrix4().makeRotationY(-wallAngle % Math.PI),
      globalStore.get.realWorldUVScale()
    );

    geometry.attributes.uv.needsUpdate = true;

    geometry.rotateY(wallAngle % Math.PI);

    return {
      box,
      size,
      center: box.getCenter(new Vector3())
    };
  }, [geometry, wallAngle]);

  const handlePoinverEnter = useCallback<
    (event: ThreeEvent<PointerEvent>) => void
  >((event) => {
    event.stopPropagation();
    sceneStore.set.previewInSlot(true);

    setTimeout(() => {
      sceneStore.set.previewInSlot(true);

      // const draggedId = sceneStore.get.draggedId();

      // let item = s

      // const item =

      //   const id = (item as ConfigureSceneNode).id;

      //   id
      //     ? sceneStore.set.updateConfigureSceneItem(id, {
      //         position: modelPosition,
      //         rotation: modelRotation
      //       })
      //     : sceneStore.set.updatePreview({
      //         position: modelPosition,
      //         rotation: modelRotation
      //       });
    }, 0);
  }, []);

  const handlePoinverLeave = useCallback<
    (event: ThreeEvent<PointerEvent>) => void
  >((event) => {
    event.stopPropagation();

    sceneStore.set.previewInSlot(false);
  }, []);

  const handlePointerUp = useCallback<
    (event: ThreeEvent<PointerEvent>) => void
  >((event) => {
    event.stopPropagation();

    // const id = (item as ConfigureSceneNode).id;

    // if (item) {
    //   itemSlotPoint.node = parent;
    //   itemSlot.point = itemSlotPoint;

    //   const node = id
    //     ? (item as ConfigureSceneNode)
    //     : sceneStore.set.addToConfigureScene(item);
    //   parentSlotPoint.node = node;
    //   parentSlot.point = parentSlotPoint;
    // }

    // sceneStore.set.updatePreview(null);

    // setTimeout(() => {
    //   sceneStore.set.previewInSlot(false);
    // }, 0);
  }, []);

  // const gl = useThree(({ gl }) => gl);

  // useEffect(() => {
  //   const handleGLPointerUp = () => {
  //     sceneStore.set.previewInSlot(false);
  //   };

  //   gl.domElement.addEventListener('pointerup', handleGLPointerUp);

  //   return () => {
  //     gl.domElement.removeEventListener('pointerup', handleGLPointerUp);
  //   };
  // }, []);

  // const wall = sceneStore.useStore(({ scene }) => scene[`wall${id}`]);

  useEffect(() => {
    sceneStore.set.addBoxesToRoomSettings(box);
  }, [box]);

  if (!materialsLib[materials.walls.name]) return null;

  return (
    <group visible={cameraSide === sceneCenterSide}>
      <mesh>
        {(enableDoor && id === wallIndexWithDoor) ||
        (enableWindow && id === wallIndexWithWindow) ? (
          <Subtraction>
            <Brush a geometry={geometry} />
            {enableDoor && id === wallIndexWithDoor && (
              <Brush
                b
                position-x={wallCenter.x + doorCenter.x}
                position-y={doorCenter.y - frameShift - 0.04}
                position-z={wallCenter.z + doorCenter.z}
                rotation-y={wallAngle}
              >
                <boxGeometry
                  args={[
                    doorSize.x - frameShift,
                    doorSize.y - frameShift,
                    Math.abs(wallSize.z) + 0.1
                  ]}
                />
              </Brush>
            )}
            {enableWindow && id === wallIndexWithWindow && (
              <Brush
                b
                position-x={wallCenter.x}
                position-y={windowSize.y / 2 + windowPosYShift}
                position-z={wallCenter.z}
                rotation-y={wallAngle}
              >
                <boxGeometry
                  args={[
                    windowSize.x - frameShift,
                    windowSize.y - frameShift,
                    Math.abs(windowSize.z) + 0.1
                  ]}
                />
              </Brush>
            )}
          </Subtraction>
        ) : (
          <primitive object={geometry} attach="geometry" />
        )}

        <primitive
          object={materialsLib[materials.walls.name]}
          attach="material"
        />
      </mesh>
      {enableDoor && id === wallIndexWithDoor && (
        <Clone
          position-x={wallCenter.x + doorCenter.x - 0.0475}
          position-y={-0.04}
          position-z={wallCenter.z + doorCenter.z}
          rotation-y={wallAngle}
          object={doorScene}
          castShadow={false}
          receiveShadow
        />
      )}
      {enableWindow && id === wallIndexWithWindow && (
        <Clone
          position-x={wallCenter.x}
          position-y={-windowCenter.y + windowSize.y / 2 + windowPosYShift}
          position-z={wallCenter.z}
          rotation-y={wallAngle}
          object={windowScene}
          castShadow={false}
          receiveShadow
        />
      )}
    </group>
  );
}
