import { createStore } from '@udecode/zustood';
import { Vector3 } from 'three';

interface DNDStorePosition {
  x: number;
  y: number;
}

export interface DNDStore {
  startPosition: DNDStorePosition;
  position: DNDStorePosition;
  shift: DNDStorePosition;
  active: boolean;
  visibility: boolean;
  id: string | null;
  modelShift: Vector3;
}

const defaultDndStoreState: DNDStore = {
  startPosition: {
    x: 0,
    y: 0
  },
  position: {
    x: 0,
    y: 0
  },
  shift: {
    x: 0,
    y: 0
  },
  active: false,
  visibility: true,
  id: null,
  modelShift: new Vector3()
};

export const dndStore = createStore('DNDStore')(
  defaultDndStoreState
).extendActions((set, get) => ({
  setStartPosition: (startPosition: DNDStorePosition) => {
    set.startPosition(startPosition);
    set.shift({
      x: get.position().x - startPosition.x,
      y: get.position().y - startPosition.y
    });
  },
  setPosition: (position: DNDStorePosition) => {
    set.position(position);
    set.shift({
      x: position.x - get.startPosition().x,
      y: position.y - get.startPosition().y
    });
  }
}));
