import { Link } from 'react-router-dom';

import { IconsEnum } from '../../../../assets/icons/types';

import { Icon } from '../../../../helpers/Icon';
import { Translate } from '../../../../helpers/Translate';

import { words } from '../../../../locales/keys';

function ProductsIndexPageSubHeader() {
  return (
    <div className="flex items-center sm:items-start lg:py-2 flex-col sm:flex-row px-4">
      <div className="flex items-center h-10 w-full">
        <div className="flex-1 truncate flex pl-3 pr-1 h-full items-center text-base xl:text-lg text-gray-500 space-x-1.5">
          <Link to="." className="hover:underline">
            <Icon icon={IconsEnum.CUBE_OUTLINE} className="h-6 w-6" />
          </Link>
          <span>/</span>
          <Link
            to="."
            className="hover:underline font-medium text-black dark:text-white"
          >
            <Translate id={words.products} />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProductsIndexPageSubHeader;
