export function loadFile(accept = 'application/json') {
  return new Promise<string | ArrayBuffer | null | undefined>(
    (resolve, reject) => {
      const element = document.createElement('input');

      element.setAttribute('type', 'file');
      element.setAttribute('accept', accept);
      element.style.display = 'none';

      element.addEventListener('change', (e: Event) => {
        const target = e.target as HTMLInputElement | null;
        if (!target) {
          reject();
          return;
        }

        const file = target.files?.[0];
        if (!file) {
          reject();
          return;
        }

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          resolve(e.target?.result);
        };
        reader.readAsText(file);
      });

      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  );
}
export function loadFilePath(accept = 'application/json') {
  return new Promise<string | null>((resolve, reject) => {
    const element = document.createElement('input');
    element.setAttribute('type', 'file');
    element.setAttribute('accept', accept);
    element.style.display = 'none';
    element.addEventListener('change', (e: Event) => {
      const target = e.target as HTMLInputElement | null;
      if (!target) {
        reject();
        return;
      }
      const file = target.files?.[0];
      if (!target.value) {
        reject();
        return;
      }
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result != null && e.target?.result != undefined) {
          const url = URL.createObjectURL(file as Blob);
          resolve(url);
        }
      };
      reader.readAsBinaryString(file as Blob);
    });
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  });
}
