import { useRef } from 'react';
import { DirectionalLight, DirectionalLightHelper, CameraHelper } from 'three';
import { Environment, softShadows, useHelper } from '@react-three/drei';
import { useControls } from 'leva';

softShadows({
  frustum: 5.75,
  size: 0.003,
  near: 9.5,
  samples: 20,
  rings: 11
});

export function Lights() {
  const { enableLightHelpers } = useControls('lightHelpers', {
    enableLightHelpers: false
  });

  const directionalLight = useRef<DirectionalLight>(null);

  useHelper(
    enableLightHelpers && directionalLight,
    DirectionalLightHelper,
    1,
    'black'
  );

  const dirLight1Camera = useRef(directionalLight.current?.shadow.camera);
  dirLight1Camera.current = directionalLight.current?.shadow.camera;

  useHelper(enableLightHelpers && dirLight1Camera, CameraHelper);

  return (
    <group dispose={null}>
      <Environment files="/assets/hdri/photo_studio_01_1k.hdr" />

      <directionalLight
        ref={directionalLight}
        position={[2, 6, 3]}
        shadow-camera-near={0.01}
        shadow-camera-far={30}
        shadow-camera-left={-50}
        shadow-camera-right={50}
        shadow-camera-top={50}
        shadow-camera-bottom={-50}
        shadow-mapSize={[2048, 2048]}
        shadow-bias={-0.005}
        castShadow
      />
    </group>
  );
}
