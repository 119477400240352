import { memo } from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../assets/icons/types';

import { PureTooltipIconButtonHelper } from '../../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../../helpers/tooltips/tooltipsConstants';

import { words } from '../../../../../../locales/keys';

interface PrimaryMenuKeepSecondaryButtonProps {
  isSecondaryMenuKeepOpen: boolean;
  toggleKeepSecondaryMenu: () => void;
}

function PrimaryMenuKeepSecondaryButton({
  isSecondaryMenuKeepOpen,
  toggleKeepSecondaryMenu
}: PrimaryMenuKeepSecondaryButtonProps) {
  return (
    <div className="hidden xl:block">
      <PureTooltipIconButtonHelper
        className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
        tooltipI18nText={isSecondaryMenuKeepOpen ? words.hide : words.keepOpen}
        tooltipPlacement={TooltipPlacement.TOP}
        icon={
          isSecondaryMenuKeepOpen ? IconsEnum.ARROW_LEFT : IconsEnum.ARROW_LEFT
        }
        iconClassName={cl(
          'h-6 w-6',
          isSecondaryMenuKeepOpen ? null : 'transform rotate-180'
        )}
        onClick={toggleKeepSecondaryMenu}
      />
    </div>
  );
}

export default memo<PrimaryMenuKeepSecondaryButtonProps>(
  PrimaryMenuKeepSecondaryButton
);
