import { WordsType } from '../keys';

const words: WordsType = {
  addDecoration: 'Add Decoration',
  addSection: 'Add section',
  changeMaterials: 'Change materials',
  components: 'Components',
  configure: 'Configure',
  configureShape: 'Configure shape',
  depth: 'Depth',
  editProduct: 'Edit product',
  height: 'Height',
  hide: 'Hide menu',
  interiorConfigurator: 'Interior Configurator',
  keepOpen: 'Keep menu open',
  nda: 'NDA',
  productDetails: 'Product details',
  products: 'Products',
  resizeRoom: 'Resize room',
  textures: 'Textures',
  walls: 'Walls',
  width: 'Width'
};

export default words;
