import { SlotItemPointAvailable } from '../types/scene.types';
import { Settings } from '../types/settings.types';

const defaultPointName = 'default';

export function preparePoint(
  settings: Settings,
  pointName: string
): SlotItemPointAvailable {
  const parsedPoint = pointName.split(settings.naming.separator.group);

  return {
    sideName: parsedPoint[0],
    pointName: parsedPoint[1] || defaultPointName
  };
}
