import {
  BoxHelper,
  Ray,
  Box3,
  Scene,
  Mesh,
  Vector3,
  Group,
  Box3Helper,
  ArrowHelper,
  Euler,
  Matrix4
} from 'three';

import { RoomSettings } from '../types/scene.types';

let boxHelperObject: BoxHelper;
let box3HelperObject: Box3Helper;
let arrowHelper: ArrowHelper;
let box3HelperObjects: Box3Helper[] = [];

const prevPosition = new Vector3();
const collisionBox = new Box3();
const raycaster = new Ray();

export function objectCollideWithRoom(
  mesh: Mesh | Group,
  roomSettings: RoomSettings,
  position?: Vector3 | undefined,
  rotation?: Euler | undefined,
  scene?: Scene | undefined
) {
  prevPosition.copy(mesh.position);
  const box = new Box3(new Vector3(), new Vector3());
  if (position) {
    mesh.position.copy(position);
  }
  box.setFromObject(mesh);
  if (rotation) {
    const center = box.getCenter(new Vector3());
    box.setFromCenterAndSize(new Vector3(), box.getSize(new Vector3()));
    const mat4 = new Matrix4().makeRotationFromEuler(rotation);
    box.applyMatrix4(mat4);
    box.setFromCenterAndSize(center, box.getSize(new Vector3()));
  }

  mesh.position.copy(prevPosition);
  const collided = collisionCheck(box, roomSettings, scene);
  return collided;
}

export function boxCollideWithRoom(
  box: Box3,
  roomSettings: RoomSettings,
  position?: Vector3 | undefined,
  rotation?: Euler | undefined,
  scene?: Scene | undefined
) {
  collisionBox.copy(box);

  if (position != undefined) {
    collisionBox.setFromCenterAndSize(
      position,
      collisionBox.getSize(new Vector3())
    );
  }

  if (rotation != undefined) {
    const center = collisionBox.getCenter(new Vector3());
    collisionBox.setFromCenterAndSize(
      new Vector3(),
      collisionBox.getSize(new Vector3())
    );
    const mat4 = new Matrix4().makeRotationFromEuler(rotation);
    collisionBox.applyMatrix4(mat4);
    collisionBox.setFromCenterAndSize(
      center,
      collisionBox.getSize(new Vector3())
    );
  }

  const collided = collisionCheck(collisionBox, roomSettings, scene);
  if (!collided) box.copy(collisionBox);
  return collided;
}

function collisionCheck(
  box: Box3,
  roomSettings: RoomSettings,
  scene: Scene | undefined
) {
  let collided = false;
  let isOnScene = true;
  for (const wall in roomSettings.boxes) {
    if (roomSettings.boxes[wall].intersectsBox(box)) {
      collided = true;
      continue;
    }
  }
  const rayPos = box.getCenter(new Vector3());
  const dir = new Vector3(0, -1, 0);
  raycaster.set(rayPos, dir);
  if (raycaster.intersectBox(roomSettings.floor, new Vector3()) === null)
    isOnScene = false;
  if (scene) {
    if (box3HelperObject != null) {
      scene.remove(box3HelperObject);
    }
    box3HelperObject = new Box3Helper(box);
    scene.add(box3HelperObject);
    if (boxHelperObject != null) {
      scene.remove(boxHelperObject);
    }
    if (arrowHelper != null) {
      scene.remove(arrowHelper);
    }
    if (box3HelperObjects.length > 0) {
      for (let i = 0; i < box3HelperObjects.length; i++) {
        scene.remove(box3HelperObjects[i]);
      }
      box3HelperObjects = [];
    }
    for (const wall in roomSettings.boxes) {
      const box3Helper = new Box3Helper(roomSettings.boxes[wall]);
      box3HelperObjects.push(box3Helper);
      scene.add(box3Helper);
    }
    const box3Helper = new Box3Helper(roomSettings.floor);
    box3HelperObjects.push(box3Helper);
    scene.add(box3Helper);
    arrowHelper = new ArrowHelper(dir, rayPos);
    scene.add(arrowHelper);
  }
  return collided || !isOnScene;
}
