import { useCallback, useEffect, useRef } from 'react';
import { Group } from 'three';
import { Clone, useGLTF } from '@react-three/drei';
import { TransformControls } from 'three-stdlib';
import { useThree } from '@react-three/fiber';

import { CameraNode } from '../../../../types/scene.types';

import { sceneStore } from '../../../../store/sceneStore';
import { dndStore } from '../../../../store/dndStore';

import { useSelectable } from '../../../../hooks/useSelectable';
import { HandleDraggableEvent } from '../../../../hooks/useDraggable';

import { Draggable } from '../../../../models/helpers/Draggable';

interface CameraProps {
  node: CameraNode;
  transformControl: TransformControls;
}

export function SceneCamera({ node, transformControl }: CameraProps) {
  const cameraModel = useGLTF(node.model);
  const selectedNode = sceneStore.use.selectedNode();
  const mesh = useRef<Group>();
  const scene = useThree(({ scene }) => scene);
  const requestRender = useThree(({ invalidate }) => invalidate);
  const { handleClick } = useSelectable(node);

  const handlePointerDown = useCallback<HandleDraggableEvent>(
    ({ planeIntersectPoint }) => {
      dndStore.get
        .modelShift()
        .set(
          node?.position?.x || 0 - planeIntersectPoint.x,
          0,
          node?.position?.z || 0 - planeIntersectPoint.z
        );
    },
    [node?.position?.x, node?.position?.y]
  );

  const updatePosition = useCallback(() => {
    if (
      !selectedNode ||
      !selectedNode.position ||
      !selectedNode.rotation ||
      !mesh.current
    )
      return;
    selectedNode.position.copy(mesh.current.position);
    selectedNode.rotation.copy(mesh.current.rotation);
    requestRender();
  }, [mesh.current]);

  useEffect(() => {
    if (mesh.current !== undefined && node == selectedNode) {
      transformControl.setSpace('world');
      transformControl.attach(mesh.current);
      transformControl.updateMatrixWorld();
      scene.add(transformControl);
      transformControl.addEventListener('change', updatePosition);
      requestRender();
    }
    return () => {
      if (mesh.current !== undefined && node == selectedNode) {
        transformControl.detach();
        scene.remove(transformControl);
        transformControl.removeEventListener('change', updatePosition);
        requestRender();
      }
    };
  }, [selectedNode]);

  const handleDragStart = useCallback<HandleDraggableEvent>(() => {
    sceneStore.set.draggedNode(node);
    sceneStore.set.selectedNode(node);
  }, [node]);

  return (
    <Draggable
      onPointerDown={handlePointerDown}
      onDragStart={handleDragStart}
      onClick={handleClick}
    >
      {cameraModel && (
        <Clone
          ref={mesh as React.RefObject<Group>}
          position={node.position}
          rotation={node.rotation}
          object={cameraModel.scene}
          castShadow={false}
          receiveShadow
        />
      )}
    </Draggable>
  );
}
