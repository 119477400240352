import { useMemo, useState } from 'react';
import each from 'lodash/each';
import { Matrix4 } from 'three';
import { useFrame, useThree } from '@react-three/fiber';

import { sceneStore } from '../store/sceneStore';
import { globalStore } from '../store/globalStore';

import { profiledContourUV } from '../utils/threejs/profiledContourUV';
import { applyBoxUV } from '../utils/threejs/applyBoxUV';

export function Ceiling() {
  const { plan, wallHeight } = sceneStore.use.roomSettings();

  const profileShape = useMemo(() => {
    const shape: number[] = [];

    each(plan, (_, i) => {
      shape.push(plan[i].x, plan[i].y);
    });

    return shape;
  }, [plan]);

  const ceilingGeometry = useMemo(() => {
    const geometry = profiledContourUV(
      profileShape,
      [0.1, 0, 0, 0],
      true,
      false,
      false
    );

    applyBoxUV(geometry, new Matrix4(), globalStore.get.realWorldUVScale());

    geometry.attributes.uv.needsUpdate = true;

    return geometry;
  }, [profileShape]);

  const materialsLib = sceneStore.use.materialsLib();
  const { materials } = sceneStore.use.roomSettings();

  const camera = useThree(({ camera }) => camera);
  const [cameraY, setCameraY] = useState(camera.position.y);

  useFrame(({ camera }) => {
    setCameraY(camera.position.y);
  });

  return (
    <mesh
      receiveShadow
      position-y={wallHeight}
      rotation-z={Math.PI / 2}
      visible={cameraY < wallHeight}
    >
      <primitive object={ceilingGeometry} attach="geometry" />
      {materialsLib[materials.ceiling.name] && (
        <primitive
          object={materialsLib[materials.ceiling.name]}
          attach="material"
        />
      )}
    </mesh>
  );
}
