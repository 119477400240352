import { useCallback, useState } from 'react';
import cl from 'classnames';
import map from 'lodash/map';
import { IconsEnum } from '../../../../assets/icons/types';

import { sceneStore } from '../../../../store/sceneStore';
import { globalStore, MeasurementsSystem } from '../../../../store/globalStore';

import { useConfiguratorPageToolbar } from './hooks/useConfiguratorPageToolbar';

import { PureButtonHelper } from '../../../../helpers/buttons/PureButtonHelper';
import { PureTooltipIconButtonHelper } from '../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';
import {
  CameraOrientation,
  SceneNodeType
} from '../../../../types/scene.types';

function ConfiguratorPageToolbar() {
  const {
    handleToggleCollision,
    handleLoadGLB,
    handleRotate,
    handleCameraModeChange,
    handleSave,
    handleLoad,
    handleToggleDoor,
    handleToggleWindow,
    handleToggleMeasurements,
    handleToggleRoomMeasurements,
    toggleMeasurementsSystem,
    handleVideoCameraCreate,
    handleZoomIn,
    handleZoomOut,
    selectCameraOrientation,
    selectCamera
  } = useConfiguratorPageToolbar();
  const [isDropOpen, setOpen] = useState(false);
  const [dropItem, SetDropItem] = useState('Perspective');
  const cameras = sceneStore.useStore(({ cameras }) => cameras);

  const selectedNode = sceneStore.use.selectedNode();
  const sceneMode = sceneStore.use.sceneMode();
  const collisionEnabled = globalStore.use.collisionEnabled();
  const enableDoor = globalStore.use.enableDoor();
  const enableWindow = globalStore.use.enableWindow();
  const enableMeasurements = globalStore.use.enableMeasurements();
  const enableRoomMeasurements = globalStore.use.enableRoomMeasurements();
  const measurementsSystem = globalStore.use.measurementsSystem();

  const handleOpen = useCallback(() => {
    setOpen(!isDropOpen);
  }, [isDropOpen]);

  return (
    <div className="w-full top-0 absolute" style={{ zIndex: '1' }}>
      <div className="flex justify-between px-2 py-1.5">
        <div className="flex">
          <div className="flex gap-1 px-3">
            <PureTooltipIconButtonHelper
              className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
              icon={IconsEnum.VIDEO_CAMERA_SOLID}
              iconClassName="h-6 w-6 stroke-2"
              tooltipText="Upload GLB model"
              tooltipPlacement={TooltipPlacement.TOP}
              onClick={handleVideoCameraCreate}
              text={' Add camera'}
            />
          </div>
          <div
            className="relative flex items-center justify-center"
            data-option-trigger=""
          >
            <button
              type="button"
              onClick={handleOpen}
              className="py-1 pl-2 pr-2 rounded text-xs leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-gray-200 dark:bg-gray-800 text-black-500 dark:text-black-300 hover:bg-gray-250 dark:hover:bg-gray-700"
            >
              <span className="text-black">{dropItem}</span>
            </button>
            <div
              id="cameralist"
              className="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
              data-option-content=""
              data-popper-placement="bottom-start"
              style={{
                position: 'fixed',
                inset: '0px auto auto 0px',
                margin: '0px',
                transform: 'translate3d(110.4px, 116px, 0px)',
                maxHeight: '913.6px',
                display: isDropOpen ? 'block' : 'none'
              }}
            >
              <button
                className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                role="menuitem"
                onClick={() => {
                  SetDropItem('Perspective');
                  const perspective = sceneStore.get.perspectiveCamera();
                  if (perspective) selectCamera(perspective);
                }}
              >
                <span className="mr-auto">Perspective</span>
              </button>
              <hr className="border-gray-200 dark:border-gray-600 mx-2.5 my-1.5"></hr>
              <button
                className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                role="menuitem"
                onClick={() => {
                  SetDropItem('Top');
                  selectCameraOrientation(CameraOrientation.TOP);
                }}
              >
                <span className="mr-auto">Top</span>
              </button>
              <button
                className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                role="menuitem"
                onClick={() => {
                  SetDropItem('Front');
                  selectCameraOrientation(CameraOrientation.FRONT);
                }}
              >
                <span className="mr-auto">Front</span>
              </button>
              <button
                className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                role="menuitem"
                onClick={() => {
                  SetDropItem('Side');
                  selectCameraOrientation(CameraOrientation.SIDE);
                }}
              >
                <span className="mr-auto">Side</span>
              </button>
              {map(cameras, (o) => o.id).length > 0 && (
                <hr className="border-gray-200 dark:border-gray-600 mx-2.5 my-1.5"></hr>
              )}
              {map(cameras, (node, i) => (
                <button
                  key={i}
                  className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                  role="menuitem"
                  onClick={() => {
                    SetDropItem(
                      'Camera ' + (map(cameras, (o) => o.id).indexOf(i) + 1)
                    );
                    selectCamera(node);
                  }}
                >
                  <span className="mr-auto">
                    Camera {map(cameras, (o) => o.id).indexOf(i) + 1}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex">
          {sceneMode && (
            <div className="flex gap-1 px-3">
              <PureTooltipIconButtonHelper
                className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={IconsEnum.ARROW_UP_ON_SQUARE}
                iconClassName="h-6 w-6 stroke-2"
                tooltipText="Upload GLB model"
                tooltipPlacement={TooltipPlacement.TOP}
                onClick={handleLoadGLB}
              />

              <PureTooltipIconButtonHelper
                className={cl(
                  'py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0',
                  {
                    'hover:bg-gray-50': !collisionEnabled,
                    'bg-gray-400 hover:bg-gray-500': collisionEnabled
                  }
                )}
                icon={IconsEnum.CPU_CHIP}
                iconClassName="h-6 w-6"
                tooltipText={
                  collisionEnabled ? 'Disable collision' : 'Enable collision'
                }
                tooltipPlacement={TooltipPlacement.TOP}
                onClick={handleToggleCollision}
              />
            </div>
          )}
          {sceneMode && (
            <div className="flex gap-1 px-3">
              {selectedNode && selectedNode.type !== SceneNodeType.CAMERA ? (
                <PureTooltipIconButtonHelper
                  className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
                  icon={IconsEnum.REFRESH_OUTLINE}
                  iconClassName="h-6 w-6 stroke-2"
                  tooltipText="Rotate product"
                  tooltipPlacement={TooltipPlacement.TOP}
                  onClick={handleRotate}
                />
              ) : (
                <PureTooltipIconButtonHelper
                  className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
                  icon={IconsEnum.REFRESH_OUTLINE}
                  iconClassName="h-6 w-6 stroke-2"
                  tooltipText="Rotate product"
                  tooltipPlacement={TooltipPlacement.TOP}
                  onClick={handleCameraModeChange}
                />
              )}
            </div>
          )}
          {sceneMode && (
            <div className="flex gap-1 px-3">
              <PureTooltipIconButtonHelper
                className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={IconsEnum.DOCUMENT_ARROW_DOWN_OUTLINE}
                iconClassName="h-6 w-6 stroke-2"
                tooltipText="Save scene"
                tooltipPlacement={TooltipPlacement.TOP}
                onClick={handleSave}
              />
              <PureTooltipIconButtonHelper
                className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={IconsEnum.DOCUMENT_ARROW_UP_OUTLINE}
                iconClassName="h-6 w-6 stroke-2"
                tooltipText="Load scene"
                tooltipPlacement={TooltipPlacement.TOP}
                onClick={handleLoad}
              />
            </div>
          )}
          {sceneMode && (
            <div className="flex gap-1 px-3">
              <PureTooltipIconButtonHelper
                className={cl(
                  'py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0',
                  {
                    'hover:bg-gray-50': !enableDoor,
                    'bg-gray-400 hover:bg-gray-500': enableDoor
                  }
                )}
                icon={IconsEnum.DOOR_OUTLINE}
                iconClassName="h-6 w-6 stroke-2"
                tooltipText={enableDoor ? 'Remove door' : 'Add door'}
                tooltipPlacement={TooltipPlacement.TOP}
                onClick={handleToggleDoor}
              />
              <PureTooltipIconButtonHelper
                className={cl(
                  'py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0',
                  {
                    'hover:bg-gray-50': !enableWindow,
                    'bg-gray-400 hover:bg-gray-500': enableWindow
                  }
                )}
                icon={IconsEnum.WINDOW_OUTLINE}
                iconClassName="h-6 w-6 stroke-2"
                tooltipText={enableWindow ? 'Remove window' : 'Add window'}
                tooltipPlacement={TooltipPlacement.TOP}
                onClick={handleToggleWindow}
              />
            </div>
          )}
          <div className="flex gap-1 px-3">
            <PureTooltipIconButtonHelper
              className={cl(
                'py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0',
                {
                  'hover:bg-gray-50': !enableMeasurements,
                  'bg-gray-400 hover:bg-gray-500': enableMeasurements
                }
              )}
              icon={IconsEnum.RULER_SOLID}
              iconClassName="h-6 w-6"
              tooltipText={
                enableMeasurements ? 'Hide measurements' : 'Show measurements'
              }
              tooltipPlacement={TooltipPlacement.TOP}
              onClick={handleToggleMeasurements}
            />
            {sceneMode && (
              <PureTooltipIconButtonHelper
                className={cl(
                  'py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0',
                  {
                    'hover:bg-gray-50': !enableRoomMeasurements,
                    'bg-gray-400 hover:bg-gray-500': enableRoomMeasurements
                  }
                )}
                icon={IconsEnum.DISTANCE_SOLID}
                iconClassName="h-6 w-6"
                tooltipText={
                  enableRoomMeasurements ? 'Hide distances' : 'Show distances'
                }
                tooltipPlacement={TooltipPlacement.TOP}
                onClick={handleToggleRoomMeasurements}
              />
            )}
            <div className="flex -space-x-px rounded-md shadow-sm">
              <PureButtonHelper
                className="py-1 pl-1 pr-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0 min-w-8 justify-center"
                onClick={toggleMeasurementsSystem}
                text={
                  measurementsSystem === MeasurementsSystem.AMERICAN
                    ? 'in'
                    : 'cm'
                }
              />
            </div>
          </div>
          <div className="flex px-3 items-start">
            <div className="flex -space-x-px rounded-md shadow-sm">
              <PureTooltipIconButtonHelper
                className="py-1 pl-1 pr-1 rounded-md rounded-r-none bg-gray-200 dark:bg-gray-700 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={IconsEnum.PLUS_OUTLINE}
                iconClassName="h-6 w-6 stroke-2"
                tooltipText="Zoom in"
                tooltipPlacement={TooltipPlacement.TOP}
                onClick={handleZoomIn}
              />
              <PureTooltipIconButtonHelper
                className="py-1 pl-1 pr-1 rounded-md rounded-l-none bg-gray-200 dark:bg-gray-700 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-offset-0"
                icon={IconsEnum.MINUS_OUTLINE}
                iconClassName="h-6 w-6 stroke-2"
                tooltipText="Zoom out"
                tooltipPlacement={TooltipPlacement.TOP}
                onClick={handleZoomOut}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfiguratorPageToolbar;
