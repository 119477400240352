import cl from 'classnames';
import map from 'lodash/map';

import { ConfiguratorTabs } from '../../roomsConstants';
import { RouterLinkHelper } from '../../../../helpers/links/RouterLinkHelper';
import { ConfiguratorPath } from '../../ConfiguratorPath';

interface ConfiguratorPageTabsProps {
  active: ConfiguratorTabs;
}

const tabs = [
  {
    type: ConfiguratorTabs.PRODUCT_EDITOR,
    name: '1. Product editor',
    link: ConfiguratorPath.productEditor()
  },
  {
    type: ConfiguratorTabs.ROOM_PLANNER,
    name: '2. Room planner',
    link: ConfiguratorPath.roomPlanner()
  },
  {
    type: ConfiguratorTabs.ROOM_DESIGNER,
    name: '3. Room designer',
    link: ConfiguratorPath.roomDesigner()
  },
  {
    type: ConfiguratorTabs.SHOPPING_LIST,
    name: '4. Shopping list',
    link: ConfiguratorPath.shoppingList()
  }
];

function ConfiguratorPageTabs({ active }: ConfiguratorPageTabsProps) {
  return (
    <div className="flex">
      {map(tabs, (item, i) => (
        <RouterLinkHelper
          key={i}
          className={cl(
            'leading-4 py-1.5 px-4 focus:ring-none -mb-px border',
            active === item.type
              ? 'bg-white border-b-white border-gray-300 dark:bg-gray-950 dark:border-b-gray-950 dark:border-gray-700 dark:text-white text-black'
              : 'border-transparent dark:hover:bg-gray-700 dark:hover:border-b-gray-700 dark:hover:text-white dark:text-gray-300 hover:bg-sky-50 hover:border-b-gray-300 hover:text-black text-gray-700'
          )}
          href={item.link}
        >
          {item.name}
        </RouterLinkHelper>
      ))}
    </div>
  );
}

export default ConfiguratorPageTabs;
