import flatten from 'lodash/flatten';
import { BufferAttribute, BufferGeometry } from 'three';

// https://discourse.threejs.org/t/profiledcontourgeometry-multimaterial/5801/4
// https://github.com/hofk/THREEg.js
// https://hofk.de/main/threejs/

export function profiledContourUV(
  profileShape: number[],
  contour: number[],
  profileClosed: boolean,
  contourClosed: boolean,
  openEnded: boolean,
  startContourCut = 0,
  endContourCut = 0,
  uvScale = 1
) {
  // creates group of frame strips, non indexed BufferGeometry

  const len = (x: number, y: number, z: number) =>
    Math.sqrt(x * x + y * y + z * z);
  const dot = (
    x1: number,
    y1: number,
    z1: number,
    x2: number,
    y2: number,
    z2: number
  ) => x1 * x2 + y1 * y2 + z1 * z2;

  profileClosed = profileClosed !== undefined ? profileClosed : true;
  contourClosed = contourClosed !== undefined ? contourClosed : true;
  openEnded =
    openEnded !== undefined
      ? contourClosed === true
        ? true
        : openEnded
      : true;

  if (profileClosed) profileShape.push(profileShape[0], profileShape[1]);
  if (contourClosed) contour.push(contour[0], contour[1]);

  const hs1 = contour.length / 2;
  const rs1 = profileShape.length / 2;
  const hs = hs1 - 1; // height segments
  const rs = rs1 - 1; // radius segments

  const vtx: number[][] = []; // rs1 many vertex colums
  const frmpos: number[][] = []; // hs many geometries and meshes
  const frmuvs: number[][] = []; // hs many uv's'

  for (let j = 0; j < rs; j++) {
    vtx.push([]);
    frmpos.push([]);
    frmuvs.push([]);
  }

  vtx.push([]); // last colum

  if (!openEnded) {
    // for two ends

    frmpos.push([], []); // for two ends
    frmuvs.push([], []);
  }

  let i2: number, i3: number, i6: number, j1: number, j2: number;
  let xc0: number,
    yc0: number,
    xc1: number,
    yc1: number,
    xc2: number,
    yc2: number,
    xSh: number,
    xDiv = 1;
  let dx: number,
    dy: number,
    dx0: number,
    dy0: number,
    dx2: number,
    dy2: number;
  let e0x: number,
    e0y: number,
    e0Length: number,
    e2x: number,
    e2y: number,
    e2Length: number,
    ex = 1,
    ey = 1,
    eLength: number;
  let xd: number,
    phi: number,
    bend = 1;
  let x: number,
    y: number,
    z: number,
    x1: number,
    y1: number,
    z1: number,
    x2: number,
    y2: number,
    z2: number,
    x3: number,
    y3: number,
    z3: number,
    x4: number,
    y4: number,
    z4: number;
  let x1L = 1,
    y1L = 1,
    x2L = 1,
    y2L = 1,
    xMin: number,
    yMin: number,
    xMax: number,
    yMax: number;
  let a: number,
    u1 = 1,
    u1a: number,
    u1b: number,
    u2 = 1,
    u3 = 1,
    u4 = 1,
    v1: number,
    v2: number,
    v3: number,
    d2: number,
    d3: number;
  const epsilon = 0.000001;

  xMin = Infinity;
  yMin = Infinity;
  xMax = -Infinity;
  yMax = -Infinity;

  if (!openEnded) {
    for (let j = 0; j < rs1; j++) {
      j2 = 2 * j;

      if (profileShape[j2] < xMin) xMin = profileShape[j2];
      if (profileShape[j2 + 1] < yMin) yMin = profileShape[j2 + 1];

      if (profileShape[j2] > xMax) xMax = profileShape[j2];
      if (profileShape[j2 + 1] > yMax) yMax = profileShape[j2 + 1];
    }
  }

  for (let j = 0; j < rs1; j++) {
    j2 = 2 * j;

    for (let i = 0; i < hs1; i++) {
      i2 = 2 * i;

      xc1 = contour[i2];
      yc1 = contour[i2 + 1];

      if (i === 0) {
        xc0 = contour[(hs - 1) * 2]; // penultimate point
        yc0 = contour[(hs - 1) * 2 + 1];
      } else {
        xc0 = contour[i2 - 2]; // previous point
        yc0 = contour[i2 - 1];
      }

      if (i === hs) {
        xc2 = contour[2]; // second point
        yc2 = contour[3];
      } else {
        xc2 = contour[i2 + 2]; // next point
        yc2 = contour[i2 + 3];
      }

      if (!contourClosed) {
        if (i === startContourCut) {
          // direction
          dx2 = xc2 - xc1;
          dy2 = yc2 - yc1;

          // unit vector
          e2Length = Math.sqrt(dx2 * dx2 + dy2 * dy2);

          e2x = dx2 / e2Length;
          e2y = dy2 / e2Length;

          // orthogonal
          ex = e2y;
          ey = -e2x;
        }

        if (i === hs - endContourCut) {
          // direction

          dx0 = xc1 - xc0;
          dy0 = yc1 - yc0;

          // unit vector
          e0Length = Math.sqrt(dx0 * dx0 + dy0 * dy0);

          e0x = dx0 / e0Length;
          e0y = dy0 / e0Length;

          // orthogonal
          ex = e0y;
          ey = -e0x;
        }

        xDiv = 1;
        bend = 1;
      }

      if ((i > 0 && i < hs) || contourClosed) {
        // directions

        dx0 = xc0 - xc1;
        dy0 = yc0 - yc1;

        dx2 = xc2 - xc1;
        dy2 = yc2 - yc1;

        if (Math.abs(dy2 / dx2 - dy0 / dx0) < epsilon) {
          // prevent 0

          dy0 += epsilon;
        }

        if (Math.abs(dx2 / dy2 - dx0 / dy0) < epsilon) {
          // prevent 0

          dx0 += epsilon;
        }

        // unit vectors

        e0Length = Math.sqrt(dx0 * dx0 + dy0 * dy0);

        e0x = dx0 / e0Length;
        e0y = dy0 / e0Length;

        e2Length = Math.sqrt(dx2 * dx2 + dy2 * dy2);

        e2x = dx2 / e2Length;
        e2y = dy2 / e2Length;

        // direction transformed

        ex = e0x + e2x;
        ey = e0y + e2y;

        eLength = Math.sqrt(ex * ex + ey * ey);

        ex = ex / eLength;
        ey = ey / eLength;

        phi = Math.acos(e2x * e0x + e2y * e0y) / 2;

        bend = Math.sign(dx0 * dy2 - dy0 * dx2); // z cross -> curve bending

        xDiv = Math.sin(phi);
      }

      xSh = profileShape[j2];

      xd = xSh / xDiv;

      dx = xd * bend * ex;
      dy = xd * bend * ey;

      x = xc1 + dx;
      y = yc1 + dy;
      z = profileShape[j2 + 1]; // ySh

      vtx[j].push(x, y, z); // store vertex

      //dApex = xd * Math.cos( phi );

      if (j === 0 && i === hs - endContourCut && !openEnded) {
        //center

        x1L = xc1;
        y1L = yc1;
      }

      if (j === 0 && i === startContourCut && !openEnded) {
        //center

        x2L = xc1;
        y2L = yc1;
      }
    }
  }

  for (let j = 0; j < rs; j++) {
    j1 = j + 1;

    for (let i = startContourCut; i < hs - endContourCut; i++) {
      i3 = 3 * i;
      i6 = i3 + 3;

      x1 = vtx[j][i3];
      y1 = vtx[j][i3 + 1];
      z1 = vtx[j][i3 + 2];

      x2 = vtx[j1][i3];
      y2 = vtx[j1][i3 + 1];
      z2 = vtx[j1][i3 + 2];

      x3 = vtx[j1][i6];
      y3 = vtx[j1][i6 + 1];
      z3 = vtx[j1][i6 + 2];

      x4 = vtx[j][i6];
      y4 = vtx[j][i6 + 1];
      z4 = vtx[j][i6 + 2];

      frmpos[j].push(
        x1,
        y1,
        z1,
        x2,
        y2,
        z2,
        x4,
        y4,
        z4,
        x2,
        y2,
        z2,
        x3,
        y3,
        z3,
        x4,
        y4,
        z4
      );

      a = len(x4 - x1, y4 - y1, z4 - z1);

      d2 = dot(x4 - x1, y4 - y1, z4 - z1, x2 - x1, y2 - y1, z2 - z1) / a;
      d3 = dot(x1 - x4, y1 - y4, z1 - z4, x3 - x4, y3 - y4, z3 - z4) / a;

      if (d2 >= 0 && d3 >= 0) {
        u1 = 0;
        u2 = d2 / a;
        u3 = 1 - d3 / a;
        u4 = 1;
      }

      if (d2 >= 0 && d3 < 0) {
        u1 = 0;
        u2 = d2 / (a - d3);
        u3 = 1;
        u4 = 1 + d3 / (a - d3);
      }

      if (d2 < 0 && d3 < 0) {
        u1 = -d2 / (a - d2 - d3);
        u2 = 0;
        u3 = 1;
        u4 = 1 + d3 / (a - d2 - d3);
      }

      if (d2 < 0 && d3 >= 0) {
        u1 = -d2 / (a - d2);
        u2 = 0;
        u3 = 1 - d3 / (a - d2);
        u4 = 1;
      }

      frmuvs[j].push(
        u1 * uvScale,
        1 * uvScale,
        u2 * uvScale,
        0,
        u4 * uvScale,
        1 * uvScale,
        u2 * uvScale,
        0,
        u3 * uvScale,
        0,
        u4 * uvScale,
        1 * uvScale
      );
    }
  }

  if (!openEnded) {
    dx = xMax - xMin;
    dy = yMax - yMin;

    v1 = -yMin / dy;
    u1a = -xMin / dx;
    u1b = 1 - u1a;

    i2 = startContourCut * 3;
    i3 = 3 * (hs - endContourCut);

    for (let j = startContourCut; j < rs - endContourCut; j++) {
      j1 = j + 1;
      j2 = 2 * j;

      x1 = vtx[j][i2];
      y1 = vtx[j][i2 + 1];
      z1 = vtx[j][i2 + 2];

      x2 = vtx[j1][i2];
      y2 = vtx[j1][i2 + 1];
      z2 = vtx[j1][i2 + 2];

      frmpos[rs].push(x2L, y2L, 0, x1, y1, z1, x2, y2, z2);

      u2 = (profileShape[j2] - xMin) / dx;
      v2 = (profileShape[j2 + 1] - yMin) / dy;

      u3 = (profileShape[j2 + 2] - xMin) / dx;
      v3 = (profileShape[j2 + 3] - yMin) / dy;

      frmuvs[rs].push(
        u1a * uvScale,
        v1 * uvScale,
        u2 * uvScale,
        v2 * uvScale,
        u3 * uvScale,
        v3 * uvScale
      );

      x1 = vtx[j1][i3];
      y1 = vtx[j1][i3 + 1];
      z1 = vtx[j1][i3 + 2];

      x2 = vtx[j][i3];
      y2 = vtx[j][i3 + 1];
      z2 = vtx[j][i3 + 2];

      frmpos[rs1].push(x1L, y1L, 0, x1, y1, z1, x2, y2, z2);

      u2 = 1 - (profileShape[j2 + 2] - xMin) / dx;
      v2 = (profileShape[j2 + 3] - yMin) / dy;

      u3 = 1 - (profileShape[j2] - xMin) / dx;
      v3 = (profileShape[j2 + 1] - yMin) / dy;

      frmuvs[rs1].push(
        u1b * uvScale,
        v1 * uvScale,
        u2 * uvScale,
        v2 * uvScale,
        u3 * uvScale,
        v3 * uvScale
      );
    }
  }

  const geometry = new BufferGeometry();
  geometry.setAttribute(
    'position',
    new BufferAttribute(new Float32Array(flatten(frmpos)), 3)
  );
  geometry.setAttribute(
    'uv',
    new BufferAttribute(new Float32Array(flatten(frmuvs)), 2)
  );

  geometry.computeVertexNormals();

  geometry.attributes.uv.needsUpdate = true;

  // =================

  // geometry.computeBoundingBox();

  // if (!geometry.boundingBox) return geometry;

  // const bboxSize = geometry.boundingBox.getSize(new Vector3());
  // const uvMapSize = Math.min(bboxSize.x / 10, bboxSize.y / 10, bboxSize.z / 10);

  // applyBoxUV(geometry, new Matrix4(), uvMapSize);

  // geometry.attributes.uv.needsUpdate = true;

  return geometry;
}
