import { Line, Text } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useMemo, useRef, useState, useEffect } from 'react';
import { Box3, Euler, Group, Object3D, Vector3 } from 'three';
import map from 'lodash/map';
import { globalStore, MeasurementsSystem } from '../../store/globalStore';
import { sceneStore } from '../../store/sceneStore';
import { SceneNodeType } from '../../types/scene.types';

interface BoxMeasurementsProps {
  group: Group;
}

interface BoxMeasurementsPoint {
  position: Vector3;
  measurement: string;
  rotation: Euler;
  anchorX: 'center' | 'left' | 'right';
}

const inchScalar = 0.3937;
function getPlainMeasurements(
  size: number,
  measurementsSystem: MeasurementsSystem
) {
  return `${parseFloat(
    (
      size *
      100 *
      (measurementsSystem === MeasurementsSystem.AMERICAN ? inchScalar : 1)
    ).toFixed(1)
  )}`;
}
function getMeasurements(size: number, measurementsSystem: MeasurementsSystem) {
  return `${parseFloat(
    (
      size *
      100 *
      (measurementsSystem === MeasurementsSystem.AMERICAN ? inchScalar : 1)
    ).toFixed(1)
  )} ${measurementsSystem === MeasurementsSystem.AMERICAN ? 'in' : 'cm'}`;
}

export function BoxMeasurements({ group }: BoxMeasurementsProps) {
  const sectionigMode = sceneStore.use.sectioningMode();
  const selectedNode = sceneStore.use.selectedNode();
  const enableMeasurements = globalStore.use.enableMeasurements();
  const measurementsSystem = globalStore.use.measurementsSystem();
  const prevMeasureSystem = useRef<MeasurementsSystem>();
  const boundingBox = useMemo<Box3>(() => {
    const box = new Box3().setFromObject(group);
    if (sectionigMode) {
      box.setFromCenterAndSize(
        box.getCenter(new Vector3()).sub(group.position),
        box.getSize(new Vector3())
      );
    }
    return box;
  }, [group, sectionigMode]);

  const boxSize = useMemo<Vector3>(
    () => boundingBox.getSize(new Vector3()),
    [boundingBox]
  );

  const boxCenter = useMemo<Vector3>(
    () => boundingBox.getCenter(new Vector3()),
    [boundingBox]
  );

  const camera = useThree(({ camera }) => camera);
  const [cameraPosition, setCameraPosition] = useState(camera.position.clone());

  const textRefs = useRef<Object3D[]>([]);

  useFrame(({ camera }) => {
    !camera.position.equals(cameraPosition) &&
      setCameraPosition(camera.position.clone());
  });
  const side = useMemo<Vector3>(() => {
    const result = cameraPosition.clone().sub(boxCenter);
    result.divide(
      new Vector3(
        Math.abs(result.x) || 1,
        Math.abs(result.y) || 1,
        Math.abs(result.z) || 1
      )
    );

    result.setY(-1);

    return result;
  }, [boxCenter, cameraPosition]);

  const startPoint = useMemo<Vector3>(() => {
    return boxSize.clone().divideScalar(2).multiply(side);
  }, [boxSize, side]);

  const lines = useMemo<Vector3[][]>(() => {
    const endPoint = boxSize.clone().multiply(side);

    const result: Vector3[][] = [];

    result.push([
      startPoint,
      new Vector3(startPoint.x - endPoint.x, startPoint.y, startPoint.z)
    ]);

    result.push([
      startPoint,
      new Vector3(startPoint.x, startPoint.y - endPoint.y, startPoint.z)
    ]);

    result.push([
      startPoint,
      new Vector3(startPoint.x, startPoint.y, startPoint.z - endPoint.z)
    ]);

    return result;
  }, [startPoint]);

  useEffect(() => {
    if (
      selectedNode &&
      selectedNode?.type !== SceneNodeType.CAMERA &&
      (selectedNode.size === undefined ||
        prevMeasureSystem.current !== measurementsSystem)
    ) {
      const size = `${getPlainMeasurements(
        boxSize.x,
        measurementsSystem
      )}x${getPlainMeasurements(
        boxSize.z,
        measurementsSystem
      )}x${getPlainMeasurements(boxSize.y, measurementsSystem)} ${
        measurementsSystem === MeasurementsSystem.AMERICAN ? 'in' : 'cm'
      }`;
      sceneStore.set.updateSceneNode(selectedNode, {
        ...selectedNode,
        size: size
      });
      prevMeasureSystem.current = measurementsSystem;
    }
  }, [selectedNode, measurementsSystem]);

  const points = useMemo<BoxMeasurementsPoint[]>(() => {
    const result: BoxMeasurementsPoint[] = [];

    const margin = side.clone().multiplyScalar(0.1);

    const deg180 = Math.PI;
    const deg90 = deg180 / 2;

    result.push({
      measurement: getMeasurements(boxSize.x, measurementsSystem),
      position: new Vector3(0, startPoint.y, startPoint.z + margin.z),
      rotation: new Euler(-deg90, 0, side.z < 0 ? deg180 : 0),
      anchorX: 'center'
    });

    const startPointSide = cameraPosition
      .clone()
      .sub(boxCenter)
      .applyEuler(new Euler(0, Math.PI / 4, 0));

    startPointSide.divide(
      new Vector3(
        Math.abs(startPointSide.x) || 1,
        Math.abs(startPointSide.y) || 1,
        Math.abs(startPointSide.z) || 1
      )
    );
    startPointSide.setY(-1);

    const isFront = side.z > 0;
    const scalar = isFront ? -1 : 1;

    let rotateY = !isFront ? deg180 : 0;

    if (startPointSide.x < 0) {
      rotateY += deg90 * scalar;
    }

    if (startPointSide.z < 0) {
      rotateY += -deg90 * scalar;
    }

    const totalSide = side.x * side.z * startPointSide.x * startPointSide.z;

    result.push({
      measurement: getMeasurements(boxSize.y, measurementsSystem),
      // position: new Vector3(
      //   startPoint.x -
      //     (startPointSide.x * startPointSide.z > 0 ? margin.x / 2 : 0),
      //   0,
      //   startPoint.z -
      //     (startPointSide.x * startPointSide.z > 0 ? 0 : margin.z / 2)
      // ),
      // rotation: new Euler(0, rotateY, 0),
      // anchorX: 'center'

      position: new Vector3(
        startPoint.x + (startPointSide.x * startPointSide.z > 0 ? margin.x : 0),
        0,
        startPoint.z + (startPointSide.x * startPointSide.z > 0 ? 0 : margin.z)
      ),
      rotation: new Euler(0, rotateY, totalSide > 0 ? -deg90 : deg90),
      anchorX: totalSide > 0 ? 'right' : 'left'
    });
    result.push({
      measurement: getMeasurements(boxSize.z, measurementsSystem),
      position: new Vector3(startPoint.x + margin.x, startPoint.y, 0),
      rotation: new Euler(-deg90, 0, deg90 * side.x),
      anchorX: 'center'
    });

    return result;
  }, [startPoint, side, measurementsSystem]);

  return (
    <>
      {enableMeasurements && (
        <group position={boxCenter}>
          {map(lines, (points, index) => (
            <Line
              key={index}
              points={points}
              color="black"
              lineWidth={1}
              renderOrder={-2}
            />
          ))}
          {map(points, (point, index) => (
            <Text
              key={index}
              ref={(el) => (textRefs.current[index] = el as Object3D)}
              position={point.position}
              rotation={point.rotation}
              anchorX={point.anchorX}
              anchorY="middle"
              color="black"
              renderOrder={-2}
            >
              {point.measurement}
            </Text>
          ))}
        </group>
      )}
    </>
  );
}
