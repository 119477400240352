import { MaterialType } from '../../types/scene.types';
import { SettingsMaterialAssetItems } from '../../types/settings.types';

export const mockRoomTextures: SettingsMaterialAssetItems = {
  parquet: {
    id: 'parquet',
    previewImage: '/assets/materials/preview/parquet.jpg',
    name: 'Parquet',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/parquet.glb'
    }
  }
};
