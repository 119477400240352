import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from './localesKeysTypes';

export interface WordsType {
  addDecoration: LocalesKey;
  addSection: LocalesKey;
  changeMaterials: LocalesKey;
  components: LocalesKey;
  configure: LocalesKey;
  configureShape: LocalesKey;
  depth: LocalesKey;
  editProduct: LocalesKey;
  height: LocalesKey;
  hide: LocalesKey;
  interiorConfigurator: LocalesKey;
  keepOpen: LocalesKey;
  nda: LocalesKey;
  productDetails: LocalesKey;
  products: LocalesKey;
  resizeRoom: LocalesKey;
  textures: LocalesKey;
  walls: LocalesKey;
  width: LocalesKey;
}

export const words = keyPathMirror<WordsType, string>(
  {
    addDecoration: null,
    addSection: null,
    changeMaterials: null,
    components: null,
    configure: null,
    configureShape: null,
    depth: null,
    editProduct: null,
    height: null,
    hide: null,
    interiorConfigurator: null,
    keepOpen: null,
    nda: null,
    productDetails: null,
    products: null,
    resizeRoom: null,
    textures: null,
    walls: null,
    width: null
  },
  'words.'
);
