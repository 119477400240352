import { map } from 'lodash';

import {
  TreeViewerNode,
  TreeViewerNodeProps
} from './components/TreeViewerNode';

export interface TreeViewerProps {
  nodes: TreeViewerNodeProps[];
}

function TreeViewer({ nodes }: TreeViewerProps) {
  return (
    <div className="flex-1 relative">
      <div className="absolute inset-0 overflow-y-auto">
        <div className="py-2">
          {map(nodes, (item, i) => (
            <TreeViewerNode
              key={i}
              icon={item.icon}
              label={item.label}
              childrenNodes={item.childrenNodes}
              selected={item.selected}
              onClick={item.onClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default TreeViewer;
