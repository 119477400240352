import { useEffect, useMemo, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';

import map from 'lodash/map';

import { sceneStore } from '../../../store/sceneStore';

import { Wall } from './components/Wall';

export function Walls() {
  const { plan, wallHeight, thickness } = sceneStore.use.roomSettings();

  const profileShape = useMemo(() => {
    return [0, 0, 0, -wallHeight, -thickness, -wallHeight, -thickness, 0];
  }, [wallHeight, thickness]);

  const camera = useThree(({ camera }) => camera);

  const [cameraX, setCameraX] = useState(camera.position.x);
  const [cameraZ, setCameraZ] = useState(camera.position.z);

  useFrame(({ camera }) => {
    setCameraX(camera.position.x);
    setCameraZ(camera.position.z);
  });

  useEffect(() => {
    return () => {
      sceneStore.set.roomSettings({
        ...sceneStore.get.roomSettings(),
        boxes: []
      });
    };
  }, [plan]);

  return (
    <>
      {map(plan, (_, i, arr) => (
        <Wall
          key={i}
          id={i}
          points={arr}
          profileShape={profileShape}
          cameraX={cameraX}
          cameraZ={cameraZ}
        />
      ))}
    </>
  );
}
