import { Color, Vector3 } from 'three';
import range from 'lodash/range';
import map from 'lodash/map';
import { Line } from '@react-three/drei';

interface GridProps {
  position: Vector3;
  width: number;
  height: number;
  step: number;
  color?: Color | number;
}

export function Grid({
  position,
  width,
  height,
  step,
  color = 0x777777
}: GridProps) {
  const rows = Math.floor(height / step) + 1;
  const cols = Math.floor(width / step) + 1;

  const startX = width / -2;
  const startZ = height / -2;

  return (
    <group position={position}>
      {map(range(cols), (index) => (
        <Line
          key={index}
          points={[
            [startX + step * index, 0, startZ],
            [startX + step * index, 0, startZ + height]
          ]}
          color={color}
        />
      ))}
      {map(range(rows), (index) => (
        <Line
          key={index}
          points={[
            [startX, 0, startZ + step * index],
            [startX + width, 0, startZ + step * index]
          ]}
          color={color}
        />
      ))}
    </group>
  );
}
