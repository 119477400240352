import { useMemo, useState } from 'react';

import {
  preventDefaultDragStart,
  useDragHtml
} from '../../../../hooks/useDragHtml';

import { TooltipSingletonTarget } from '../../../../helpers/tooltips/TooltipSingletonTarget';

export interface ProductSidebarSectionProps {
  id: string;
  img: string;
  name: string;
}

function ProductSidebarSection({ id, img, name }: ProductSidebarSectionProps) {
  const { dndId, shift, active, visibility, onPointerDown } = useDragHtml(id);

  const [wrapperEl, setWrapperEl] = useState<HTMLDivElement | null>(null);

  const offsetWtapperParent = useMemo(() => {
    if (!wrapperEl) return { left: 0, top: 0 };

    let left = 0;
    let top = 0;
    let parent: HTMLElement | null = wrapperEl;

    while (parent) {
      left += parent.offsetLeft;
      top += parent.offsetTop;
      parent = parent.offsetParent as HTMLElement | null;
    }

    return { left, top };
  }, [wrapperEl]);

  const cloneStyles = {
    transform: `translate(${offsetWtapperParent.left + shift.x}px, ${
      offsetWtapperParent.top + shift.y
    }px)`,
    width: `${wrapperEl?.offsetWidth}px`,
    height: `${wrapperEl?.offsetHeight}px`
  };

  return (
    <>
      <div
        className="aspect-w-1 aspect-h-1 overflow-hidden rounded-md focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100"
        onPointerDown={onPointerDown}
        style={{ pointerEvents: active ? 'none' : 'auto' }}
        ref={setWrapperEl}
      >
        <TooltipSingletonTarget
          referenceElement={wrapperEl}
          tooltipText={name}
        />
        <img
          src={img}
          alt={name}
          className="object-cover w-full h-full"
          onDragStart={preventDefaultDragStart}
        />
      </div>

      {dndId === id && active && visibility && (
        <div
          className="fixed top-0 left-0 pointer-events-none outline z-50 overflow-hidden rounded-md focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100"
          style={cloneStyles}
        >
          <img
            src={img}
            alt={name}
            className="object-cover w-full h-full"
            onDragStart={preventDefaultDragStart}
          />
        </div>
      )}
    </>
  );
}

export default ProductSidebarSection;
