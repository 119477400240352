import React, { ReactNode } from 'react';

import { DefaultFooter } from '../../footers/DefaultFooter';

interface MainLayoutProps {
  children: ReactNode;
}

function MainLayout({ children }: MainLayoutProps) {
  return (
    <div
      id="main-section"
      className="flex-1 flex flex-col overflow-hidden relative sm:z-0"
    >
      {children}
      <DefaultFooter />
    </div>
  );
}

export default MainLayout;
