import { useCallback } from 'react';
import { sceneStore } from '../../../../store/sceneStore';

import { MaterialItem } from '../../../../types/scene.types';

import { ContentBrowserSelectItem } from '../ContentBrowserSelectItem';

export interface ContentBrowserMaterialProps {
  materialItem: MaterialItem;
  group: string;
  materialName: string;
  materialImage: string;
  currentMaterialItem: MaterialItem;
}

function ContentBrowserMaterial({
  materialItem,
  group,
  materialName,
  materialImage,
  currentMaterialItem
}: ContentBrowserMaterialProps) {
  /*   console.log({
    materialItem,
    group,
    materialName,
    materialImage,
    currentMaterialItem
  }); */
  const handleClick = useCallback(() => {
    sceneStore.set.setMaterial(group, materialItem);
  }, [group, materialItem]);

  return (
    <ContentBrowserSelectItem
      name={materialName}
      onClick={handleClick}
      img={materialImage}
      category={group}
      selected={currentMaterialItem.name === materialItem.name}
    />
  );
}

export default ContentBrowserMaterial;
