import React, { memo } from 'react';

import { IconsEnum } from '../../assets/icons/types';

import { ArrowLeftIcon } from '../../assets/icons/ArrowLeftIcon';
import { ArrowLeftOutlineIcon } from '../../assets/icons/ArrowLeftOutlineIcon';
import { ArrowRightSolidIcon } from '../../assets/icons/ArrowRightSolidIcon';
import { AVIcon } from '../../assets/icons/AVIcon';
import { CalculatorIcon } from '../../assets/icons/CalculatorIcon';
import { CheckIcon } from '../../assets/icons/CheckIcon';
import { ChevronUpIcon } from '../../assets/icons/ChevronUpIcon';
import { ChevronRightIcon } from '../../assets/icons/ChevronRightIcon';
import { ColorSwatchOutlineIcon } from '../../assets/icons/ColorSwatchOutlineIcon';
import { CpuChipIcon } from '../../assets/icons/CpuChipIcon';
import { CrossIcon } from '../../assets/icons/CrossIcon';
import { CubeOutlineIcon } from '../../assets/icons/CubeOutlineIcon';
import { CubeSolidIcon } from '../../assets/icons/CubeSolidIcon';
import { CubeTransparentOutlineIcon } from '../../assets/icons/CubeTransparentOutlineIcon';
import { DistanceSolidIcon } from '../../assets/icons/DistanceSolidIcon';
import { DocumentArrowDownOutlineIcon } from '../../assets/icons/DocumentArrowDownOutlineIcon';
import { DocumentArrowUpOutlineIcon } from '../../assets/icons/DocumentArrowUpOutlineIcon';
import { DoorOutlineIcon } from '../../assets/icons/DoorOutlineIcon';
import { ExclamationCircleIcon } from '../../assets/icons/ExclamationCircleIcon';
import { HomeIcon } from '../../assets/icons/HomeIcon';
import { MenuOutlineIcon } from '../../assets/icons/MenuOutlineIcon';
import { MinusOutlineIcon } from '../../assets/icons/MinusOutlineIcon';
import { PlusOutlineIcon } from '../../assets/icons/PlusOutlineIcon';
import { PencilSquareIcon } from '../../assets/icons/PencilSquareIcon';
import { RefreshOutlineIcon } from '../../assets/icons/RefreshOutlineIcon';
import { RulerOutlineIcon } from '../../assets/icons/RulerOutlineIcon';
import { RulerSolidIcon } from '../../assets/icons/RulerSolidIcon';
import { TrashOutlineIcon } from '../../assets/icons/TrashOutlineIcon';
import { VideoCameraSolidIcon } from '../../assets/icons/VideoCameraSolidIcon';
import { ViewGridAddIcon } from '../../assets/icons/ViewGridAddIcon';
import { XIcon } from '../../assets/icons/XIcon';
import { ZoomInOutlineIcon } from '../../assets/icons/ZoomInOutlineIcon';
import { ZoomOutOutlineIcon } from '../../assets/icons/ZoomOutOutlineIcon';
import { ArrowUpOnSquareIcon } from '../../assets/icons/ArrowUpOnSquareIcon';
import { WindowOutlineIcon } from '../../assets/icons/WindowOutlineIcon';
import { ArrowPathIcon } from '../../assets/icons/ArrowPathIcon';

interface IconProps {
  id?: string;
  className?: string;
  icon: IconsEnum;
  strokeWidth?: string;
}

function Icon({ className, icon, id }: IconProps) {
  switch (icon) {
    case IconsEnum.ARROW_LEFT:
      return <ArrowLeftIcon className={className} id={id} />;
    case IconsEnum.ARROW_LEFT_OUTLINE:
      return <ArrowLeftOutlineIcon className={className} id={id} />;
    case IconsEnum.ARROW_PATH:
      return <ArrowPathIcon className={className} id={id} />;
    case IconsEnum.ARROW_RIGHT_SOLID:
      return <ArrowRightSolidIcon className={className} id={id} />;
    case IconsEnum.ARROW_UP_ON_SQUARE:
      return <ArrowUpOnSquareIcon className={className} id={id} />;
    case IconsEnum.AV:
      return <AVIcon className={className} id={id} />;
    case IconsEnum.CALCULATOR:
      return <CalculatorIcon className={className} id={id} />;
    case IconsEnum.CHECK:
      return <CheckIcon className={className} id={id} />;
    case IconsEnum.CHEVRON_UP:
      return <ChevronUpIcon className={className} id={id} />;
    case IconsEnum.CHEVRON_RIGHT:
      return <ChevronRightIcon className={className} id={id} />;
    case IconsEnum.COLOR_SWATCH_OUTLINE:
      return <ColorSwatchOutlineIcon className={className} id={id} />;
    case IconsEnum.CPU_CHIP:
      return <CpuChipIcon className={className} id={id} />;
    case IconsEnum.CROSS:
      return <CrossIcon className={className} id={id} />;
    case IconsEnum.CUBE_OUTLINE:
      return <CubeOutlineIcon className={className} id={id} />;
    case IconsEnum.CUBE_SOLID:
      return <CubeSolidIcon className={className} id={id} />;
    case IconsEnum.CUBE_TRANSPARENT_OUTLINE:
      return <CubeTransparentOutlineIcon className={className} id={id} />;
    case IconsEnum.DISTANCE_SOLID:
      return <DistanceSolidIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_ARROW_DOWN_OUTLINE:
      return <DocumentArrowDownOutlineIcon className={className} id={id} />;
    case IconsEnum.DOCUMENT_ARROW_UP_OUTLINE:
      return <DocumentArrowUpOutlineIcon className={className} id={id} />;
    case IconsEnum.DOOR_OUTLINE:
      return <DoorOutlineIcon className={className} id={id} />;
    case IconsEnum.EXCLAMATION_CIRCLE:
      return <ExclamationCircleIcon className={className} id={id} />;
    case IconsEnum.HOME:
      return <HomeIcon className={className} id={id} />;
    case IconsEnum.MENU_OUTLINE:
      return <MenuOutlineIcon className={className} id={id} />;
    case IconsEnum.MINUS_OUTLINE:
      return <MinusOutlineIcon className={className} id={id} />;
    case IconsEnum.PLUS_OUTLINE:
      return <PlusOutlineIcon className={className} id={id} />;
    case IconsEnum.PENCIL_SQUARE:
      return <PencilSquareIcon className={className} id={id} />;
    case IconsEnum.REFRESH_OUTLINE:
      return <RefreshOutlineIcon className={className} id={id} />;
    case IconsEnum.RULER_OUTLINE:
      return <RulerOutlineIcon className={className} id={id} />;
    case IconsEnum.RULER_SOLID:
      return <RulerSolidIcon className={className} id={id} />;
    case IconsEnum.TRASH_OUTLINE:
      return <TrashOutlineIcon className={className} id={id} />;
    case IconsEnum.VIDEO_CAMERA_SOLID:
      return <VideoCameraSolidIcon className={className} id={id} />;
    case IconsEnum.VIEW_GRID_ADD:
      return <ViewGridAddIcon className={className} id={id} />;
    case IconsEnum.WINDOW_OUTLINE:
      return <WindowOutlineIcon className={className} id={id} />;
    case IconsEnum.X:
      return <XIcon className={className} id={id} />;
    case IconsEnum.ZOOM_IN_OUTLINE:
      return <ZoomInOutlineIcon className={className} id={id} />;
    case IconsEnum.ZOOM_OUT_OUTLINE:
      return <ZoomOutOutlineIcon className={className} id={id} />;
    default:
      return null;
  }
}

export default memo<IconProps>(Icon);
