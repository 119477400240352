import { IconProps } from '../types';

function DistanceSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 20 20"
      fill="currentColor"
      stroke="currentColor"
    >
      <g transform="matrix(0.032,0,0,0.032,18,2) rotate(90)">
        <path d="m224 320.443v-128.886l-32.408 32.408a32 32 0 1 1 -45.255-45.255l87.036-87.035a32 32 0 0 1 45.254 0l87.036 87.035a32 32 0 0 1 -45.255 45.255l-32.408-32.408v128.886l32.408-32.408a32 32 0 0 1 45.255 45.255l-87.036 87.035a32 32 0 0 1 -45.254 0l-87.036-87.035a32 32 0 1 1 45.255-45.255zm-112 159.557a32 32 0 0 0 32 32h224a32 32 0 0 0 0-64h-224a32 32 0 0 0 -32 32zm0-448a32 32 0 0 0 32 32h224a32 32 0 0 0 0-64h-224a32 32 0 0 0 -32 32z"></path>
      </g>
    </svg>
  );
}

export default DistanceSolidIcon;
