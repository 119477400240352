import {
  SceneNodeAttach,
  SceneNodeStick,
  SceneNodeType
} from '../../types/scene.types';
import { SettingsModelAssetItems } from '../../types/settings.types';

export const mockSku14404: SettingsModelAssetItems = {
  sku14404_angular: {
    id: 'sku14404_angular',
    parentId: 'sku14404',
    previewImage: '/assets/models/sku14404/preview/angular.jpg',
    name: 'Angular',
    fileUrl: '/assets/models/sku14404/angular.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  sku14404_middle: {
    id: 'sku14404_middle',
    parentId: 'sku14404',
    previewImage: '/assets/models/sku14404/preview/middle.jpg',
    name: 'Middle',
    fileUrl: '/assets/models/sku14404/middle.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  sku14404_left_arm: {
    id: 'sku14404_left_arm',
    parentId: 'sku14404',
    previewImage: '/assets/models/sku14404/preview/left_arm.jpg',
    name: 'Left Arm',
    fileUrl: '/assets/models/sku14404/left_arm.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  sku14404_right_arm: {
    id: 'sku14404_right_arm',
    parentId: 'sku14404',
    previewImage: '/assets/models/sku14404/preview/right_arm.jpg',
    name: 'Right Arm',
    fileUrl: '/assets/models/sku14404/right_arm.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  sku14404_ottoman: {
    id: 'sku14404_ottoman',
    parentId: 'sku14404',
    previewImage: '/assets/models/sku14404/preview/ottoman.jpg',
    name: 'Ottoman',
    fileUrl: '/assets/models/sku14404/ottoman.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  }
};
