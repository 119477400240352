import {
  MaterialItem,
  MaterialType,
  SceneNodeAttach,
  SceneNodeStick,
  SceneNodeType
} from './scene.types';

export enum ModelBind {
  MODEL = 'model',
  DECORATIONS = 'decorations'
}

export type SettingsAssetBaseItem = {
  id: string;
  previewImage: string;
  name: string;
};

export type SettingsAssetModelItem = SettingsAssetBaseItem & {
  parentId?: string;
  fileUrl: string;
  attach: SceneNodeAttach;
  stick: SceneNodeStick;
  type: SceneNodeType;
};

export type SettingsAssetMaterialItem = SettingsAssetBaseItem & {
  filesUrl: {
    [MaterialType.ATLAS]?: Record<string, string>;
    [MaterialType.REAL_WORLD]?: string;
  };
};

export type SettingsMaterialAssetItems = Record<
  string,
  SettingsAssetMaterialItem
>;

export type SettingsMaterials = {
  assets: SettingsMaterialAssetItems;
  bind: Record<string, MaterialItem[]>;
};

export type SettingsModelAssetItems = Record<string, SettingsAssetModelItem>;

export type SettingsModels = {
  assets: SettingsModelAssetItems;
  bind: Record<ModelBind, string[]>;
};

export type SettingsNaming = {
  prefix: SettingsNamingPrefix;
  separator: SettingsNamingSeparator;
};

export type NamingPrefix<T> = {
  model: T;
  slots: T;
  childs: T;
};

export type SettingsNamingPrefix = NamingPrefix<string>;

export type NamingSeparator<T> = {
  empty: T;
  group: T;
  points: T;
};

export type SettingsNamingSeparator = NamingSeparator<string>;

export type SettingsInfo = {
  preview: string;
  previewLarge: string;
  brand: string;
  category?: string;
  collections?: string;
  nda?: boolean;
  sku: string;
  style?: string;
  name: string;
};

export type Settings = {
  info: SettingsInfo;
  models: SettingsModels;
  naming: SettingsNaming;
  materials: SettingsMaterials;
};
