import React, { ReactNode } from 'react';
import cl from 'classnames';

import { MainLayout } from '../MainLayout';
import { ClassName } from '../../../types/general.types';

interface ItemLayoutProps {
  name?: string;
  header?: ReactNode;
  tabs?: ReactNode;
  children: ReactNode;
  sidebar?: ReactNode;
  customSecondaryMenu?: ReactNode;
  mainAddClassName?: ClassName;
}

function ItemLayout({
  header,
  tabs,
  children,
  sidebar,
  customSecondaryMenu,
  mainAddClassName
}: ItemLayoutProps) {
  return (
    <MainLayout>
      <div className="flex flex-1">
        {customSecondaryMenu}
        <div className="flex flex-col w-full z-0">
          {(header || tabs) && (
            <div
              id="page-header-wrap"
              className="relative z-5 shrink-0 flex gap-4 lg:gap-6 px-4 items-center h-16 border-b border-gray-200 dark:border-gray-700"
            >
              {header}
              {tabs}
            </div>
          )}

          <div className="relative flex-1 xl:z-0">
            <div className="absolute inset-0 flex overflow-hidden">
              <main
                className={cl(
                  'flex-1 relative overflow-y-auto focus:outline-none z-0',
                  mainAddClassName
                )}
                tabIndex={-1}
              >
                {children}
              </main>
              {sidebar}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default ItemLayout;
