import { SceneNode } from '../../types/scene.types';

import { useHoverable } from '../../hooks/useHoverable';

type HoverableProps = {
  children: React.ReactNode;
  node: SceneNode;
};

export function Hoverable({ children, node }: HoverableProps) {
  const { handlePointerEnter, handlePointerLeave } = useHoverable(node);

  return (
    <group
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      {children}
    </group>
  );
}
