import { Html } from '@react-three/drei';
import { useControls } from 'leva';
import map from 'lodash/map';
import { Fragment, Suspense } from 'react';
import { useEffect } from 'react';
import { Euler, Vector3 } from 'three';

import { SceneNodeType, SlotNode } from '../../types/scene.types';

import { globalStore } from '../../store/globalStore';
import { DefaultScopes, SceneStore, sceneStore } from '../../store/sceneStore';

import { DraggableModel } from '../../models/DraggableModel';
import { AvailableSlots } from '../../models/helpers/AvailableSlots';
import { DraggableGroupModel } from '../../models/DraggableGroupModel';

const enabledDevTools = process.env.REACT_APP_DEV_TOOLS === 'true';

const defaultPosition = new Vector3();
const defaultRotation = new Euler();

const sceneStoreSelector = ({
  techScope,
  selectedNode,
  configureNode,
  sceneMode,
  sectioningMode
}: SceneStore) => ({
  techScope,
  selectedNode,
  configureNode,
  sceneMode,
  sectioningMode
});

const scope = DefaultScopes.FLOOR;

export function RoomModels() {
  const { techScope, selectedNode, configureNode, sceneMode, sectioningMode } =
    sceneStore.useStore(sceneStoreSelector);

  const floor = sceneStore.useStore(({ scene }) => scene[scope]);

  const canvasWrapper = globalStore.use.canvasWrapper();

  // DEV ONLY
  const [{ snappingHelpers }] = useControls('snappingHelpers', () => ({
    snappingHelpers: enabledDevTools
  }));

  useEffect(() => {
    const onKeypress = (event: KeyboardEvent) => {
      if (!selectedNode || selectedNode.type === SceneNodeType.CAMERA) return;

      event.key === 'Delete' && sceneStore.set.deleteSceneNode(selectedNode);

      event.code === 'KeyR' &&
        !sceneStore.get.previewInSlot() &&
        sceneStore.set.rotateBy(new Euler(0, 45 * (Math.PI / 180), 0));
    };

    const onEnter = () => {
      canvasWrapper?.focus();
    };

    canvasWrapper?.addEventListener('pointerenter', onEnter);
    canvasWrapper?.addEventListener('keydown', onKeypress);

    return () => {
      canvasWrapper?.removeEventListener('pointerenter', onEnter);
      canvasWrapper?.removeEventListener('keydown', onKeypress);
    };
  }, [canvasWrapper, selectedNode]);

  return (
    <>
      {sceneMode &&
        map(floor, (node) => (
          <Suspense key={node.id}>
            {node.type !== SceneNodeType.GROUP && (
              <DraggableModel node={node} />
            )}
            {node.type === SceneNodeType.GROUP &&
              (!sectioningMode || node !== configureNode) && (
                <DraggableGroupModel node={node} />
              )}
          </Suspense>
        ))}

      {!sceneMode &&
        sectioningMode &&
        configureNode?.type === SceneNodeType.SIMPLE && (
          <Suspense>
            <DraggableModel node={configureNode} />
          </Suspense>
        )}

      {sectioningMode &&
        configureNode?.type === SceneNodeType.GROUP &&
        map(
          configureNode.model,
          (node) =>
            node.type === SceneNodeType.SLOT && (
              <Fragment key={node.id}>
                <group
                  position={
                    sceneMode ? configureNode.position : defaultPosition
                  }
                  rotation={
                    sceneMode ? configureNode.rotation : defaultRotation
                  }
                >
                  <DraggableModel node={node} />
                  <AvailableSlots
                    interactiveItem={techScope.preview as SlotNode}
                    node={node}
                    scope={scope}
                  />
                  {/* DEV ONLY */}
                  {snappingHelpers && (
                    <group position-y={1}>
                      <Html
                        className="scene-annotation"
                        distanceFactor={5}
                        center
                        position={node.position}
                      >
                        {parseInt(node.id, 10) % 100}
                      </Html>
                    </group>
                  )}
                </group>
              </Fragment>
            )
        )}
    </>
  );
}
