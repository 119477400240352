import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Settings } from '../../../../types/settings.types';

import { ConfigureProductPageMenu } from '../../components/ConfigureProductPageMenu';
import { ConfigureProductPageHeader } from '../../components/ConfigureProductPageHeader';
import { ConfiguratorView } from '../../../../scene/ConfiguratorView';
import { ItemLayout } from '../../../../common/layouts/ItemLayout';

import { globalStore } from '../../../../store/globalStore';
import { sceneStore } from '../../../../store/sceneStore';

import { sku446Settings } from '../../../../mocks/mockSettingSku446';
import { sku14404Settings } from '../../../../mocks/mockSettingSku14404';
import { harmonySettings } from '../../../../mocks/mockSettingHarmony';

function settingsById(productId?: string): Settings | null {
  switch (productId) {
    case '1':
      return sku446Settings;
    case '2':
      return sku14404Settings;
    case '3':
      return harmonySettings;
    default:
      return null;
  }
}

function ConfigureProductPage() {
  const { productId } = useParams();

  const settings = settingsById(productId);

  useEffect(() => {
    if (!settings) return;

    sceneStore.set.clear();
    globalStore.set.clear();
    globalStore.set.settings(settings);
  }, [settings]);

  return (
    <ItemLayout
      header={<ConfigureProductPageHeader />}
      customSecondaryMenu={<ConfigureProductPageMenu />}
    >
      <ConfiguratorView />
    </ItemLayout>
  );
}

export default ConfigureProductPage;
