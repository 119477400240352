import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type NextLinkHelperOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => void;

interface RouterLinkHelperProps {
  className?: string;
  href: string;
  title?: string;
  onClick?: NextLinkHelperOnClick;
  children: ReactNode;
}

function RouterLinkHelper({
  className,
  href,
  title,
  onClick,
  children
}: RouterLinkHelperProps) {
  return (
    <Link to={href} className={className} onClick={onClick} title={title}>
      {children}
    </Link>
  );
}

export default RouterLinkHelper;
