import React, { memo, ReactNode, useEffect } from 'react';
import cl from 'classnames';

import { useMenu } from '../../../hooks/useMenu';

import { SecondaryMenuWrapperBackdrop } from './components/SecondaryMenuWrapperBackdrop';
import { SecondaryMenuWrapperBodyWrapper } from './components/SecondaryMenuWrapperBodyWrapper';
import { SecondaryMenuWrapperCloseButton } from './components/SecondaryMenuWrapperCloseButton';
import { SecondaryMenuWrapperHead } from './components/SecondaryMenuWrapperHead';

interface SecondaryMenuWrapperProps {
  i18nTitle: string;
  children: ReactNode;
}

function SecondaryMenuWrapper({
  i18nTitle,
  children
}: SecondaryMenuWrapperProps) {
  const {
    isSecondaryMenuOpen,
    isSecondaryMenuKeepOpen,
    closeSecondaryMenu,
    onMountSecondarySidebar,
    onUnmountSecondarySidebar
  } = useMenu();

  useEffect(() => {
    onMountSecondarySidebar();
    return () => {
      onUnmountSecondarySidebar();
    };
  }, []);

  return (
    <div
      data-id="nav-secondary-wrap"
      className={cl(
        'absolute h-full w-full z-10 pointer-events-none',
        isSecondaryMenuKeepOpen ? 'xl:relative xl:w-auto' : null
      )}
    >
      <div
        data-id="nav-secondary-inner"
        className={cl(
          'relative z-10 flex h-full pointer-events-auto w-96 4xl:w-112 max-w-full transform transition-transform',
          isSecondaryMenuOpen ? null : '-translate-x-full',
          isSecondaryMenuKeepOpen ? 'xl:transform-none xl:w-80' : null
        )}
      >
        <nav
          data-id="nav-secondary"
          className={cl(
            'border-gray-200 border-r bg-white dark:bg-gray-900 dark:border-gray-700 flex flex-auto flex-col h-full',
            isSecondaryMenuOpen ? 'shadow-xl' : null,
            isSecondaryMenuKeepOpen ? 'xl:shadow-none xl:pl-0' : null
          )}
        >
          <SecondaryMenuWrapperHead i18nTitle={i18nTitle} />

          <SecondaryMenuWrapperBodyWrapper>
            {children}
          </SecondaryMenuWrapperBodyWrapper>
        </nav>
        <SecondaryMenuWrapperCloseButton
          isSecondaryMenuKeepOpen={isSecondaryMenuKeepOpen}
          closeSecondaryMenu={closeSecondaryMenu}
        />
      </div>
      <SecondaryMenuWrapperBackdrop
        isSecondaryMenuOpen={isSecondaryMenuOpen}
        closeSecondaryMenu={closeSecondaryMenu}
      />
    </div>
  );
}

export default memo<SecondaryMenuWrapperProps>(SecondaryMenuWrapper);
