import { createStore } from '@udecode/zustood';
import {
  Box3,
  Euler,
  MeshStandardMaterial,
  Vector2,
  Vector3,
  Mesh,
  Group,
  Material,
  DoubleSide
} from 'three';

import {
  SimpleNode,
  SlotNode,
  GroupNode,
  Materials,
  MaterialsLib,
  RoomSettings,
  Scene,
  SceneNode,
  SceneNodeType,
  MaterialItem,
  CameraNodeItems,
  CameraNode,
  CameraOrientation
} from '../types/scene.types';

import { deleteSceneNode } from './utils/deleteSceneNode';
import { detachSlotsForSlotNode } from './utils/detachSlotsForSlotNode';

export type SceneNodeID = SceneNode['id'];

export type NewSimpleNode = Omit<SimpleNode, 'id' | 'parent'>;
export type NewSlotNode = Omit<SlotNode, 'id' | 'parent'>;
export type NewGroupNode = Omit<GroupNode, 'id' | 'parent'>;
export type NewCameraNode = Omit<CameraNode, 'id' | 'parent'>;
export type NewSceneNode = NewSimpleNode | NewSlotNode | NewGroupNode;

export const enum DefaultScopes {
  FLOOR = 'floor'
}

export const enum SceneMode {
  PRODUCT_EDITOR = 'product_editor',
  ROOM_PLANNER = 'room_planner',
  ROOM_DESIGNER = 'room_designer'
}

export interface SceneStore {
  scene: Scene;
  cameras: CameraNodeItems;
  materials: Materials;
  materialsLib: MaterialsLib;
  roomSettings: RoomSettings;
  draggedNode: SceneNode | CameraNode | null;
  hoveredNode: SceneNode | CameraNode | null;
  selectedNode: SceneNode | CameraNode | null;
  configureNode: SceneNode | null;
  techScope: {
    preview: NewSceneNode | SceneNode | CameraNode | null;
  };
  previewInSlot: boolean;
  sceneMode: boolean;
  sectioningMode: boolean;
  draggedObject: Mesh | Group | null;
  draggedToPosition: Vector3;
  rotateBy: Euler;
  testObject: string | null;
  cameraTransformMode: string;
  activeCamera: CameraNode | null;
  cameraOrientation: { orient: CameraOrientation } | null;
  perspectiveCamera: CameraNode | null;
}

export const defaultSceneStoreState: SceneStore = {
  scene: {
    [DefaultScopes.FLOOR]: {}
  },
  cameras: {},
  materials: {},
  materialsLib: {
    // default_floor: new MeshStandardMaterial({
    //   color: 0x73757c
    // }),
    default_white: new MeshStandardMaterial({ color: 0xffffff })
  },
  roomSettings: {
    plan: [
      new Vector2(-4, -4),
      new Vector2(4, -4),
      new Vector2(4, 4),
      new Vector2(-4, 4)
    ],
    thickness: 0.15,
    wallHeight: 2.81,
    materials: {
      floor: { name: 'parquet' },
      walls: { name: 'default_white' },
      ceiling: { name: 'default_white' }
    },
    boxes: [],
    floor: new Box3()
  },
  draggedNode: null,
  hoveredNode: null,
  selectedNode: null,
  configureNode: null,
  techScope: {
    preview: null
  },
  previewInSlot: false,
  sceneMode: true,
  sectioningMode: true,
  draggedObject: null,
  rotateBy: new Euler(),
  draggedToPosition: new Vector3(),
  testObject: null,
  cameraTransformMode: 'translate',
  activeCamera: null,
  cameraOrientation: null,
  perspectiveCamera: null
};

defaultSceneStoreState.materialsLib.default_white.side = DoubleSide;

export const sceneStore = createStore('SceneStore')(defaultSceneStoreState)
  .extendActions((set, get) => ({
    clear: () => {
      set.state((draft) => {
        Object.assign(draft, defaultSceneStoreState);
      });
    },

    addSceneNode: (scope: string, item: NewSceneNode | SceneNode) => {
      const id = Date.now().toString();

      const isCreatedItem = 'id' in item && item.id;

      const newItem: SceneNode = isCreatedItem
        ? item
        : {
            ...item,
            parent: null,
            scope,
            id
          };

      const scene = get.scene();

      !scene[scope] && Object.assign(scene, { [scope]: {} });

      Object.assign(scene[scope], { [isCreatedItem ? item.id : id]: newItem });

      set.scene({
        ...scene
      });

      return newItem;
    },

    addCameraNode: (scope: string, item: NewCameraNode | CameraNode) => {
      const id = Date.now().toString();

      const isCreatedItem = 'id' in item && item.id;

      const newItem: CameraNode = isCreatedItem
        ? item
        : {
            ...item,
            scope,
            id
          };

      const cameras = get.cameras();

      !cameras && Object.assign(cameras, {});

      Object.assign(cameras, {
        [isCreatedItem ? item.id : id]: newItem
      });

      set.cameras({
        ...cameras
      });

      return newItem;
    },

    updateSceneNode: (node: SceneNode, item: Partial<NewSceneNode>) => {
      if (!node) return;

      Object.assign(node, item);

      set.scene({
        ...get.scene()
      });
    },

    updateCameraNode: (node: CameraNode, item: Partial<NewCameraNode>) => {
      if (!node) return;

      Object.assign(node, item);

      set.cameras({
        ...get.cameras()
      });
      return item;
    },

    setMaterial: (group: string, materialItem: MaterialItem) => {
      set.materials({
        ...get.materials(),
        [group]: materialItem
      });
    },

    updateMaterialLib: (name: string, material: Material | undefined) => {
      const materialsLib = get.materialsLib();

      material
        ? Object.assign(materialsLib, { [name]: material })
        : delete materialsLib[name];

      set.materialsLib({
        ...materialsLib
      });
    },

    updatePreview: (item: Partial<SceneNode | CameraNode> | null) => {
      const curPreview = get.techScope().preview;

      const preview = item
        ? (Object.assign(curPreview || item, item) as SceneNode)
        : null;

      const techScope = get.techScope();
      set.techScope({ ...techScope, preview });
    },

    addBoxesToRoomSettings: (box: Box3) => {
      const { boxes } = sceneStore.get.roomSettings();
      set.roomSettings({
        ...get.roomSettings(),
        boxes: [box, ...boxes]
      });
    },

    detachSlotNode: (node: SceneNode) => {
      if (node?.type !== SceneNodeType.SLOT)
        throw new Error('node is not SLOT type');

      detachSlotsForSlotNode(node, (childNode: SceneNode) => {
        deleteSceneNode(set, get, childNode);
      });

      set.scene({ ...get.scene() });
    },

    deleteSceneNode: (node: SceneNode) => {
      deleteSceneNode(set, get, node);

      set.scene({ ...get.scene() });
    },

    deleteCameraNode: (node: CameraNode) => {
      delete get.cameras()[node.id];

      set.cameras({ ...get.cameras() });
    },

    shiftToCenter: (node: SceneNode | null) => {
      if (node?.type !== SceneNodeType.GROUP) return;
      const centerPos = new Vector3();
      let count = 0;
      for (const key in node.model) {
        count++;
        centerPos.add(node.model[key].position);
      }
      centerPos.divideScalar(count);
      for (const key in node.model) {
        node.model[key].position.sub(centerPos);
      }
    },

    switchSceneMode(mode: SceneMode) {
      const selectedNode = get.selectedNode();

      switch (mode) {
        case SceneMode.PRODUCT_EDITOR:
          set.configureNode(
            selectedNode?.type === SceneNodeType.GROUP ||
              selectedNode?.type === SceneNodeType.SIMPLE
              ? selectedNode
              : null
          );
          set.selectedNode(null);
          set.roomSettings({
            ...get.roomSettings(),
            boxes: []
          });
          set.sectioningMode(true);
          set.sceneMode(false);
          break;

        case SceneMode.ROOM_DESIGNER:
          this.shiftToCenter(get.configureNode());
          set.selectedNode(get.configureNode());
          set.configureNode(null);
          set.sectioningMode(false);
          set.sceneMode(true);
          break;
      }
    }
  }))
  .extendActions((set, get) => ({
    addToConfigureNode: (item: NewSceneNode | SceneNode) => {
      const configureNode = get.configureNode();

      if (!configureNode || configureNode.type !== SceneNodeType.GROUP)
        throw new Error('configureNode not exist or scene type not GROUP');

      const id = Date.now().toString();

      const isCreatedItem = 'id' in item && item.id;

      const newItem = isCreatedItem
        ? item
        : ({
            ...item,
            id,
            parent: configureNode
          } as SceneNode);

      Object.assign(configureNode.model, {
        [isCreatedItem ? item.id : id]: newItem
      });

      set.scene({ ...get.scene() });

      return newItem;
    }
  }));
