import { SlotItem, SlotItemPoint } from '../../types/scene.types';

interface CheckSlotAvailableProps {
  parentSlot: SlotItem;
  parentSlotPoint: SlotItemPoint;
  itemSlotName: string;
  itemSlotPointName: string;
  children: JSX.Element;
}

export function CheckSlotAvailable({
  parentSlot,
  parentSlotPoint,
  itemSlotName,
  itemSlotPointName,
  children
}: CheckSlotAvailableProps) {
  if (parentSlot.point || parentSlotPoint.available.length === 0) {
    return null;
  }

  for (let i = 0; i < parentSlotPoint.available.length; ++i) {
    const availablePoint = parentSlotPoint.available[i];

    if (
      availablePoint.sideName === itemSlotName &&
      availablePoint.pointName === itemSlotPointName
    ) {
      return children;
    }
  }

  return null;
}
