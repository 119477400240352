import { MaterialType } from '../../types/scene.types';
import { SettingsMaterialAssetItems } from '../../types/settings.types';

export const mockTextures: SettingsMaterialAssetItems = {
  alumin: {
    id: 'alumin',
    previewImage: '/assets/materials/preview/alumin.jpg',
    name: 'Alumin',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/alumin.glb'
    }
  },
  carbon: {
    id: 'carbon',
    previewImage: '/assets/materials/preview/carbon.jpg',
    name: 'Carbon',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/carbon.glb'
    }
  },
  charm: {
    id: 'charm',
    previewImage: '/assets/materials/preview/charm.jpg',
    name: 'Charm',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/charm.glb'
    }
  },
  gold: {
    id: 'gold',
    previewImage: '/assets/materials/preview/gold.jpg',
    name: 'Gold',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/gold.glb'
    }
  },
  'lull-blue': {
    id: 'lull-blue',
    previewImage: '/assets/materials/preview/lull_blue.jpg',
    name: 'Lull Blue',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/lull_blue.glb'
    }
  },
  plastic: {
    id: 'plastic',
    previewImage: '/assets/materials/preview/plastic.jpg',
    name: 'Plastic',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/plastic.glb'
    }
  },
  'twiling-blue': {
    id: 'twiling-blue',
    previewImage: '/assets/materials/preview/twiling_blue.jpg',
    name: 'Twiling Blue',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/twiling_blue.glb'
    }
  },
  'vine-green': {
    id: 'vine-green',
    previewImage: '/assets/materials/preview/vine_green.jpg',
    name: 'Vine Green',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/vine_green.glb'
    }
  },
  'winter-gray': {
    id: 'winter-gray',
    previewImage: '/assets/materials/preview/winter_gray.jpg',
    name: 'Winter Gray',
    filesUrl: {
      [MaterialType.REAL_WORLD]: '/assets/materials/winter_gray.glb'
    }
  }
};
