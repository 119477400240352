import {
  SceneNodeAttach,
  SceneNodeStick,
  SceneNodeType
} from '../../types/scene.types';
import { SettingsModelAssetItems } from '../../types/settings.types';

export const mockHarmony: SettingsModelAssetItems = {
  harmony_angular: {
    id: 'harmony_angular',
    parentId: 'harmony',
    previewImage: '/assets/models/harmony/preview/angular.jpg',
    name: 'Angular',
    fileUrl: '/assets/models/harmony/angular.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  harmony_double_middle: {
    id: 'harmony_double_middle',
    parentId: 'harmony',
    previewImage: '/assets/models/harmony/preview/double_middle.jpg',
    name: 'Double Middle',
    fileUrl: '/assets/models/harmony/double_middle.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  harmony_left_arm: {
    id: 'harmony_left_arm',
    parentId: 'harmony',
    previewImage: '/assets/models/harmony/preview/left_arm.jpg',
    name: 'Left Arm',
    fileUrl: '/assets/models/harmony/left_arm.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  harmony_right_arm: {
    id: 'harmony_right_arm',
    parentId: 'harmony',
    previewImage: '/assets/models/harmony/preview/right_arm.jpg',
    name: 'Right Arm',
    fileUrl: '/assets/models/harmony/right_arm.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  harmony_double_left_arm: {
    id: 'harmony_double_left_arm',
    parentId: 'harmony',
    previewImage: '/assets/models/harmony/preview/double_left_arm.jpg',
    name: 'Double Left Arm',
    fileUrl: '/assets/models/harmony/double_left_arm.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  harmony_double_right_arm: {
    id: 'harmony_double_right_arm',
    parentId: 'harmony',
    previewImage: '/assets/models/harmony/preview/double_right_arm.jpg',
    name: 'Double Right Arm',
    fileUrl: '/assets/models/harmony/double_right_arm.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  },
  harmony_ottoman: {
    id: 'harmony_ottoman',
    parentId: 'harmony',
    previewImage: '/assets/models/harmony/preview/ottoman.jpg',
    name: 'Ottoman',
    fileUrl: '/assets/models/harmony/ottoman.glb',
    attach: SceneNodeAttach.DEFAULT,
    stick: SceneNodeStick.BOTTOM,
    type: SceneNodeType.SLOT
  }
};
