import { useDraggable, DraggableOptions } from '../../hooks/useDraggable';

type DraggableProps = {
  children: React.ReactNode;
} & DraggableOptions;

export function Draggable({
  children,
  onPointerDown,
  onPointerMove,
  onPointerUp,
  onDragStart,
  onClick,
  durationLimit,
  distanceLimit
}: DraggableProps) {
  const {
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handlePointerCancel
  } = useDraggable({
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onDragStart,
    onClick,
    durationLimit,
    distanceLimit
  });

  return (
    <group
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onPointerCancel={handlePointerCancel}
      onPointerMissed={handlePointerCancel}
    >
      {children}
    </group>
  );
}
