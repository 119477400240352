import { IconProps } from '../types';

function WindowOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 552 552"
      fill="currentColor"
    >
      <g transform="translate(-100, -100)">
        <path d="m381.18 190.27c-76.957 0-139.86 62.898-139.86 139.86v204.23c0 14.801 12.578 27.379 27.379 27.379h224.95c14.801 0 27.379-12.578 27.379-27.379l0.003906-204.23c0-76.957-62.898-139.86-139.86-139.86zm110.26 139.86v31.078h-95.457v-139.86c54.02 7.3984 95.457 53.277 95.457 108.78zm-219.77 0c0-55.496 41.438-101.38 95.457-108.78v139.86h-95.457zm0 60.676h95.457v141.34h-95.457zm219.77 141.34h-95.457v-141.34h95.457z" />
      </g>
    </svg>
  );
}

export default WindowOutlineIcon;
