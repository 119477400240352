import { useCallback, useEffect } from 'react';

import { dndStore } from '../store/dndStore';

export function preventDefaultDragStart(e: React.DragEvent) {
  e.preventDefault();
}

export function useDragHtml(id: string) {
  const { id: dndId, shift, active, visibility } = dndStore.useStore();

  const mousemove = useCallback((e: MouseEvent) => {
    dndStore.set.setPosition({
      x: e.clientX,
      y: e.clientY
    });
  }, []);

  const mouseup = useCallback(() => {
    document.body.removeEventListener('pointerup', mouseup);
    document.body.removeEventListener('pointermove', mousemove);

    dndStore.set.setStartPosition({ x: 0, y: 0 });
    dndStore.set.setPosition({ x: 0, y: 0 });
    dndStore.set.id(null);
    dndStore.set.active(false);
  }, [mousemove]);

  const onPointerDown = useCallback(
    (e: React.MouseEvent) => {
      dndStore.set.setStartPosition({ x: e.clientX, y: e.clientY });
      dndStore.set.setPosition({ x: e.clientX, y: e.clientY });
      dndStore.set.active(true);
      dndStore.set.visibility(true);
      dndStore.set.id(id);

      document.body.addEventListener('pointerup', mouseup);
      document.body.addEventListener('pointermove', mousemove);
    },
    [mouseup, mousemove]
  );

  useEffect(() => {
    return () => {
      document.body.removeEventListener('pointerup', mouseup);
      document.body.removeEventListener('pointermove', mousemove);
    };
  }, []);

  return {
    dndId,
    shift,
    active,
    visibility,
    onPointerDown
  };
}
