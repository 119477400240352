import { SettingsInfo } from '../../../../../../types/settings.types';
import React from 'react';

interface ProductPageMenuHeaderProps {
  info: SettingsInfo;
}

function ProductPageMenuHeader({ info }: ProductPageMenuHeaderProps) {
  return (
    <h1 className="mt-2 align-top">
      <span className="text-3xl font-extrabold tracking-tight">
        {info.name}
      </span>
      {info.nda && (
        <span className="px-2 py-1 text-xs font-medium cursor-default rounded bg-yellow-200 text-black uppercase align-top">
          NDA
        </span>
      )}
      <button
        type="button"
        className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 text-gray-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
          />
        </svg>
        <span className="sr-only">Copy link</span>
      </button>
    </h1>
  );
}

export default ProductPageMenuHeader;
